import { History } from 'history';
import { routes } from '../core/routes/paths';
import { AuthAsync } from './general/auth/actionsSync';

// tslint:disable-next-line:no-any
export const authorizeInterceptor = (history: History<any>, dispatch: any, status: number) => {
	if (status === 401 || status === 403) {
		dispatch(AuthAsync.logout());
		history.push(routes.login.path);
	}
};
