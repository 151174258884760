import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { addLineBreaks, sanitize } from '@ea/common';
import { GridRow } from '../../service/typings/grid';

interface Props extends ICellRendererParams {
	value: string;
	onRowChanged?: (node: GridRow, field: string | undefined, value: any) => void;
	isNumber?: boolean;
}

export class CustomRenderer implements ICellRenderer {
	element!: HTMLElement;
	textarea!: HTMLTextAreaElement;
	content!: HTMLElement;
	editing?: boolean;
	isNumber?: boolean;

	public init(params: Props) {
		this.element = document.createElement('div');
		this.textarea = document.createElement('textarea');
		this.content = document.createElement('div');
		this.editing = false;
		this.isNumber = params.isNumber;

		if (params.isNumber) {
			this.textarea.setAttribute('inputmode', 'numeric');
		}

		this.element.setAttribute('class', 'ag-grid-custom-header-cell');

		this.textarea.setAttribute(
			'class',
			'ag-grid-custom-header-cell__textarea ag-grid-custom-header-cell__textarea--hidden',
		);
		this.textarea.addEventListener('blur', () => {
			this.setEditing(false);
			if (params.onRowChanged) {
				params.onRowChanged(params.node.data, params.colDef.field, this.textarea.value);
			}
		});
		this.textarea.addEventListener('keydown', (e) => {
			e.stopPropagation();
		});

		if (params.isNumber) {
			['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
				this.textarea.addEventListener(
					event,
					function (
						this: HTMLTextAreaElement & {
							oldValue: string;
							oldSelectionStart: number | null;
							oldSelectionEnd: number | null;
						},
					) {
						if (this.value === this.value.trim() && !isNaN(Number(this.value))) {
							this.oldValue = this.value;
							this.oldSelectionStart = this.selectionStart;
							this.oldSelectionEnd = this.selectionEnd;
						} else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
							this.value = this.oldValue;
							if (this.oldSelectionStart !== null && this.oldSelectionEnd !== null) {
								this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
							}
						} else {
							this.value = '';
						}
					},
				);
			});
		}

		this.element.appendChild(this.textarea);

		this.content.setAttribute('class', 'ag-grid-custom-header-cell__content');
		this.content.addEventListener('click', () => {
			const selection = window.getSelection();

			if (selection) {
				const { anchorNode, anchorOffset } = selection;
				Array.from(this.content.childNodes)
					.filter((child) => child.nodeType === Node.TEXT_NODE)
					.forEach((child, i) => {
						if (child === anchorNode) {
							const lines = String(this.textarea.value || '').split('\n');
							const position = lines.reduce(
								(position, line, index) => (index < i ? position + line.length + 1 : position),
								0,
							);
							this.textarea.selectionStart = position + anchorOffset;
							this.textarea.selectionEnd = position + anchorOffset;
						}
					});
			}
			this.setEditing(true);
		});
		this.element.appendChild(this.content);

		this.render(params);
	}

	public setEditing(editing: boolean) {
		if (editing) {
			this.content.setAttribute(
				'class',
				'ag-grid-custom-header-cell__content ag-grid-custom-header-cell__content--hidden',
			);
			this.textarea.setAttribute('class', 'ag-grid-custom-header-cell__textarea');
			this.element.setAttribute('class', 'ag-grid-custom-header-cell ag-grid-custom-header-cell--editing');
			setTimeout(() => {
				this.textarea.focus();
			});
		} else {
			this.content.innerHTML = addLineBreaks(sanitize(this.textarea.value));
			this.content.setAttribute('class', 'ag-grid-custom-header-cell__content');
			this.textarea.setAttribute(
				'class',
				'ag-grid-custom-header-cell__textarea ag-grid-custom-header-cell__textarea--hidden',
			);
			this.element.setAttribute('class', 'ag-grid-custom-header-cell');
		}
	}

	public render(params: Props) {
		this.textarea.innerHTML = params.value || '';
		if (params.isNumber) {
			this.content.innerHTML = params.value || '';
		} else {
			this.content.innerHTML = params.value ? addLineBreaks(sanitize(this.textarea.value)) : '';
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.render(params);
		return true;
	}
}
