import { actionCreatorFactory } from 'typescript-fsa';
import { CompanyDetailed, CompanyListItem } from '../../../../service/typings/entites';
import {
	GetCompanyDetailsRequest,
	RemoveCompanyRequest,
	RemoveCompanyResponse,
	UpdateCompanyDetailsRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPANY_DETAILS');

export const CompanyDetailsActions = {
	get: ActionFactory.async<GetCompanyDetailsRequest, CompanyDetailed, Error>('GET'),
	update: ActionFactory.async<UpdateCompanyDetailsRequest, CompanyListItem, Error>('UPDATE'),
	remove: ActionFactory.async<RemoveCompanyRequest, RemoveCompanyResponse, Error>('REMOVE_COMPANY'),
};
