import { forEach, isArray, isFunction, isString } from 'lodash-es';
import { Dict } from '../types';
import { IGridSort } from '../interfaces/ui/grid';
import { Ref } from '../interfaces/service';

export const createArray = (n: number, value?: any) => {
	return [...Array(n).fill(value)];
};

export function omitEmpty(obj: any) {
	const result: any = {};
	Object.keys(obj).forEach((key) => {
		if (obj[key] !== null && typeof obj[key] !== 'undefined') {
			result[key] = obj[key];
		}
	});
	return result;
}

export function removeDeletedObjects(data: Dict) {
	const result: Dict = {};
	Object.keys(data).forEach((key) => {
		if (data[key] && data[key].id === -1) {
			result[key] = null;
		} else {
			result[key] = data[key];
		}
	});
	return result;
}

export function stringifySort(options: IGridSort) {
	if (options.field && options.order) {
		return {
			sort: `${options.field},${options.order}`,
		};
	} else {
		return {};
	}
}

export function arrayToObject(array: any[], value: any) {
	if (isFunction(value)) {
		return array.reduce(
			(result, key) => ({
				...result,
				[key]: value(key),
			}),
			{},
		);
	} else {
		const result: any = {};
		for (const key of array) {
			result[key] = value;
		}
		return result;
	}
}

export function transformToObject<T, R>(array: T[], transform: (value: T) => { key: string | number; value: R }) {
	const result: Dict<R> = {};
	array.forEach((value) => {
		const pair = transform(value);
		result[pair.key] = pair.value;
	});
	return result;
}

export function searchInString(haystack: string | string[], needle: string) {
	return (isArray(haystack) ? haystack : [haystack]).some(
		(item) => item.toLowerCase().indexOf((needle as string).toLowerCase()) >= 0,
	);
}

export function countTruthy(collection: any) {
	let number = 0;
	forEach(collection, (el) => {
		if (el) {
			number += 1;
		}
	});
	return number;
}

export function ensureArray<T>(obj: T | T[] | undefined) {
	if (!obj) {
		return [];
	} else if (isArray(obj)) {
		return obj;
	} else {
		return [obj];
	}
}

export function assignRef<T>(ref: Ref<T>, value: T) {
	if (typeof ref === 'function') {
		ref(value);
	} else if (ref != null) {
		ref.current = value;
	}
	return ref;
}

export function trim(value: any) {
	if (isString(value)) {
		return value.trim();
	} else {
		return value;
	}
}

export function clearArray<T>(obj: T[]) {
	return obj.filter((el) => el);
}
