import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { values, includes } from 'lodash-es';
import { AuthSelectors } from '../../../store/general/auth/selectors';
import { Site } from '../../../config/general';
import { routes } from '../../../core/routes/paths';

interface Props extends RouteProps {
	site?: Site;
	roles?: string[];
}

export const ProtectedRoute: FC<Props | RouteProps> = (props) => {
	const { site, roles, ...routerProps } = props as Props;

	const auth = useSelector(AuthSelectors.profiles);

	const sites = values(auth).filter((el) => el !== undefined);

	const isAuthorized = sites.length > 0;

	let isAllowed = isAuthorized;

	if (isAuthorized) {
		if (site) {
			const user = auth[site];
			if (user) {
				isAllowed = roles ? includes(roles, user.role) : true;
			}
		}
	}

	if (isAuthorized) {
		if (isAllowed) {
			return <Route {...routerProps} />;
		} else {
			return <Redirect to={routes.root.path} />;
		}
	} else {
		return <Redirect to={routes.login.path} />;
	}
};
