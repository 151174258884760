import { combineReducers } from 'redux';
import { CompanyListReducer } from '../companies/reducer';
import { ProfilesListReducer } from './profiles/reducer';
import { QuestionsListReducer } from './questions/reducer';
import { CompetencesListReducer } from './competences/reducer';
import { PersonsListReducer } from './persons/reducer';
import { UserActivityListReducer } from './userActivity/reducer';
import { AdminActivityListReducer } from './adminActivity/reducer';
import { AdministrationListReducer } from './administration/reducer';
import { AdministrationPageReducer } from './administrationPage/reducer';
import { AccessListReducer } from './access/reducer';

export const ListsReducer = combineReducers({
	persons: PersonsListReducer,
	access: AccessListReducer,
	profiles: ProfilesListReducer,
	questions: QuestionsListReducer,
	competences: CompetencesListReducer,
	companies: CompanyListReducer,
	userActivity: UserActivityListReducer,
	adminActivity: AdminActivityListReducer,
	administration: AdministrationListReducer,
	administrationPage: AdministrationPageReducer,
});
