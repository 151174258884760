import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'astroturf';
import { DateTime, Info } from 'luxon';
import { chunk, isArray } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CaretLeft } from '../../images/icons/caret-left.svg';
import { ReactComponent as CaretRight } from '../../images/icons/caret-right.svg';
import { BlockButton } from '../elements/buttons/BlockButton';
import { monthDays } from '../../service/utils/date';

type Date = string | undefined;

type Value = Date | [Date, Date];

interface IProps {
	value: Value;
	format?: string;
	onChange?: (value: string | [string, string]) => void;
	range?: boolean;
	double?: boolean;
	immediately?: boolean;
	hideButton?: boolean;
}

function isRange(date: Value): date is [Date, Date] {
	return isArray(date);
}

function toRange(date: Value) {
	return isRange(date) ? date : [date, undefined];
}

export const Calendar: FC<IProps> = ({
	immediately,
	hideButton,
	onChange,
	value,
	double,
	range,
	format = 'yyyy-MM-dd',
}) => {
	const { t } = useTranslation();

	const [today] = useState(DateTime.local());

	const [month, setMonth] = useState<DateTime>(() => {
		const [reference] = toRange(value);
		const date = reference ? DateTime.fromFormat(reference, format) : today;
		return (date.isValid ? date : today).startOf('month');
	});

	const months = useMemo(() => {
		if (double) {
			return [month, month.plus({ months: 1 })];
		} else {
			return [month];
		}
	}, [month, double]);

	const dates = toRange(value);

	const [firstDate, setFirstDate] = useState(dates[0] ? DateTime.fromFormat(dates[0], format) : undefined);
	const [lastDate, setLastDate] = useState(dates[1] ? DateTime.fromFormat(dates[1], format) : undefined);
	const [choiceFirst, setChoiceFirst] = useState(!(firstDate && !lastDate));

	const weekdays = Info.weekdays('short');
	const firstWeekday = 1;

	const days = useMemo(() => {
		return months.map((month) => {
			return monthDays(month, firstWeekday);
		});
	}, [months, firstWeekday]);

	const onNextMonth = useCallback(() => {
		setMonth((month) => month.plus({ months: 1 }));
	}, [setMonth]);

	const onPreviousMonth = useCallback(() => {
		setMonth((month) => month.minus({ months: 1 }));
	}, [setMonth]);

	const onSaveButton = useCallback(() => {
		if (onChange) {
			if (firstDate && lastDate) {
				onChange([(firstDate as DateTime).toFormat(format), (lastDate as DateTime).toFormat(format)]);
			} else {
				onChange((firstDate as DateTime).toFormat(format));
			}
		}
	}, [range, onChange, lastDate, firstDate, format]);

	const onDayClick = useCallback(
		(day: DateTime) => () => {
			if (range) {
				if (choiceFirst) {
					setFirstDate(day);
					if (lastDate && day > lastDate) {
						setLastDate(undefined);
					}
					setChoiceFirst(false);
				} else {
					if (firstDate && day > firstDate) {
						setLastDate(day);
						setChoiceFirst(true);
					} else {
						setFirstDate(day);
						setLastDate(undefined);
					}
				}
			} else {
				setFirstDate(day);
				setLastDate(undefined);
				if (immediately && onChange) {
					onChange((day as DateTime).toFormat(format));
				}
			}
		},
		[range, lastDate, firstDate, choiceFirst, setChoiceFirst, setFirstDate, setLastDate, range],
	);

	return (
		<Wrapper double={double}>
			<Container>
				{months.map((firstMonthDay, page) => (
					<Page key={page}>
						<Header>
							<Year>{firstMonthDay.toFormat('LLLL yyyy')}</Year>
							{page === months.length - 1 && (
								<Navigation>
									<LeftButton onClick={onPreviousMonth}>
										<StyledCaretLeft />
									</LeftButton>
									<RightButton onClick={onNextMonth}>
										<StyledCaretRight />
									</RightButton>
								</Navigation>
							)}
						</Header>
						<Body>
							<Weekdays>
								{weekdays.map((weekday) => (
									<Weekday key={weekday}>{weekday}</Weekday>
								))}
							</Weekdays>
							{chunk(days[page], 7).map((days, chunk) => (
								<Week key={chunk}>
									{days.map((date, index) =>
										date ? (
											<Day
												key={index}
												today={
													firstMonthDay.month === today.month && today.hasSame(date, 'day')
												}
												start={firstDate && firstDate.hasSame(date, 'day')}
												end={lastDate && lastDate.hasSame(date, 'day')}
												single={
													firstDate &&
													firstDate.hasSame(date, 'day') &&
													(!lastDate || firstDate.hasSame(lastDate, 'day'))
												}
												intermediate={
													firstDate && lastDate && date > firstDate && date < lastDate
												}
												onClick={onDayClick(date)}
											>
												{date.day}
											</Day>
										) : (
											<EmptyDay key={index} />
										),
									)}
								</Week>
							))}
						</Body>
					</Page>
				))}
			</Container>
			{!hideButton && (
				<ButtonWrapper>
					<SaveButton color={'blue'} onClick={onSaveButton} disabled={!firstDate}>
						{t('common.actions.save')}
					</SaveButton>
				</ButtonWrapper>
			)}
		</Wrapper>
	);
};

const SaveButton = styled(BlockButton)`
	margin-left: auto;
	width: 151px;
`;

const ButtonWrapper = styled.div`
	margin-top: 37px;
	display: flex;
`;

const Container = styled.div`
	display: flex;
`;

const Wrapper = styled.div<{ double?: boolean }>`
	user-select: none;
	background: white;
	border: solid 1px #ededed;
	padding: 23px 18px 29px 18px;
	&.double {
		padding: 23px 39px 29px 18px;
	}
`;

const Navigation = styled.div`
	display: flex;
	margin-left: auto;
`;

const StyledCaretLeft = styled(CaretLeft)`
	width: 9px;
`;

const StyledCaretRight = styled(CaretRight)`
	width: 9px;
`;

const LeftButton = styled.div`
	display: flex;
	height: 20px;
	width: 20px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const RightButton = styled(LeftButton)`
	margin-left: 10px;
`;

const Year = styled.div`
	font-size: 12px;
	line-height: 32px;
	color: #484848;
	margin-bottom: 31px;
	font-weight: bold;
	&:first-letter {
		text-transform: uppercase;
	}
`;
const Page = styled.div`
	&:not(:last-of-type) {
		margin-right: 40px;
	}
`;
const Header = styled.div`
	display: flex;
`;
const Weekdays = styled.div`
	display: flex;
`;
const Weekday = styled.div`
	color: #757575;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	height: 30px;
	width: 30px;
	display: inline-block;
	&:first-letter {
		text-transform: uppercase;
	}
	&:not(:last-of-type) {
		margin-right: 10px;
	}
`;
const Body = styled.div`
	//TODO: временное решение, чтобы предотвратить скачки
	//min-height: 215px;
`;
const Week = styled.div`
	display: flex;
	margin-bottom: 1px;
`;
const Day = styled.div<{ today?: boolean; start?: boolean; end?: boolean; single?: boolean; intermediate?: boolean }>`
	width: 30px;
	height: 30px;
	font-size: 12px;
	font-weight: bold;
	line-height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;

	&:not(:last-child) {
		margin-right: 10px;

		&:after {
			content: '';
			background: #008ac6;
			height: 30px;
			width: 10px;
			position: absolute;
			right: -10px;
			top: 0;
			opacity: 0;
		}
	}

	&.today {
		border-radius: 50%;
	}

	&.today,
	&:hover {
		background: #f5f5f5;
	}

	&.end,
	&.start,
	&.intermediate {
		background: #008ac6;
		color: white;
	}

	&.intermediate,
	&.start {
		position: relative;
		border-radius: 0;

		&:after {
			opacity: 1;
		}
	}

	&.start {
		border-radius: 50% 0 0 50%;
	}

	&.end {
		border-radius: 0 50% 50% 0;
	}

	&.single {
		border-radius: 50%;

		&:after {
			opacity: 0;
		}
	}
`;

const EmptyDay = styled.div`
	width: 30px;
	height: 30px;
	&:not(:last-child) {
		margin-right: 10px;
	}
`;
