import React, { FC, useMemo, useRef } from 'react';
import styled from 'astroturf';
import { Grow, Popper } from '@material-ui/core';
import useOnClickOutside from 'use-onclickoutside';
import { ListItem } from '@ea/common';
import { GridPopupSelect } from '../fields/GridPopupSelect';

interface Props {
	offset?: string | number;
	open: boolean;
	anchorEl?: null | HTMLElement;
	onSelect: (item: ListItem) => void;
	onClose: () => void;
	items: ListItem[];
	additionalItems?: ListItem[];
}

export const POPUP_APPEAR_TIME = 300;

export const GridPopup: FC<Props> = ({
	anchorEl,
	onClose,
	onSelect,
	items,
	open,
	additionalItems,
	offset = '0px,6px',
}) => {
	const body = useRef<any>();

	const modifiers = useMemo(
		() => ({
			offset: {
				enabled: true,
				offset: offset,
			},
			flip: {
				enabled: false,
			},
		}),
		[offset],
	);
	useOnClickOutside(body, (event: any) => {
		// TODO: костыль, сделанный для предотвращения двойного клика
		if (anchorEl && !anchorEl.contains(event.target)) {
			onClose();
		}
	});
	return (
		<Popup
			transition
			open={open}
			anchorEl={anchorEl}
			placement="bottom-end"
			modifiers={modifiers}
			popperOptions={{
				positionFixed: true,
			}}
		>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps} timeout={POPUP_APPEAR_TIME}>
					<PopupBody ref={body}>
						<PopupContent>
							<StyledGridPopupSelect
								onSelect={onSelect}
								items={items}
								additionalItems={additionalItems}
							/>
						</PopupContent>
					</PopupBody>
				</Grow>
			)}
		</Popup>
	);
};

const Popup = styled(Popper)`
	@import '../../../../common/src/styles/constants.scss';
	z-index: $z-index-popup;
`;

const StyledGridPopupSelect = styled(GridPopupSelect)`
	height: 100%;
`;

const PopupContent = styled.div`
	@import '../../../../common/src/styles/mixins.scss';
	@include thin-scrollbar();
	overflow: auto;
	max-height: 230px;
`;

const PopupBody = styled.div`
	width: 218px;
	background: white;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	padding-top: 16px;
	padding-bottom: 14px;
	max-height: 250px;
	position: relative;
`;
