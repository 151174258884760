import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { initialPaginatedContentLoading, PaginatedParametrizedContentLoading } from '@ea/common';
import { CompanyDetailsActions } from '../details/company/actions';
import { CompanyListItem } from '../../../service/typings/entites';
import { CompanyActions } from './actions';

export interface ICompanyListState {
	list: PaginatedParametrizedContentLoading<CompanyListItem[]>;
	focus: number | null;
	actual: boolean;
}

const initialState: ICompanyListState = {
	list: initialPaginatedContentLoading([], {}),
	focus: null,
	actual: false,
};

export const CompanyListReducer = reducerWithInitialState(initialState)
	.case(CompanyActions.getList.started, (state) => ({
		...state,
		list: {
			...state.list,
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(CompanyActions.getList.done, (state, { result, params }) =>
		state.list.loading.status === 'loading'
			? {
					...state,
					list: {
						...state.list,
						content: result._embedded.companies,
						loading: {
							type: 'full',
							status: 'loaded',
						},
						pagination: {
							...result.page,
							more: params.size ? result._embedded.companies.length >= params.size : false,
						},
						params,
					},
					actual: true,
			  }
			: state,
	)
	.case(CompanyActions.getList.failed, (state) => ({
		...state,
		list: {
			...state.list,
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(CompanyActions.loadMore.started, (state) => ({
		...state,
		list: {
			...state.list,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(CompanyActions.loadMore.done, (state, { result, params: { size } }) =>
		state.list.loading.status === 'loading' && state.list.loading.type === 'append'
			? {
					...state,
					list: {
						...state.list,
						content: [...state.list.content, ...result._embedded.companies],
						loading: {
							type: 'append',
							status: 'loaded',
						},
						pagination: { ...result.page, more: size ? result._embedded.companies.length >= size : false },
					},
			  }
			: state,
	)
	.case(CompanyActions.setFocus, (state, focus) => ({
		...state,
		focus,
	}))
	.case(CompanyActions.reset, (state, actual) => ({
		...state,
		list: initialPaginatedContentLoading([], {}),
		actual: false,
		focus: null,
	}))
	// Тут начинаются сторонние экшены, которые влияют на стейт
	.case(CompanyDetailsActions.remove.done, (state, { result }) => ({
		...state,
		list: {
			...state.list,
			content: state.list.content.filter((item) => item.id !== result.companyId),
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(CompanyDetailsActions.update.done, (state, { result }) => ({
		...state,
		list: {
			...state.list,
			content: [result, ...state.list.content.filter((company) => company.id !== result.id)],
		},
	}));
