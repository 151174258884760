import { useSelector } from 'react-redux';
import { AuthSelectors } from '../../store/general/auth/selectors';
import { useSite } from './useSite';

export const useProfile = () => {
	const profiles = useSelector(AuthSelectors.profiles);

	const site = useSite();

	if (site) {
		return profiles[site];
	}
};
