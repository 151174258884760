import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import '@ea/common/dist/styles.scss';
import '@ea/table/dist/styles.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

const renderApp = (Comp?: any) => {
	ReactDOM.render(<Comp />, document.getElementById('root'));
};

renderApp(App);

if ((module as any).hot) {
	(module as any).hot.accept('./App', () => {
		const NewApp = require('./App').default;

		renderApp(NewApp);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
