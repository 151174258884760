import { actionCreatorFactory } from 'typescript-fsa';
import {
	ConfirmCreateCompanyRequest,
	ConfirmCreateCompanyResponse,
	CreateCompanyRequest,
	CreateCompanyResponse,
	GetCompaniesRequest,
	GetCompaniesResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPANY');

export const CompanyActions = {
	getList: ActionFactory.async<GetCompaniesRequest, GetCompaniesResponse, Error>('GET_LIST'),
	loadMore: ActionFactory.async<GetCompaniesRequest, GetCompaniesResponse, Error>('LOAD_MORE'),
	create: ActionFactory.async<CreateCompanyRequest, CreateCompanyResponse, Error>('CREATE_COMPANY'),
	confirmCreate: ActionFactory.async<ConfirmCreateCompanyRequest, ConfirmCreateCompanyResponse, Error>(
		'CONFIRM_CREATE_COMPANY',
	),
	setFocus: ActionFactory<number | null>('SET_FOCUS'),
	reset: ActionFactory('RESET'),
};
