import { get, values } from 'lodash-es';
import { IAppState } from '../../../mainReducer';
import { AccessSelectionFunction } from './reducer';

export const AccessListSelector = {
	rows: (state: IAppState) => {
		const { rows, companies, functions, access } = state.specialists.lists.access.data.content;
		return rows.map((row) => {
			if (row.type === 'companies') {
				const { selected, functions } = state.specialists.lists.access.selection[row.id] || {};
				const changes = get(state.specialists.lists.access.changeRows, `[${row.id}].credentials`);

				return {
					...companies[row.id],
					header: true,
					rowId: `c/${row.id}`,
					selected,
					indeterminate:
						!selected &&
						functions &&
						values(functions).some((el) => el.selected || values(el.items).some((flag) => flag)),
					credentials: changes !== null && changes !== undefined ? changes : companies[row.id]?.credentials,
				};
			} else if (row.type === 'functions') {
				const { selected, items } = get(
					state.specialists.lists.access.selection,
					`[${row.companyId}].functions[${row.id}]`,
					{},
				) as AccessSelectionFunction;
				const currCredentials = get(state.specialists.lists.access.changeRows, `[${row.id}].credentials`);

				return {
					...functions[row.id],
					section: true,
					rowId: `f/${row.id}`,
					companyId: row.companyId,
					selected,
					indeterminate: !selected && items && values(items).some((flag) => flag),
					credentials:
						currCredentials !== null && currCredentials !== undefined
							? currCredentials
							: functions[row.id]?.credentials,
				};
			} else {
				const currCredentials = get(state.specialists.lists.access.changeRows, `[${row.id}].credentials`);

				return {
					...access[row.id],
					selected: get(
						state.specialists.lists.access.selection[row.companyId],
						`functions.${row.functionId}.items[${row.id}]`,
						false,
					),
					functionId: row.functionId,
					companyId: row.companyId,
					changed: currCredentials !== undefined,
					credentials:
						currCredentials !== null && currCredentials !== undefined
							? currCredentials
							: access[row.id]?.credentials,
				};
			}
		});
	},
	pagination: (state: IAppState) => {
		return state.specialists.lists.access.data.pagination;
	},
	status: (state: IAppState) => {
		return state.specialists.lists.access.status;
	},
	editing: (state: IAppState) => {
		return state.specialists.lists.access.status.editing;
	},
	initialLoading: (state: IAppState) => {
		return state.specialists.lists.access.status.checking;
	},
	changeRows: (state: IAppState) => {
		return state.specialists.lists.access.changeRows;
	},
	data: (state: IAppState) => {
		return state.specialists.lists.access.data;
	},
	content: (state: IAppState) => {
		return state.specialists.lists.access.data.content;
	},
	loaded: (state: IAppState) => {
		const { loading } = state.specialists.lists.access.data;
		return loading.status === 'loaded' || loading.type === 'append';
	},
	loadingMore: (state: IAppState) => {
		const { loading } = state.specialists.lists.access.data;
		return loading.type === 'append' && loading.status === 'loading';
	},
	filters: (state: IAppState) => {
		return state.specialists.lists.access.filters;
	},
	selection: (state: IAppState) => state.specialists.lists.access.selection,
	scroll: (state: IAppState) => {
		return state.specialists.lists.access.scroll;
	},
	entity: (state: IAppState) => {
		return state.specialists.lists.access.entity;
	},
};
