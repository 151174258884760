import React from 'react';
import styled from 'astroturf';
import { ReactComponent as ChevronIcon } from '../../../images/icons/ui/chevron.svg';
import { LoadingIcon } from '../indicators/LoadingIcon';
import { SelectItem } from '../../../service/typings/interface';
import { LongText } from '../../service/LongText';

interface IChildrenParams {
	data: SelectItem[];
}

interface IProps {
	value?: string;
	placeholder?: string;
	open?: boolean;
	onClick?: () => void;
	loading?: boolean;
	className?: string;
}

export const ClassifierDropdown = React.forwardRef<HTMLDivElement, IProps>(
	({ placeholder, className, value, loading, open, onClick }, ref) => {
		return (
			<Wrapper ref={ref} className={className} onClick={onClick}>
				<LongText>{value ? value : <Placeholder> {placeholder} </Placeholder>}</LongText>
				<PositionedIcon defaultIcon={<StyledChevronIcon rotated={open} />} loading={loading} />
			</Wrapper>
		);
	},
);

const PositionedIcon = styled(LoadingIcon)`
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 8px;
`;

const Wrapper = styled.div`
	@import '../../../styles/constants.scss';
	height: 32px;
	line-height: 32px;
	padding: 0 11px;
	font-size: 12px;
	background: white;
	color: $color-black;
	border: solid 1px #ededed;
	outline: 0;
	cursor: pointer;
	position: relative;
`;

const StyledChevronIcon = styled(ChevronIcon)<{ rotated?: boolean }>`
	transform: rotate(0deg);
	&.rotated {
		transform: rotate(180deg);
	}
`;

const Placeholder = styled.div`
	@import '../../../styles/constants.scss';
	color: $color-blue;
`;
