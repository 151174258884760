import { Action } from 'typescript-fsa';
import { PaginatedContentLoading } from '@ea/common';
import { Dict } from '../types';
import { initialLoadingData } from '../interfaces/store';

export function extractActionResult(result: Action<Dict>) {
	return result.payload.result;
}

export function extractActionError(error: Action<Dict>) {
	return error.payload.error;
}

export function getPaginatedContentLoading<T>(data: PaginatedContentLoading<T> | undefined, defaultValue: T) {
	return {
		pagination: {
			number: 0,
			more: true,
			...(data && data.pagination),
		},
		content: data ? data.content : defaultValue,
		loading: {
			...initialLoadingData(),
			...(data && data.loading),
		},
		error: data ? data.error : {},
	};
}
