import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { GridColumn } from '../../service/typings/grid';

interface IProps extends ICellRendererParams {
	colDef: GridColumn;
	class?: string;
	value: string;
	link?: (data?: any) => string;
	onClick?: (data?: any) => void;
	target?: string;
	disabled?: boolean;
	color?: string;
}

export class LinkRenderer implements ICellRenderer {
	element!: HTMLElement;
	link!: HTMLElement;

	public init(params: IProps) {
		const { first } = params.colDef;

		this.element = document.createElement('div');
		this.element.setAttribute('class', `ag-grid-link-cell ${first ? 'ag-grid-first-cell' : ''}`);

		if (params.color) {
			this.element.setAttribute('style', `background-color: ${params.color || 'none'}; height: 100%`);
		}

		this.link = document.createElement(params.disabled ? 'div' : 'a');
		this.link.innerHTML = params.value;
		this.link.setAttribute(
			'class',
			`ag-grid-link-cell__link ${params.disabled ? 'ag-grid-link-cell__link--disabled' : ''}`,
		);

		if (params.target) {
			this.link.setAttribute('target', params.target);
		}
		if (params.link) {
			this.link.setAttribute('href', params.link(params.node.data));
		}

		this.link.addEventListener('click', (event: MouseEvent) => {
			this.onClick(event, params);
		});

		this.element.appendChild(this.link);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		params.color && this.element.setAttribute('style', `background-color: ${params.color || 'none'}; height: 100%`);
		this.link.innerHTML = params.value;
		return true;
	}

	private onClick(event: MouseEvent, params: IProps) {
		if (params.onClick && !params.disabled) {
			params.onClick(params.node.data);
			event.preventDefault();
		}
	}
}
