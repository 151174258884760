import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { EmptyContent } from '@ea/common';
import { ProtectedRoute } from '../components/general/service/ProtectedRoute';
import AdminLayout from '../components/general/layout/AdminLayout';
import { routes } from '../core/routes/paths';
import { NotFound } from './common/NotFound';
import { Login } from './common/Login';

const SpecialistsRoutes = lazy(() => import('./specialists/Routes'));

export const Routes = () => {
	return (
		<Switch>
			<Route path={routes.login.path}>
				<Login />
			</Route>

			<Route path={routes.notFound.path}>
				<NotFound />
			</Route>

			<ProtectedRoute
				path={[
					routes.specialists.root.path,
					routes.specialists.library.root.path,
					routes.specialists.settings.root.path,
					routes.specialists.projects.root.path,
					routes.specialists.estimate.root.path,
				]}
			>
				<AdminLayout>
					<Suspense fallback={<EmptyContent />}>
						<SpecialistsRoutes />
					</Suspense>
				</AdminLayout>
			</ProtectedRoute>

			<ProtectedRoute path={'*'}>
				<NotFound />
			</ProtectedRoute>
		</Switch>
	);
};
