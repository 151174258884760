import { actionCreatorFactory } from 'typescript-fsa';

import {
	GetQuestionDetails,
	GetQuestionDetailsTemplate,
	QuestionDetailed,
	QuestionDetailedDraft,
	UpdateQuestionDetails,
} from './typings';

const ActionFactory = actionCreatorFactory('QUESTION_DETAIL');

export const QuestionDetailsActions = {
	get: ActionFactory.async<GetQuestionDetails, QuestionDetailed, Error>('GET'),
	getTemplate: ActionFactory.async<GetQuestionDetailsTemplate, QuestionDetailedDraft, Error>('GET_TEMPLATE'),
	update: ActionFactory.async<UpdateQuestionDetails, QuestionDetailed, Error>('UPDATE'),
};
