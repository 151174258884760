import { actionCreatorFactory } from 'typescript-fsa';
import {
	AddClassifierRequest,
	AddClassifierResponse,
	GetClassifierRequest,
	GetClassifierResponse,
	RemoveClassifierRequest,
	RemoveClassifierResponse,
} from '../../../../service/typings/actions/classifier';

const ActionFactory = actionCreatorFactory('REFERENCES');

export const ReferencesActions = {
	getReferences: ActionFactory.async<GetClassifierRequest, GetClassifierResponse, Error>('GET_REFERENCES'),
	removeReference: ActionFactory.async<RemoveClassifierRequest, RemoveClassifierResponse, Error>('REMOVE_REFERENCE'),
	addReference: ActionFactory.async<AddClassifierRequest, AddClassifierResponse, Error>('ADD_REFERENCE'),
};
