import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import downloadIcon from '../../images/icons/download.blue.svg';
import downloadGrayIcon from '../../images/icons/download.gray.svg';

interface IProps extends ICellRendererParams {
	value: string;
	disable: string;
}

export class DownloadRenderer implements ICellRenderer {
	element!: HTMLElement;
	icon!: HTMLElement;

	public init(params: IProps) {
		this.icon = document.createElement('img');
		this.icon.setAttribute('src', downloadIcon);

		this.element = document.createElement('a');
		this.element.setAttribute('class', 'ag-grid-with-padding-cell ag-grid-centered-cell');
		this.element.setAttribute('target', '_blank');
		this.element.setAttribute('download', 'true');
		this.element.appendChild(this.icon);

		this.render(params);
	}

	public render(params: IProps) {
		if (!params.value || params.disable) {
			this.icon.setAttribute('src', downloadGrayIcon);
		} else {
			if (params.value) {
				this.element.setAttribute('href', `/${params.value}`);
			}
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
