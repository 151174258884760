import React from 'react';
import styled from 'astroturf';
import { Checkbox } from '@ea/common';
import { GridRow } from '../../service/typings/grid';

interface IProps {
	node: any;
	onRowSelected: (node: GridRow) => void;
}

export class CheckboxRenderer extends React.Component<IProps> {
	onChange = () => {
		const { onRowSelected, node } = this.props;
		onRowSelected(node.data);
	};

	render() {
		const { node } = this.props;
		return (
			<CheckboxWrapper disabled={node.data.unselectable}>
				<Checkbox
					disabled={node.data.unselectable}
					indeterminate={node.data.indeterminate}
					checked={node.data.selected || false}
					onChange={this.onChange}
				/>
			</CheckboxWrapper>
		);
	}
}

const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	&.disabled {
		cursor: not-allowed;
	}
`;
