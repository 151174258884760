import { actionCreatorFactory } from 'typescript-fsa';
import { Reference } from '../../../../service/typings/entites';
import {
	AddCompetenceTypeRequest,
	GetCompetenceTypesRequest,
	RemoveCompetenceTypeRequest,
	RemoveCompetenceTypeResponse,
	UpdateCompetenceTypeRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPETENCE_TYPES');

export const CompetenceTypesActions = {
	addType: ActionFactory.async<AddCompetenceTypeRequest, Reference, Error>('ADD_TYPE'),
	getTypes: ActionFactory.async<GetCompetenceTypesRequest, Reference[], Error>('GET_TYPES'),
	updateType: ActionFactory.async<UpdateCompetenceTypeRequest, Reference, Error>('UPDATE_TYPE'),
	removeType: ActionFactory.async<RemoveCompetenceTypeRequest, RemoveCompetenceTypeResponse, Error>('REMOVE_TYPE'),
};
