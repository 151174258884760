import React, { FC } from 'react';
import styled from 'astroturf';
import { hexToRGB } from '../../service/utils/color';

interface Props {
	color?: string;
	lighten?: boolean;
	className?: string;
}

export const ColoredCard: FC<Props> = ({ children, color, lighten, className }) => {
	const borderLeftColor = color && lighten ? hexToRGB(color, 0.4) : color;
	return (
		<Wrapper className={className} style={{ borderLeftColor }}>
			{children}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	@import '../../styles/constants.scss';
	background: $color-gray;
	border-left: solid 10px #fce2b7;
`;
