import React, { MouseEvent, useCallback } from 'react';
import styled from 'astroturf';
import { ReactComponent as Cross } from '../../../images/icons/remove-reference.svg';
import { GridReference } from '../../../service/typings/grid';

interface Props {
	value?: GridReference;
	onClear?: () => void;
	onClick?: () => void;
	clearable?: boolean;
	disabled?: boolean;
}

export const ClassifierValueCell = React.forwardRef<HTMLDivElement, Props>(
	({ value, onClear, onClick, disabled, clearable }, ref) => {
		const onClearInternal = (event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			if (onClear) {
				onClear();
			}
		};

		const onClickInternal = () => {
			if (!disabled && onClick) {
				onClick();
			}
		};

		// TODO: потенциально опасное место, если value не будет определён
		return value ? (
			<Wrapper ref={ref} style={{ backgroundColor: value && value.color }}>
				<Content onClick={onClickInternal} disabled={disabled}>
					<Value>{value.name}</Value>
					{clearable && <RemoveButton onClick={onClearInternal} />}
				</Content>
			</Wrapper>
		) : null;
	},
);

const RemoveButton = styled(Cross)`
	margin-left: auto;
	flex-shrink: 0;
	opacity: 0;
	transition: 0.5s;
`;

const Value = styled.div`
	width: 100%;
	height: 100%;
	margin-right: 12px;
	line-height: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Content = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: flex-start;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
	&.disabled {
		cursor: auto;
	}
`;

const Wrapper = styled.div`
	display: flex;
	min-width: 100%;
	min-height: 100%;
	padding: 8px 12px;
	box-sizing: border-box;
	&:hover {
		${RemoveButton} {
			opacity: 1;
		}
	}
`;
