import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { CompanyDetailed } from '../../../../service/typings/entites';
import { CompanyDetailsActions } from './actions';

export interface CompanyDetailsState {
	data: ContentLoading<CompanyDetailed | null>;
}

const initialState = (): CompanyDetailsState => ({
	data: initialContentLoading(null),
});

export const CompanyDetailsReducer = reducerWithInitialState(initialState())
	.case(CompanyDetailsActions.get.started, (state) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(CompanyDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loaded',
			},
			error,
		},
	}))
	.case(CompanyDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}));
