import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { forEach, get, isArray, mapValues, pick, uniq, values } from 'lodash-es';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { Dict } from '../../../../core/types';
import { join } from '../../../../core/services/lists';
import { transformToObject } from '../../../../core/services/utils';
import {
	ACCESS_LIST_PAGE_SIZE,
	AccessEntity,
	accessFields,
} from '../../../../pages/specialists/Settings/Administration/AdministrationUserPage/Access/AccessTable/AccessTable';
import { Row } from '../../../../service/typings/lists';
import { GridStatistics, GridColumn } from '../../../../service/typings/grid';
import { AccessShort, CompanyShort, FunctionShort } from '../../../../service/typings/entites';
import { FilterDefinition } from '../../../../service/typings/filters';
import { AccessListActions } from './actions';
import { Access, AccessListResponse, AccessRow, AccessRowChanges } from './typings';

export interface AccessListData {
	companies: Dict<CompanyShort>;
	functions: Dict<FunctionShort>;
	access: Dict<AccessShort>;
	rows: Row[];
	changed: boolean;
}

export interface AccessListSelection {
	rows: Dict<boolean>;
	companies: Dict<GridStatistics>;
	functions: Dict<GridStatistics>;
}

export interface AccessSelectionCompany {
	total: number;
	selected: boolean;
	functions: Record<string, AccessSelectionFunction>;
}

export interface AccessSelectionFunction {
	total: number;
	selected: boolean;
	items: Record<string, AccessSelectionRow>;
}

export type AccessSelectionRow = boolean;

export type AccessSelection = Record<string, AccessSelectionCompany>;

export interface AccessListState {
	entity?: AccessEntity;
	data: PaginatedParametrizedContentLoading<AccessListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: AccessSelection;
	changeRows: AccessRowChanges;
	status: {
		view: 'table' | 'modal';
		value: 'unknown' | 'create' | 'edit';
		checking: boolean;
		editing: boolean;
	};
	scroll: number;
}

const initialData = () => ({
	companies: {},
	functions: {},
	access: {},
	rows: [],
	changed: false,
});

const initialState: AccessListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	changeRows: {},
	selection: {},
	status: {
		value: 'unknown',
		view: 'table',
		checking: false,
		editing: false,
	},
	scroll: 0,
};

export const AccessListReducer = reducerWithInitialState(initialState)
	.case(AccessListActions.resetChanges, (state) => {
		return {
			...state,
			changeRows: {},
		};
	})
	.case(AccessListActions.setEditing, (state, payload) => {
		return {
			...state,
			status: {
				...state.status,
				editing: payload,
			},
		};
	})
	.case(AccessListActions.applyChanges, (state) => {
		const changes = state.changeRows;

		return {
			...state,
			changeRows: {},
			data: {
				...state.data,
				content: {
					...state.data.content,
					functions: mapValues(state.data.content.functions, (data) => ({
						...data,
						credentials: changes[data.id] ? changes[data.id].credentials : data.credentials,
					})),
					companies: mapValues(state.data.content.companies, (data) => ({
						...data,
						credentials: changes[data.id] ? changes[data.id].credentials : data.credentials,
					})),
					access: mapValues(state.data.content.access, (data) => ({
						...data,
						credentials: changes[data.id] ? changes[data.id].credentials : data.credentials,
					})),
				},
			},
		};
	})
	.case(AccessListActions.rowChange, (state, { id, type, credentials, useSelection, ...rest }) => {
		const changes: AccessRowChanges = {};
		const content = state.data.content;
		const fields = uniq(['id', 'companyId', 'functionId', ...(state.entity ? accessFields[state.entity] : [])]);

		if (useSelection) {
			if (type === 'company') {
				const companySelection = state.selection[id];
				if (companySelection) {
					forEach(companySelection.functions, (functionData, functionId) => {
						if (functionData.selected) {
							changes[functionId] = {
								type: 'function',
								companyId: content.functions[functionId]?.companyId,
								credentials: Math.min(credentials, content.functions[functionId]?.credentialsMax || 0),
							};
						}
						forEach(functionData.items, (selected, accessId) => {
							if (selected) {
								changes[accessId] = {
									...pick(content.access[accessId], fields),
									type: 'access',
									credentials: Math.min(credentials, content.access[accessId]?.credentialsMax || 0),
								};
							}
						});
					});
				}
			} else if (type === 'function') {
				const companyId = get(content.functions[id], `companyId`);
				if (companyId) {
					const companySelection = state.selection[companyId];
					if (companySelection && companySelection.functions[id]) {
						forEach(companySelection.functions[id].items, (selected, accessId) => {
							if (selected) {
								changes[accessId] = {
									...pick(content.access[accessId], fields),
									type: 'access',
									credentials: Math.min(credentials, content.access[accessId]?.credentialsMax || 0),
								};
							}
						});
					}
				}
			}
		}

		if (type === 'access' && credentials === -1) {
			const functionCredentials = (state.changeRows[rest.functionId] || content.functions[rest.functionId])
				?.credentials;
			const credentialsMax = rest.credentialsMax;

			changes[id] = {
				...changes[id],
				...pick(content.access[id], fields),
				type,
				id,
				credentials: functionCredentials > credentialsMax ? credentialsMax : credentials,
			};
		} else if (type === 'access') {
			changes[id] = { ...pick(content.access[id], fields), type, credentials, id } as AccessRow;
		} else if (type === 'function') {
			changes[id] = { companyId: content.functions[id]?.companyId, type, credentials, id } as AccessRow;
		} else {
			changes[id] = { type, credentials, id };
		}

		return {
			...state,
			changeRows: {
				...state.changeRows,
				...changes,
			},
		};
	})
	.case(AccessListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(AccessListActions.getList.done, (state, { result, params }) => {
		const normalizeResult = normalize(result);
		return {
			...state,
			entity: params.entity,
			data: {
				content: join(initialData(), normalizeResult) as AccessListData,
				loading: {
					type: 'full',
					status: 'loaded',
				},
				pagination: {
					...result.page,
					more: true,
				},
				params,
			},
			scroll: 0,
		};
	})
	.case(AccessListActions.checkAccess.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
		selection: {},
		status: {
			...state.status,
			checking: true,
		},
	}))
	.case(AccessListActions.checkAccess.done, (state, { result, params }) => {
		return {
			...state,
			entity: params.entity !== 'questions' && result.status === 'create' ? params.entity : state.entity,
			data: {
				...state.data,
				loading: {
					type: 'full',
					status: result.status === 'edit' ? 'loading' : 'loaded',
				},
			},
			changeRows: {},
			status: {
				...state.status,
				checking: false,
				editing: params.entity === 'questions' && result.status === 'create',
				view: params.entity === 'questions' || result.status === 'edit' ? 'table' : 'modal',
				value: result.status,
			},
		};
	})
	.case(AccessListActions.getList.failed, (state, { error }) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))
	.case(AccessListActions.getListForAccessCreate.started, (state, { allLoad }) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(AccessListActions.getListForAccessCreate.done, (state, { result, params }) => {
		const normalizeResult = normalize(result);

		return {
			...state,
			entity: params.entity,
			data: {
				content: {
					...state.data.content,
					functions: normalizeResult.functions,
					companies: normalizeResult.companies,
					access: normalizeResult.access,
					rows: normalizeResult.rows,
					changed: false,
				},
				loading: {
					type: 'full',
					status: 'loaded',
				},
				pagination: {
					...result.page,
					number:
						params.allLoad && result.page.totalElements
							? Math.round(result.page.totalElements / ACCESS_LIST_PAGE_SIZE)
							: result.page.number,
					more: params.allLoad ? false : true,
				},
				params,
			},
			changeRows: {},
			scroll: 0,
			selection: updateAccessSelectionStatistics(state.selection, result),
		};
	})
	.case(AccessListActions.getListForAccessCreate.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(AccessListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(AccessListActions.loadMore.done, (state, { result, params: { size } }) => {
		const normalizeResult = normalize(result);

		return {
			...state,
			data: {
				...state.data,
				content: join(state.data.content, normalizeResult) as AccessListData,
				loading: {
					type: 'append',
					status: 'loaded',
				},
				pagination: { ...result.page, more: result._embedded.companies.length !== 0 },
			},
		};
	})
	.case(AccessListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(AccessListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(AccessListActions.getStatistics.done, (state, { result }) => {
		const normalizeResult = normalize(result, false);

		return {
			...state,
			data: {
				...state.data,
				content: {
					...state.data.content,
					...(join(state.data.content, normalizeResult, true) as AccessListData),
				},
			},
			selection: updateAccessSelectionStatistics(state.selection, result),
		};
	})
	.case(AccessListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AccessListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(AccessListActions.setAccess.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loaded',
			},
		},
		status: {
			...state.status,
			value: 'edit',
			view: 'table',
		},
		selection: {},
	}))
	.case(AccessListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(AccessListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}));

function normalize(data: AccessListResponse, rows = true) {
	const result: Omit<AccessListData, 'changed'> = {
		companies: {},
		functions: {},
		access: {},
		rows: [],
	};

	const companies = data._embedded?.companies || [];

	companies.forEach((company) => {
		const { functions, ...companyData } = company;

		result.companies[companyData.id] = {
			credentials:
				companyData.credentials !== undefined ? companyData.credentials || 0 : companyData.credentialsMax || 0,
			...companyData,
		};
		result.rows.push({
			type: 'companies',
			id: companyData.id,
		});

		functions.forEach(({ access, ...functionData }) => {
			result.functions[functionData.id] = {
				...functionData,
				companyId: companyData.id,
				credentials:
					functionData.credentials !== undefined
						? functionData.credentials || 0
						: functionData.credentialsMax || 0,
			};
			result.rows.push({
				type: 'functions',
				id: functionData.id,
				companyId: companyData.id,
			});

			access.forEach((item: Access) => {
				result.access[item.id] = {
					...item,
					credentials: item.credentials !== undefined ? item.credentials || 0 : item.credentialsMax || 0,
					functionId: functionData.id,
					companyId: companyData.id,
				};
				result.rows.push({
					type: 'access',
					id: item.id,
					functionId: functionData.id,
					companyId: companyData.id,
				});
			});
		});
	});

	if (rows) {
		return result;
	} else {
		return {
			...result,
			rows: [],
		};
	}
}

export function denormalize(
	type: AccessEntity,
	changes: AccessRowChanges,
	content: AccessListData,
	hasAccess?: boolean,
) {
	const companies: Dict = {};

	if (!hasAccess) {
		forEach(content.companies, (companyData, id) => {
			companies[id] = { ...companyData, functions: {} };
		});
		forEach(content.functions, (functionData, id) => {
			companies[functionData.companyId].functions[id] = { ...functionData, access: [] };
		});
		forEach(content.access, (accessData) => {
			const { companyId, functionId, ...data } = accessData;
			companies[companyId].functions[functionId].access.push({
				...pick(data, accessFields[type]),
				credentials: data.credentials || 0,
			});
		});
	}

	forEach(changes, (change, id) => {
		if (change.type === 'company') {
			companies[id] = {
				...companies[id],
				id: +id,
				functions: companies[id] ? companies[id].functions : {},
				credentials: change.credentials,
			};
		} else {
			const { companyId } = change as { companyId: number };
			companies[companyId] = {
				...companies[companyId],
				id: +companyId,
				functions: companies[companyId] ? companies[companyId].functions : {},
			};
		}
	});

	forEach(changes, (change, id) => {
		if (change.type === 'function') {
			const { companyId } = change as { companyId: number };
			companies[companyId].functions[id] = {
				...companies[companyId].functions[id],
				id,
				access: companies[companyId].functions[id] ? companies[companyId].functions[id].access : [],
				credentials: change.credentials,
			};
		} else if (change.type === 'access') {
			const { companyId, functionId } = change as { companyId: number; functionId: number };

			const updatedData = {
				...pick(change, hasAccess ? [...accessFields[type], 'id'] : accessFields[type]),
				credentials: change.credentials,
			};

			if (typeof (updatedData as any)['id'] === 'string') {
				delete (updatedData as any)['id'];
			}
			companies[companyId].functions[functionId] = {
				...companies[companyId].functions[functionId],
				id: functionId,
				access: companies[companyId].functions[functionId]
					? [...companies[companyId].functions[functionId].access, updatedData]
					: [updatedData],
			};
		}
	});

	return {
		companies: values(companies).map((company) => ({
			...company,
			functions: values(company.functions),
		})),
	};
}

export function updateAccessSelectionStatistics(currentSelection: AccessSelection, statistics: AccessListResponse) {
	const selection: AccessSelection = {};

	statistics._embedded.companies.forEach((companyData) => {
		const functions = transformToObject(companyData.functions, (functionData) => {
			return {
				key: functionData.id,
				value: {
					selected: false,
					total: functionData.access.length,
					items: transformToObject(functionData.access, (data) => ({
						key: data.id,
						value: false,
					})),
				},
			};
		});
		selection[companyData.id] = {
			selected: false,
			total: companyData.functions.length,
			functions,
		};
	});

	return {
		...currentSelection,
		...selection,
	};
}
