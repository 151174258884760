import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-text-truncate';
import styled from 'astroturf';

interface Props {
	lines?: number;
	className?: string;
}

export const TruncatedText: FC<Props> = ({ children, className, lines }) => {
	const [expanded, setExpanded] = useState(false);
	const { t } = useTranslation();

	const onExpand = useCallback(() => {
		setExpanded(true);
	}, [setExpanded]);

	return (
		<Wrapper className={className}>
			<Truncate
				line={!expanded && lines}
				text={children as string}
				textTruncateChild={<ExpandButton onClick={onExpand}>{t('ui.truncateExpand')}</ExpandButton>}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
`;

const ExpandButton = styled.span`
	@import '../../../../../apps/admin/src/static/styles/constants.scss';
	color: $color-blue;
	display: block;
	cursor: pointer;
	font-weight: bold;
`;
