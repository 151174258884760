export const accessTypes = [
	{
		key: 0,
		name: 'Нет доступа',
	},
	{
		key: 1,
		name: 'Чтение',
	},
	{
		key: 2,
		name: 'Изменение',
	},
	{
		key: 3,
		name: 'Добавление',
	},
	{
		key: -1,
		name: 'Как у функции',
	},
];

export const questionAccessTypes = [
	{
		key: 0,
		name: 'Нет доступа',
	},
	{
		key: 3,
		name: 'Есть доступ',
	},
];

export enum accesses {
	'DENIED' = 'DENIED',
	'REED' = 'REED',
	'EDITABLE' = 'EDITABLE',
	'ADDABLE' = 'ADDABLE',
}
const accessesObject: { [key: number]: accesses } = {
	0: accesses.DENIED,
	1: accesses.REED,
	2: accesses.EDITABLE,
	3: accesses.ADDABLE,
};

export const getAccess = (access?: number) => {
	return {
		isAccessReed: access ? accessesObject[access] === accesses.REED : false,
		isAccessAddable: access ? accessesObject[access] === accesses.ADDABLE : false,
		isAccessEditable: access ? accessesObject[access] === accesses.EDITABLE : false,
		isAccessDenied: access ? accessesObject[access] === accesses.DENIED : true,
		access: access ? accessesObject[access] : accesses.DENIED,
	};
};
