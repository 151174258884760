import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';

import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as SearchIcon } from '../../../../images/icons/ui/search.svg';

interface IProps {
	onSearch?: (query: string) => void;
}

export const SearchSection: FC<IProps> = ({ onSearch }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState('');

	const input = useRef<HTMLInputElement>(null);

	const [debouncedOnSearch] = useDebouncedCallback((query: string) => onSearch && onSearch(query), 500);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			setValue(value);
			debouncedOnSearch(value);
		},
		[setValue, debouncedOnSearch],
	);

	return (
		<Wrapper>
			<StyledSearchIcon />
			<StyledInput placeholder={t('common.actions.search')} ref={input} value={value} onChange={onChange} />
		</Wrapper>
	);
};

const StyledSearchIcon = styled(SearchIcon)`
	width: 9px;
	height: 9px;
	margin: auto 6px;
	flex-shrink: 0;
`;

const Wrapper = styled.div`
	@import '../../../../styles/constants.scss';
	height: 24px;
	min-height: 24px;
	border: solid 1px #f5f5f5;
	display: flex;
	align-items: center;
	margin: 4px 9px 6px 12px;
	padding-left: 2px;
`;

const StyledInput = styled.input`
	border: none;
	outline: none;
	min-width: 0;
	text-overflow: ellipsis;
	width: 100%;
	font-size: 12px;
	line-height: 16px;
	padding-top: 1px;
`;
