import { actionCreatorFactory } from 'typescript-fsa';
import { Reference } from '../../../../service/typings/entites';
import {
	AddCompetenceClassifierRequest,
	GetCompetenceClassifierRequest,
	RemoveCompetenceClassifierRequest,
	RemoveCompetenceClassifierResponse,
	UpdateCompetenceClassifierRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPETENCE_GROUPS');

export const CompetenceGroupsActions = {
	addGroup: ActionFactory.async<AddCompetenceClassifierRequest, Reference, Error>('ADD_GROUP'),
	getGroups: ActionFactory.async<GetCompetenceClassifierRequest, Reference[], Error>('GET_GROUPS'),
	updateGroup: ActionFactory.async<UpdateCompetenceClassifierRequest, Reference, Error>('UPDATE_GROUP'),
	removeGroup: ActionFactory.async<RemoveCompetenceClassifierRequest, RemoveCompetenceClassifierResponse, Error>(
		'REMOVE_GROUP',
	),
};
