import { useSelector } from 'react-redux';
import { filter } from 'lodash-es';
import { AuthSelectors } from '../../store/general/auth/selectors';
import { useProfile } from './useProfile';

export function useAuth() {
	const allProfiles = useSelector(AuthSelectors.profiles);

	const profile = useProfile();

	const authorized = filter(allProfiles, (el) => !!el).length > 0;

	return {
		authorized,
		profile,
	};
}
