import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectTypeProfile } from '../../service/typings/entities';
import { ProfileCard } from './ProfileCard';

interface Props {
	fullName: string;
	personalArea?: string;
	profile: ProjectTypeProfile;
	email?: string;
	className?: string;
	id?: number;
	onEdit?: () => void;
	editable?: boolean;
}

export const ProfileInfo: FC<Props> = ({ className, profile, fullName, personalArea, id, email = '' }) => {
	const { t } = useTranslation();

	const { companyName, functionName, categoryName, name } = profile;

	const structures = ['sp1', 'sp2', 'sp3', 'sp4', 'sp5'] as const;

	const fields: { label: string; value: string | null }[] = useMemo(
		() => [
			{
				label: t('common.words.company'),
				value: companyName,
			},
			{
				label: t('common.words.function'),
				value: functionName,
			},
			{
				label: t('common.words.email'),
				value: email || '',
			},
			...structures.map((item) => ({
				label: t(`common.words.${item}`),
				value: profile[item],
			})),
			{
				label: t('common.words.category'),
				value: categoryName,
			},
			{
				label: t('common.words.profile'),
				value: name,
			},
		],
		[profile],
	);

	return (
		<ProfileCard id={id} fullName={fullName} className={className} fields={fields} personalArea={personalArea} />
	);
};
