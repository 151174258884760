import React, { FC } from 'react';
import styled from 'astroturf';

import { Link } from 'react-router-dom';
import { ReactComponent as Icon } from '../../images/icons/logout.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { User } from '../../service/typings/entities';

interface Props {
	to: string;
	title: string;
	profile?: User;
	authorized?: boolean;
	onLogout?: () => void;
}

export const Header: FC<Props> = ({ to, profile, authorized, title, onLogout }) => {
	return (
		<HeaderInternal>
			<LogoWrapper to={to}>
				<Logo />
			</LogoWrapper>
			<Text>{title}</Text>
			{authorized ? (
				<>
					{profile && (
						<Name>
							{profile.lName} {profile.fName} {profile.mName}
						</Name>
					)}

					{onLogout && <LogoutButton onClick={onLogout} />}
				</>
			) : null}
		</HeaderInternal>
	);
};

const HeaderInternal = styled.div`
	@import '../../styles/constants.scss';
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 24px;
	color: $color-black;
	border-bottom: solid 1px #e6e8f1;
	height: 48px;
	padding-left: 22px;
	padding-right: 22px;
`;

const LogoWrapper = styled(Link)`
	display: flex;
	align-items: center;
	margin-top: 2px;
	margin-right: 36px;
`;

const Text = styled.div`
	margin-right: auto;
`;

const Name = styled.div`
	margin-right: 6px;
`;

const LogoutButton = styled(Icon)`
	@import '../../styles/constants.scss';
	fill: $color-blue;
	width: 16px;
	height: 16px;
	cursor: pointer;
`;
