import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { includes, isArray, mapValues } from 'lodash-es';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { Dict } from '../../../../core/types';
import { join } from '../../../../core/services/lists';
import { PersonsActions } from '../../entities/persons/actions';
import { ReferencesActions } from '../../entities/references/actions';
import {
	removeRows,
	removeRowsSelection,
	reorderColumns,
	updateSelectionStatistics,
} from '../../../../core/services/grid';
import { GridColumn, GridCommonSelection, GridStatistics } from '../../../../service/typings/grid';
import { CompanyShort, FunctionShort, PersonShort } from '../../../../service/typings/entites';
import { Row } from '../../../../service/typings/lists';
import { FilterDefinition } from '../../../../service/typings/filters';
import { PersonsListActions } from './actions';
import { GetPersonsListResponse } from './typings';

export interface PersonListData {
	companies: Dict<CompanyShort>;
	functions: Dict<FunctionShort>;
	persons: Dict<PersonShort>;
	rows: Row[];
	changed: boolean;
}

export interface PersonListSelection {
	rows: Dict<boolean>;
	companies: Dict<GridStatistics>;
	functions: Dict<GridStatistics>;
}

export interface PersonsListState {
	data: PaginatedParametrizedContentLoading<PersonListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: GridCommonSelection;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	companies: {},
	functions: {},
	persons: {},
	rows: [],
	changed: false,
});

const initialState: PersonsListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	lastParams: {},
	selection: {
		rows: {},
		companies: {},
	},
	scroll: 0,
};

export const PersonsListReducer = reducerWithInitialState(initialState)
	.case(PersonsListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(PersonsListActions.getList.done, (state, { result, params }) => ({
		...state,
		data: {
			content: join(initialData(), normalize(result)) as PersonListData,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			pagination: result.page,
			params,
		},
		scroll: 0,
	}))
	.case(PersonsListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(PersonsListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(PersonsListActions.loadMore.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: join(state.data.content, normalize(result)) as PersonListData,
			loading: {
				type: 'append',
				status: 'loaded',
			},
			pagination: result.page,
		},
	}))
	.case(PersonsListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(PersonsListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(PersonsListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(PersonsListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(PersonsListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(PersonsListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(PersonsListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(PersonsListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(PersonsListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	.case(ReferencesActions.removeReference.done, (state, { result: { id, addColumnId } }) => {
		const column = (state.columns.content || []).find((el) => el.addColumnId === addColumnId);
		if (column && column.field) {
			const field = column.field as string;
			return {
				...state,
				data: {
					...state.data,
					content: {
						...state.data.content,
						persons: mapValues(state.data.content.persons, (value) => ({
							...value,
							[field]: value[field] && value[field].id === id ? null : value[field],
						})),
					},
				},
			};
		} else {
			return state;
		}
	})
	.case(PersonsListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(PersonsListActions.getStatistics.done, (state, { result }) => ({
		...state,
		selection: updateSelectionStatistics(state.selection, result),
	}))
	.case(PersonsListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(PersonsListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(PersonsListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(PersonsListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(PersonsListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.cases([PersonsActions.batchRemove.done, PersonsActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: removeRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: removeRowsSelection(state.selection, params),
	}))
	.case(PersonsListActions.showPassword.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				persons: mapValues(state.data.content.persons, (value, id) => ({
					...value,
					password: Number(id) === result.id ? result.password : undefined,
				})),
			},
		},
	}))
	.case(PersonsListActions.hidePassword, (state, { id }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				persons: {
					...state.data.content.persons,
					[id]: {
						...state.data.content.persons[id],
						password: undefined,
					},
				},
			},
		},
	}))
	.case(PersonsActions.update.done, (state, { result: { id, data } }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				persons: mapValues(state.data.content.persons, (value) =>
					value.personId === id
						? {
								...value,
								...data,
						  }
						: value,
				),
			},
		},
	}))
	.case(ReferencesActions.removeReference.done, (state, { result: { id, addColumnId } }) => {
		const column = (state.columns.content || []).find((el) => el.addColumnId === addColumnId);
		if (column && column.field) {
			const field = column.field as string;
			return {
				...state,
				data: {
					...state.data,
					content: {
						...state.data.content,
						persons: mapValues(state.data.content.persons, (value) => ({
							...value,
							[field]: value[field] && value[field].id === id ? null : value[field],
						})),
					},
				},
			};
		} else {
			return state;
		}
	});

function normalize(data: GetPersonsListResponse) {
	const result: Omit<PersonListData, 'changed'> = {
		companies: {},
		functions: {},
		persons: {},
		rows: [],
	};

	const companies = data._embedded.companies;

	companies.forEach((company) => {
		const { functions, ...companyData } = company;

		result.companies[companyData.id] = companyData;
		result.rows.push({
			type: 'companies',
			id: companyData.id,
		});

		functions.forEach(({ persons, ...functionData }) => {
			result.functions[functionData.id] = {
				companyId: companyData.id,
				...functionData,
			};
			result.rows.push({
				type: 'functions',
				id: functionData.id,
				companyId: companyData.id,
			});

			persons.forEach((person: Dict) => {
				const data = {
					...person,
					id: person.personProjectTypeId,
					personId: person.id,
				};
				result.persons[data.id] = {
					...data,
				};
				result.rows.push({
					type: 'persons',
					id: data.id,
					functionId: functionData.id,
					companyId: companyData.id,
				});
			});
		});
	});

	return result;
}
