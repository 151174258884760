import { actionCreatorFactory } from 'typescript-fsa';
import { ProfileDetailed } from '../../details/profile/typings';
import { GridReference } from '../../../../service/typings/entites';
import {
	AddListClassifierRequest,
	GetListClassifierRequest,
	RemoveProfilesRequest,
	RestoreProfilesRequest,
	UpdateProfileRequest,
	UpdateProfileResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('PROFILES');

export const ProfilesActions = {
	get: ActionFactory.async<{}, ProfileDetailed, Error>('GET'),
	update: ActionFactory.async<UpdateProfileRequest, UpdateProfileResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemoveProfilesRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreProfilesRequest, {}, Error>('BATCH_RESTORE'),
	getClassifiers: ActionFactory.async<GetListClassifierRequest, GridReference[], Error>('GET_CLASSIFIERS'),
	addClassifier: ActionFactory.async<AddListClassifierRequest, GridReference, Error>('ADD_CLASSIFIER'),
};
