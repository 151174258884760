import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { QuestionsListActions } from '../../lists/questions/actions';
import { QuestionDetailsActions } from './actions';
import { QuestionDetailed, QuestionDetailedDraft } from './typings';

export interface QuestionDetailsState {
	data: ContentLoading<QuestionDetailed | null>;
	template: ContentLoading<QuestionDetailedDraft | null>;
}

const initialState = (): QuestionDetailsState => ({
	data: initialContentLoading(null),
	template: initialContentLoading(null),
});

export const QuestionDetailsReducer = reducerWithInitialState(initialState())
	.case(QuestionsListActions.deleteImage, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...(state.data.content || ({} as any)),
				data: {
					...(state.data.content || ({} as any)).data,
					question: {
						...(state.data.content || ({} as any)).data.question,
						image: null,
						url: undefined,
					},
				},
			},
		},
	}))
	.case(QuestionDetailsActions.get.started, (state) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(QuestionDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loaded',
			},
			error,
		},
	}))
	.case(QuestionDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(QuestionDetailsActions.getTemplate.started, (state) => ({
		...state,
		template: {
			loading: {
				status: 'loading',
			},
			content: null,
		},
	}))
	.case(QuestionDetailsActions.getTemplate.failed, (state, { error }) => ({
		...state,
		template: {
			loading: {
				status: 'loaded',
			},
			content: null,
			error,
		},
	}))
	.case(QuestionDetailsActions.getTemplate.done, (state, { result }) => ({
		...state,
		template: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(QuestionDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}));
