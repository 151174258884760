import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { isFunction } from 'lodash-es';

interface Props extends ICellRendererParams {
	value: string;
	color?: string;
}

export class ColoredRenderer implements ICellRenderer {
	element!: HTMLElement;

	public init(params: Props) {
		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-with-padding-cell');
		this.render(params);
	}

	public render(params: Props) {
		const { value, color } = params;
		this.element.innerHTML = value || '';
		this.element.setAttribute(
			'style',
			`background-color: ${isFunction(color) ? color(params.data) : color || 'none'};`,
		);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.render(params);
		return true;
	}
}
