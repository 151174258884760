import React, { FC, useCallback, useRef, useState } from 'react';
import styled from 'astroturf';
import { isNumber } from 'lodash-es';
import { ReactComponent as HidePassword } from '../../icons/ui/hide-password.svg';
import { ReactComponent as ShowPassword } from '../../icons/ui/show-password.svg';

interface IProps {
	type?: 'text' | 'password' | 'tel' | string;
	placeholder?: string;
	className?: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	name?: string;
	autocomplete?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	decimal?: boolean;
	maxLength?: number;
	min?: number;
	max?: number;
}

export const MaterialInput: FC<IProps> = ({
	type = 'text',
	placeholder = '',
	className,
	value,
	disabled,
	onBlur,
	onChange,
	readOnly,
	min,
	max,
	decimal,
	maxLength,
	...rest
}) => {
	const [innerType, setInnerType] = useState(type === 'number' ? 'text' : type);
	const [focus, setFocus] = useState(false);
	const input = useRef<HTMLInputElement>(null);

	const onFocusInternal = useCallback(() => {
		setFocus(true);
	}, [setFocus]);

	const onBlurInternal = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			onBlur && onBlur(event);
			setFocus(false);
		},
		[setFocus, onBlur],
	);

	const onChangeNew = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (type === 'number' && !decimal && event.target) {
			const numericValue = Number(event.target.value);
			if (min && numericValue < min) {
				event.target.value = String(min);
			} else if (max && numericValue > max) {
				event.target.value = String(max);
			}
		}

		if (type === 'number' || decimal) {
			event.target.value = event.target.value
				.replace(decimal ? /[^0-9.]/g : /[^0-9]/g, '')
				.replace(/[.]{1,3}/, '.');

			const splitValue = event.target.value.split('.');

			if (!splitValue[0] && splitValue[1]) {
				event.target.value = `0.${splitValue[1]}`;
			}

			const inputValue = event.target.value;

			if (min && +inputValue < min) {
				event.target.value = String(min);
			} else if (max && +inputValue > max) {
				event.target.value = String(max);
			}
		}

		if (maxLength && String(event.target.value).length > maxLength && input.current) {
			input.current.value = input.current.value.substr(0, maxLength);
			event.target.value = input.current.value.substr(0, maxLength);
		}

		onChange && onChange(event);
	};

	const onAnimationStart = (e: React.AnimationEvent) => {
		if (e.animationName.indexOf('onAutoFillStart') !== -1) {
			setFocus(true);
		} else if (e.animationName.indexOf('onAutoFillCancel') !== -1) {
			setFocus(false);
		}
	};

	const onPlaceholderClick = useCallback(() => {
		!disabled && input.current && input.current.focus();
	}, [disabled]);

	const onSetInnerType = useCallback(
		(newType: 'text' | 'password') => () => {
			setInnerType(newType);
		},
		[],
	);

	return (
		<InputContainer className={className}>
			<Placeholder focus={focus || isNumber(value) || (!!value && !!value.length)} onClick={onPlaceholderClick}>
				{placeholder}
			</Placeholder>
			<CustomInput
				lighten={readOnly}
				ref={input}
				type={innerType}
				placeholder={''}
				onFocus={onFocusInternal}
				onBlur={onBlurInternal}
				value={value}
				readOnly={readOnly}
				step={'any'}
				onChange={onChangeNew}
				onAnimationStart={onAnimationStart}
				withIcon={type === 'password'}
				{...rest}
			/>
			{type === 'password' ? (
				<IconContainer password={innerType === 'text'}>
					{innerType === 'password' ? (
						<ShowPassword onClick={onSetInnerType('text')} />
					) : (
						<HidePassword onClick={onSetInnerType('password')} />
					)}
				</IconContainer>
			) : null}
		</InputContainer>
	);
};

const InputContainer = styled.div`
	position: relative;
	height: 44px;
`;

export const Placeholder = styled.label<{ focus?: boolean; disabled?: boolean }>`
	position: absolute;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.1s ease-in-out;
	left: 24px;
	white-space: nowrap;
	max-width: calc(100% - 48px) !important;
	text-overflow: ellipsis;
	overflow: hidden;
	border-bottom: 1px solid transparent;
	z-index: 2;
	color: rgba(33, 33, 33, 0.5);
	user-select: none;
	cursor: text;
	top: 12px;
	display: flex;

	&:first-letter {
		text-transform: capitalize !important;
	}
	&:before {
		display: block;
		left: -2px;
		right: -2px;
		position: absolute;
		top: 2px;
		height: 12px;
		background-color: #fff;
		z-index: -1;
	}

	&.focus {
		font-size: 12px;
		line-height: 1.2;
		color: rgba(33, 33, 33, 0.5);
		padding: 0 4px;
		top: -10px;
		left: 22px;

		&:before {
			content: '';
		}
	}
	&.disabled {
		color: #dcdcdc;
		cursor: default;
	}
`;

export const CustomInput = styled.input<{
	withIcon?: boolean;
	lighten?: boolean;
}>`
	border-radius: 5px;
	border: solid 1px #dcdcdc;
	color: #212121;
	font-size: 16px;
	font-family: lato, -apple-system, Roboto, BlinkMacSystemFont, serif;
	min-width: 10px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	line-height: 1.4;
	font-weight: 700;
	-webkit-appearance: none;
	height: 44px;
	background-color: transparent;
	animation: onAutoFillCancel 5000s linear;
	position: relative;
	padding: 10px 9px 10px 24px;

	&.lighten {
		color: #4d4d4d;
	}

	&.withIcon {
		padding: 10px 28px 10px 24px;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&:-webkit-autofill {
		color: #212121;
		font-size: 16px;
		background-color: #f4f;
		animation: onAutoFillStart 5000s linear;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background-color: initial;
		-webkit-text-size-adjust: initial;
		-webkit-text-fill-color: initial;
		-webkit-box-shadow: 0 0 0 1000px #fff inset;
	}

	&::-webkit-credentials-auto-fill-button,
	&::-webkit-contacts-auto-fill-button {
		position: relative;
		top: 2px;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		color: rgba(#212121, 0.6);
	}

	@keyframes onAutoFillStart {
		// Workaround to force nesting this keyframe
		0% {
			position: relative;
		}
		100% {
			position: relative;
		}
	}

	@keyframes onAutoFillCancel {
		// Workaround to force nesting this keyframe
		0% {
			position: relative;
		}
		100% {
			position: relative;
		}
	}
`;

const IconContainer = styled.div<{ password?: boolean }>`
	position: absolute;
	width: 22px;
	height: 22px;
	right: 10px;
	top: 10px;
	cursor: pointer;
	fill: rgba(117, 117, 117, 0.54);
`;
