import { useCallback, useMemo, useState } from 'react';

type Modal<T> = [{ open: boolean } & Partial<T>, (newState: T) => void, () => void];

export function useStatefulModal<T>(): Modal<T> {
	const [open, setOpen] = useState(false);
	const [state, setState] = useState<Partial<T>>({});

	const onOpen = useCallback(
		(newState: T) => {
			setOpen(true);
			setState(newState);
		},
		[setOpen],
	);

	const onClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return useMemo(() => {
		return [
			{
				open,
				...state,
			},
			onOpen,
			onClose,
		];
	}, [onOpen, onClose, state, open]);
}
