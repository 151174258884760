import React from 'react';
import styled from 'astroturf';
import MaterialCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import checked from '../../../images/icons/checkbox/checked.svg';
import indeterminate from '../../../images/icons/checkbox/indeterminate.svg';

export const Checkbox = (props: CheckboxProps) => {
	return (
		<StyledMaterialCheckbox
			color="default"
			checkedIcon={
				<Base disabled={props.disabled}>
					<img src={checked} />
				</Base>
			}
			indeterminateIcon={
				<Base disabled={props.disabled}>
					<img src={indeterminate} />
				</Base>
			}
			icon={<Base disabled={props.disabled} />}
			{...props}
		/>
	);
};

const StyledMaterialCheckbox = styled(MaterialCheckbox)`
	padding: 6px !important;
`;

const Base = styled.div<{ disabled?: boolean }>`
	width: 16px;
	height: 16px;
	border: solid 1px #757575;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background 0.5s;
	&.disabled {
		background-color: #dedede;
	}
`;
