import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useIsServer } from '../hooks/useIsServer';

interface Props {
	id?: string;
}

export const EmptyPortal: FC<Props> = ({ children, id }) => {
	const isServer = useIsServer();

	return !isServer && id ? createPortal(<>{children}</>, document.getElementById(id) as HTMLDivElement) : null;
};
