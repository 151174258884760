import React from 'react';
import styled from 'astroturf';
import { Tooltiped } from '@ea/common';
import { ReactComponent as WarningIcon } from '../../../images/icons/warning.svg';

interface IProps {
	value: string;
	error?: (value: number) => boolean;
	warning?: (value: number) => boolean;
}

export class ValidationRenderer extends React.Component<any> {
	render() {
		const { value, error, warning } = this.props;

		const hasError = error ? error(value) : false;
		const hasWarning = warning ? warning(value) : false;
		return (
			<Wrapper invalid={hasError}>
				{warning ? (
					<Content>
						<FirstHalf>{value}</FirstHalf>
						<SecondHalf>
							{hasWarning && (
								<Tooltiped text={warning(value)} show>
									<WarningIcon />
								</Tooltiped>
							)}
						</SecondHalf>
					</Content>
				) : (
					value
				)}
			</Wrapper>
		);
	}
}

const Wrapper = styled.div<{ invalid?: boolean }>`
	height: 100%;
	padding: 6px 11px;
	&.invalid {
		background: rgba(255, 0, 0, 0.1);
	}
`;

const Content = styled.div<{ invalid?: boolean }>`
	width: 100%;
	display: flex;
`;

const FirstHalf = styled.div`
	width: 50%;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const SecondHalf = styled.div`
	width: 50%;
	text-align: left;
	display: flex;
	align-items: center;
	padding-left: 9px;
`;
