import { actionCreatorFactory } from 'typescript-fsa';
import { GetQuestionsListRequest, GetQuestionsListResponse } from '../../lists/questions/typings';
import { GetStatisticsRequest, GetStatisticsResponse } from '../../../../service/typings/actions/statistics';
import {
	RemoveQuestionsRequest,
	RestoreQuestionsRequest,
	UpdateQuestionRequest,
	UpdateQuestionResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('QUESTIONS');

export const QuestionsActions = {
	getList: ActionFactory.async<GetQuestionsListRequest, GetQuestionsListResponse, Error>('GET_LIST'),
	getStatistics: ActionFactory.async<GetStatisticsRequest, GetStatisticsResponse, Error>('GET_STATISTICS'),
	update: ActionFactory.async<UpdateQuestionRequest, UpdateQuestionResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemoveQuestionsRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreQuestionsRequest, {}, Error>('BATCH_RESTORE'),
};
