import React, { FC } from 'react';
import styled from 'astroturf';
import addIcon from '../../../images/icons/columns/reference.svg';
import pulseIcon from '../../../images/indicators/pulse.svg';

interface IProps {
	loading?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

export const ClassifierEmptyCell = React.forwardRef<HTMLDivElement, IProps>(({ loading, disabled, onClick }, ref) => {
	const onClickInternal = () => {
		if (!disabled && onClick) {
			onClick();
		}
	};

	return (
		<Wrapper ref={ref} onClick={onClickInternal} disabled={disabled}>
			<AddIcon src={addIcon} visible={!loading} />
			<Loading src={pulseIcon} visible={loading} />
		</Wrapper>
	);
});

const AddIcon = styled.img<{ visible?: boolean }>`
	opacity: 0;
	transition: 0.5s;
	flex-shrink: 0;
	display: block;
	height: 13px;
	width: 13px;
	&.visible {
		opacity: 1;
	}
`;

const Loading = styled.img<{ visible?: boolean }>`
	opacity: 0;
	width: 13px;
	height: 13px;
	position: absolute;
	left: 12px;
	top: 9px;
	pointer-events: none;
	transition: 0.5s;
	&.visible {
		opacity: 1;
	}
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
	cursor: pointer;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 8px 12px;
	&.disabled {
		cursor: auto;
	}
`;
