import { combineReducers } from 'redux';
import { SpecialistsReducer } from './specialists/reducer';
import { GeneralReducer } from './general/reducer';

export const mainReducer = combineReducers({
	general: GeneralReducer,
	specialists: SpecialistsReducer,
});

export type IAppState = ReturnType<typeof mainReducer>;
