import React, { FC, memo, useMemo, useState } from 'react';
import styled from 'astroturf';
import { range } from 'lodash-es';

export interface ClassificationLevelProps {
	level: number;
	maxLevel?: number;
	editing?: boolean;
	className?: string;
	onChange?: (value: number) => void;
	simplified?: boolean;
	invalid?: boolean;
}

export const ClassificationLevel: FC<ClassificationLevelProps> = memo(
	({ level, className, maxLevel = 4, editing = false, simplified = false, invalid = false, onChange }) => {
		if (!level) {
			level = 0;
		}

		const status = useMemo(() => {
			if (level <= maxLevel / 4) {
				return 'bad';
			} else if (level <= maxLevel / 2) {
				return 'medium';
			} else if (level <= (maxLevel * 3) / 4) {
				return 'good';
			} else {
				return 'perfect';
			}
		}, [level, maxLevel]);

		const onChangeInternal = (value: number) => () => {
			if (onChange && editing) {
				onChange(value);
			}
		};

		return (
			<Wrapper className={className}>
				<Levels>
					{range(maxLevel).map((i) => {
						return (
							<LevelWrapper key={i} editing={editing} onClick={onChangeInternal(i + 1)}>
								<Level invalid={invalid} value={i < level ? status : 'empty'} editing={editing} />
							</LevelWrapper>
						);
					})}
				</Levels>
				{!simplified && (
					<Value>
						{level} / {maxLevel}
					</Value>
				)}
			</Wrapper>
		);
	},
);

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	line-height: 12px;
`;

const Levels = styled.div`
	width: 100%;
	min-width: 139px;
	display: flex;
`;
const Level = styled.button<{ value: string; editing?: boolean; invalid?: boolean }>`
	background: #d8d8d8;
	height: 8px;
	width: 100%;
	outline: none;
	border: solid 1px transparent;
	cursor: inherit;
	transition: 0.5s;
	&.value-bad {
		background: #f7a9a8;
		border-color: #f7a9a8;
	}
	&.value-medium {
		background: #fff59c;
		border-color: #fff59c;
	}
	&.value-good {
		background: #c5e1a5;
		border-color: #c5e1a5;
	}
	&.value-perfect {
		background: #80c684;
		border-color: #80c684;
	}
	&.editing.value-empty {
		background: white;
		border: solid 1px #008ac6;
	}
	&.editing.invalid {
		border: solid 1px #ff4256;
	}
`;

const LevelWrapper = styled.div<{ editing?: boolean }>`
	width: 100%;
	padding-right: 0;
	height: 30px;
	display: flex;
	align-items: center;
	&:not(:last-child) {
		padding-right: 2px;
	}
	&.editing {
		cursor: pointer;
	}
`;

const Value = styled.div`
	margin-left: 22px;
`;
