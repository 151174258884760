import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer } from 'ag-grid-community';
import { GridRow } from '../../service/typings/grid';
import { CheckboxRenderer } from './CheckboxRenderer';

export class FullWidthRenderer implements ICellRenderer {
	params: any;
	parentElement!: HTMLElement;
	value: any;

	// TODO: костыль, добавленый постольку, поскольку в refresh не передаются fullWidthCellRendererParams
	onRowSelected?: Function;
	selectable?: boolean;
	rightContentRenderer?: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
			this.onRowSelected = params.onRowSelected;
			this.selectable = params.selectable;
			this.rightContentRenderer = params.rightContentRenderer;
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(
				<Renderer
					onRowSelected={this.onRowSelected}
					selectable={this.selectable}
					rightContentRenderer={this.rightContentRenderer}
					{...params}
				/>,
				this.parentElement,
			);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
	}
}

interface Props {
	node: any;
	onRowSelected: (node: GridRow) => void;
	selectable: boolean;
	value: boolean;
	className?: string;
	rightContentRenderer?: any;
}

const Renderer = (props: Props) => {
	const { node, onRowSelected, selectable, rightContentRenderer, className } = props;
	return (
		<FullWidthWrapper
			header={node.data.header}
			section={node.data.section}
			notSelectable={!selectable}
			style={{ background: node.data.color }}
			className={className}
		>
			{selectable && (
				<CheckboxWrapper>
					<CheckboxRenderer node={node} onRowSelected={onRowSelected} />
				</CheckboxWrapper>
			)}
			<Text>{node.data.name}</Text>
			{rightContentRenderer && <RightContentWrap>{rightContentRenderer(node)}</RightContentWrap>}
		</FullWidthWrapper>
	);
};

const CheckboxWrapper = styled.div`
	margin-right: 18px;
	// TODO: костыль, чтобы выровнять чекбоксы
	padding-left: 1px;
`;
const RightContentWrap = styled.div`
	width: 100%;
	padding-left: 5px;
	font-weight: normal;
	padding-right: 1px;
`;

const FullWidthWrapper = styled.div<{ header?: boolean; section?: boolean; notSelectable?: boolean }>`
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: bold;
	&.header {
		background: rgb(255, 165, 0);
		font-size: 14px;
		color: white;
	}
	&.section {
		background: rgb(252, 226, 183);
	}
	&.notSelectable {
		padding-left: 18px;
	}
`;

const Text = styled.div``;
