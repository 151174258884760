import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { isUndefined } from 'lodash-es';

interface IProps extends ICellRendererParams {
	value: number;
	color: string;
}

export class ScoreRenderer implements ICellRenderer {
	element!: HTMLElement;

	public init(params: IProps) {
		this.element = document.createElement('div');
		this.render(params);
	}

	public render(params: IProps) {
		const { value, color } = params;

		if (!isUndefined(value) && value !== null) {
			this.element.setAttribute('style', `background-color: ${color}`);
			this.element.innerHTML = String(value);
		} else {
			this.element.innerHTML = '';
		}
		this.element.setAttribute('class', 'ag-grid-score-cell');
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
