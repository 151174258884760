import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

interface Props extends ICellRendererParams {
	value: {
		id: number;
		name: string;
		jobId?: string;
	}[];
}

export class WithCounterRenderer implements ICellRenderer {
	element!: HTMLElement;
	content!: HTMLElement;
	number!: HTMLElement;

	public init(params: Props) {
		this.number = document.createElement('div');
		this.number.setAttribute(
			'class',
			'ag-grid-with-counter-cell__number ag-grid-with-counter-cell__number--hidden',
		);

		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-with-counter-cell');

		this.content = document.createElement('div');
		this.content.setAttribute('class', 'ag-grid-with-counter-cell__content');

		this.element.appendChild(this.content);
		this.element.appendChild(this.number);

		this.render(params);
	}

	public render(params: Props) {
		const { value } = params;

		this.content.innerHTML =
			value && value.length ? `${value[0].jobId ? value[0].jobId + ': ' : ''}${value[0].name}` : '';
		if (value && value.length > 1) {
			this.number.classList.remove('ag-grid-with-counter-cell__number--hidden');
			this.number.innerHTML = `+${Math.min(9, value.length - 1)}`;
		} else {
			this.number.classList.add('ag-grid-with-counter-cell__number--hidden');
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.render(params);
		return true;
	}
}
