import styled from 'astroturf';
import React, { FC, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as PulseIcon } from '../../../images/indicators/pulse.svg';

interface Props {
	loading?: boolean;
	loadingIcon?: ReactNode;
	defaultIcon?: ReactNode;
	className?: string;
	id?: string;
}

export const LoadingIcon: FC<Props> = ({ className, id, defaultIcon, loadingIcon, loading }) => {
	return (
		<Wrapper className={className} id={id}>
			<CSSTransition in={!loading} timeout={500} classNames="appear" unmountOnExit>
				{defaultIcon}
			</CSSTransition>
			<CSSTransition in={loading} timeout={500} classNames="slow-appear" unmountOnExit>
				{loadingIcon || <StyledPulseIcon />}
			</CSSTransition>
		</Wrapper>
	);
};

const StyledPulseIcon = styled(PulseIcon)`
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
