// @ts-nocheck

import React, { PureComponent } from 'react';
import { isFunction, throttle } from 'lodash-es';
import styled from 'astroturf';
import { Dict } from '../../../../service/typings/basic';

interface Props extends Dict {
	onChange: Function;
	to?: number;
	from?: number;
}

const calculateChange = (e, hsl, container, from = 0, to = 1) => {
	const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
	const x = typeof e.pageX === 'number' ? e.pageX : e.touches[0].pageX;
	const y = typeof e.pageY === 'number' ? e.pageY : e.touches[0].pageY;
	let left = x - (container.getBoundingClientRect().left + window.pageXOffset);
	let top = y - (container.getBoundingClientRect().top + window.pageYOffset);

	if (left < 0) {
		left = 0;
	} else if (left > containerWidth) {
		left = containerWidth;
	}

	if (top < 0) {
		top = 0;
	} else if (top > containerHeight) {
		top = containerHeight;
	}

	const saturation = left / containerWidth;
	const bright = from + (1 - top / containerHeight) * (to - from);

	return {
		h: hsl.h,
		s: saturation,
		v: bright,
		a: hsl.a,
		source: 'hsv',
	};
};

export class LimitedSaturation extends PureComponent<Props> {
	constructor(props) {
		super(props);

		this.throttle = throttle((fn, data, e) => {
			fn(data, e);
		}, 50);
	}

	componentWillUnmount() {
		this.throttle.cancel();
		this.unbindEventListeners();
	}

	handleChange = (e) => {
		const { onChange, hsl, from, to } = this.props;
		if (isFunction(onChange)) {
			this.throttle(onChange, calculateChange(e, hsl, this.container, from, to), e);
		}
	};

	handleMouseDown = (e) => {
		this.handleChange(e);
		window.addEventListener('mousemove', this.handleChange);
		window.addEventListener('mouseup', this.handleMouseUp);
	};

	handleMouseUp = () => {
		this.unbindEventListeners();
	};

	unbindEventListeners() {
		window.removeEventListener('mousemove', this.handleChange);
		window.removeEventListener('mouseup', this.handleMouseUp);
	}

	render() {
		const { from, to, hsv, radius, shadow } = this.props;

		const color = {
			background: `hsl(${hsv.h}, 100%, 50%)`,
			borderRadius: radius,
		};

		const white = {
			borderRadius: radius,
			background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))`,
		};

		const black = {
			boxShadow: shadow,
			borderRadius: radius,
			background: `linear-gradient(to top, rgba(0,0,0,${1 - from}), rgba(0,0,0,${1 - to}))`,
		};

		const pointer = {
			top: `${-(Math.min(1, (hsv.v - from) / (to * (1 - from))) * 100) + 100}%`,
			left: `${hsv.s * 100}%`,
		};

		return (
			<Color
				style={color}
				ref={(container) => (this.container = container)}
				onMouseDown={this.handleMouseDown}
				onTouchMove={this.handleChange}
				onTouchStart={this.handleChange}
			>
				<SaturationWhite style={white}>
					<SaturationBlack style={black} />
					<Pointer style={pointer}>
						{this.props.pointer ? <this.props.pointer {...this.props} /> : <Circle />}
					</Pointer>
				</SaturationWhite>
			</Color>
		);
	}
}

const Absolute = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const Color = styled(Absolute)``;

const SaturationWhite = styled(Absolute)`
	background: linear-gradient(to right, #a6a6a6, rgba(255, 255, 255, 0));
`;
const SaturationBlack = styled(Absolute)`
	background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
`;

const Circle = styled.div`
	width: 4px;
	height: 4px;
	box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 0 0 1px 2px rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	cursor: hand;
	transform: translate(-2px, -2px);
`;

const Pointer = styled.div`
	position: absolute;
	cursor: default;
`;
