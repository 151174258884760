import { useCallback, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ColumnResizedEvent, DragStoppedEvent } from 'ag-grid-community';
import { get, keys, merge } from 'lodash-es';
import { Dict } from '@ea/common';
import { GridColumn, GridSort } from '../typings/grid';
import { addSorting } from '../utils';

interface Options {
	sorting?: GridSort;
	column: Dict<Partial<LocalGridColumn>>;
	localStorage?: string;
}

interface LocalGridColumn extends GridColumn {
	order?: number;
}

function getLocalStorageSettings(key: string) {
	const value = localStorage.getItem(key);
	return value ? JSON.parse(value) : {};
}

export function useLocalGridColumns(options: Options) {
	const columnOptions = options.column;
	const sortingOptions = options.sorting;
	const localStorageKey = options.localStorage;

	const [customOptions, setCustomOptions] = useState(() => {
		if (localStorageKey) {
			return getLocalStorageSettings(localStorageKey);
		} else {
			return {};
		}
	});

	const columns = useMemo(() => {
		return addSorting(
			keys(columnOptions)
				.map((field, index) => ({
					field,
					order: index,
					...columnOptions[field],
					...customOptions[field],
					suppressSizeToFit: !get(columnOptions, `${field}.autoWidth`),
					headerName: columnOptions[field].headerName || '',
				}))
				.sort((a, b) => a.order - b.order),
			sortingOptions,
		);
	}, [columnOptions, customOptions, sortingOptions]);

	// Used to aggregate different debounced changes
	const accumulatedChanges = useRef<Dict<Partial<LocalGridColumn>>>({});

	const updateColumns = useCallback(() => {
		const options = merge(customOptions, accumulatedChanges.current);
		if (localStorageKey) {
			localStorage.setItem(localStorageKey, JSON.stringify(options));
		}
		setCustomOptions(options);
		accumulatedChanges.current = {};
	}, [customOptions, setCustomOptions, localStorageKey, accumulatedChanges.current]);

	const [debouncedUpdateColumns] = useDebouncedCallback(updateColumns, 250);

	const onColumnEdited = useCallback(
		(field: string, value: Partial<LocalGridColumn>) => {
			accumulatedChanges.current[field] = {
				...accumulatedChanges.current[field],
				...value,
			};
			debouncedUpdateColumns();
		},
		[debouncedUpdateColumns],
	);

	const onColumnResized = useCallback(
		(params: ColumnResizedEvent, auto?: boolean) => {
			const internalColumns = params.columns ? params.columns : [params.column];
			internalColumns.forEach((column) => {
				if (column) {
					if (auto) {
						onColumnEdited(column.getColDef().field as string, {
							width: column.getActualWidth(),
						});
					} else {
						onColumnEdited(column.getColDef().field as string, {
							width: column.getActualWidth(),
							suppressSizeToFit: true,
							autoWidth: false,
						});
					}
				}
			});
		},
		[onColumnEdited, columns],
	);

	const onColumnMoved = useCallback(
		(params: DragStoppedEvent) => {
			const { columnApi } = params;

			// Костыль: предотвращает перемещение колонок при их ресайзе
			if (params.target.classList.contains('ag-header-cell-resize')) {
				return;
			}

			const reordererColumns = columnApi.getAllDisplayedColumns().map((el) => el.getColDef().field);

			reordererColumns.forEach((field, index) => {
				if (field) {
					accumulatedChanges.current[field] = {
						order: index,
					};
				}
			});

			debouncedUpdateColumns();
		},
		[debouncedUpdateColumns],
	);

	return {
		onColumnResized,
		onColumnMoved,
		columns: columns.map((el) => ({ ...el, headerName: el.headerName || '' })),
	};
}
