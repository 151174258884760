import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

interface IProps extends ICellRendererParams {
	value: {
		total: number;
		completed: number;
	};
	total?: number;
	completed?: number;
}

export class ProgressRenderer implements ICellRenderer {
	element!: HTMLElement;
	fill!: HTMLElement;
	bar!: HTMLElement;
	number!: HTMLElement;

	public init(params: IProps) {
		this.number = document.createElement('div');

		this.fill = document.createElement('div');
		this.fill.setAttribute('class', 'ag-grid-progress-cell__fill');

		this.bar = document.createElement('div');
		this.bar.appendChild(this.fill);

		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-progress-cell');
		this.element.appendChild(this.bar);
		this.element.appendChild(this.number);

		this.render(params);
	}

	public render(params: IProps) {
		if (params.value) {
			const { total, completed } = params.value;
			this.fill.setAttribute('style', `width: ${total && completed ? (completed / total) * 100 : 0}%`);
			this.bar.setAttribute('class', 'ag-grid-progress-cell__bar');
			this.number.setAttribute('class', 'ag-grid-progress-cell__number');
			this.number.innerHTML = `${completed}/${total}`;
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
