import { Action } from 'redux';
import { History } from 'history';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import i18n from 'i18next';
import { IAppState } from '../../store/mainReducer';

export type IThunkAction<T = any> = ThunkAction<
	Promise<T>,
	IAppState,
	// tslint:disable-next-line:no-any
	{ history: History<any>; i18n: typeof i18n },
	Action
>;

export type IThunkDispatch = ThunkDispatch<IAppState, { history: History<any>; i18n: typeof i18n }, Action>;

export type ILoadingStatus = 'init' | 'loading' | 'loaded';

export type ILoadingType = 'full' | 'append';

export interface ILoadingData {
	status: ILoadingStatus;
	type?: ILoadingType;
}

export const initialLoadingData = (): ILoadingData => ({
	status: 'init',
	type: 'full',
});
