export const colors = {
	blue: '#008ac6',
	red: '#ff0000',
	white: '#fff',
	transparent: 'transparent',
	disabled: '#AAA',
};

export const fonts = {
	lato: 'lato, -apple-system, Roboto, BlinkMacSystemFont, "Segoe UI"',
};

export const questionLevels = [1, 2, 3];

export const contentTypes = [
	{ label: 'Текст', code: 0 },
	{ label: 'Изображение', code: 1 },
];

export const ALL_ELEMENTS = 9999;

export const MAXIMUM_EDITABLE_ITEMS_COUNT = 15;
