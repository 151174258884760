import { actionCreatorFactory } from 'typescript-fsa';
import { Dict } from '../../../../core/types';
import { FilterDefinition } from '../../../../service/typings/filters';
import { RememberScrollPosition } from '../../../../service/typings/actions/grid';
import { GetFiltersRequest, UpdateFiltersRequest } from '../../../../service/typings/actions/filters';
import { AccessSelection } from './reducer';
import {
	AccessListResponse,
	AccessProjectTypeChangeRequest,
	AccessProjectTypeResponse,
	AccessRowChange,
	CheckAccessListResponse,
	GetAccessListRequest,
	SetAccessListRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('ACCESS_LIST');

export const AccessListActions = {
	rowChange: ActionFactory<AccessRowChange>('ROW_CHANGE'),
	setEditing: ActionFactory<boolean>('SET_EDITING'),
	resetChanges: ActionFactory('RESET_CHANGES'),
	applyChanges: ActionFactory('APPLY_CHANGES'),
	getProjectTypeAccess: ActionFactory.async<{ userName: string }, AccessProjectTypeResponse, Error>(
		'GET_ACCESS_PROJECT_TYPES',
	),
	setProjectTypeAccess: ActionFactory.async<AccessProjectTypeChangeRequest, AccessProjectTypeResponse, Error>(
		'SET_ACCESS_PROJECT_TYPES',
	),
	setAccess: ActionFactory.async<SetAccessListRequest, AccessListResponse, Error>('SET_ACCESS'),
	checkAccess: ActionFactory.async<GetAccessListRequest, CheckAccessListResponse, Error>('CHECK_ACCESS'),
	getList: ActionFactory.async<GetAccessListRequest, AccessListResponse, Error>('GET_LIST'),
	getListForAccessCreate: ActionFactory.async<GetAccessListRequest, AccessListResponse, Error>(
		'GET_LIST_FOR_ACCESS_CREATE',
	),
	loadMore: ActionFactory.async<GetAccessListRequest, AccessListResponse, Error>('LOAD_MORE'),
	rememberParams: ActionFactory<Dict>('REMEMBER_PARAMS'),
	setChanged: ActionFactory('SET_CHANGED'),
	// Selection
	updateSelection: ActionFactory<AccessSelection>('UPDATE_SELECTION'),
	getStatistics: ActionFactory.async<GetAccessListRequest, AccessListResponse, Error>('GET_STATISTICS'),
	// Filters
	getFilters: ActionFactory.async<GetFiltersRequest, FilterDefinition[], Error>('GET_FILTERS'),
	updateFilters: ActionFactory.async<UpdateFiltersRequest, FilterDefinition[], Error>('UPDATE_FILTERS'),
	// Scroll
	rememberScroll: ActionFactory<RememberScrollPosition>('REMEMBER_SCROLL'),
};
