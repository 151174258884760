import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useChangeDetection = (callback: EffectCallback, dependencies: DependencyList) => {
	const initialRun = useRef(true);
	useEffect(() => {
		if (initialRun.current) {
			initialRun.current = false;
		} else {
			callback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
};
