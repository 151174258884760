import { useEffect, useRef } from 'react';

export function useDynamicFunction(staticFunction?: Function) {
	const functionReference = useRef(staticFunction);

	useEffect(() => {
		functionReference.current = staticFunction;
	}, [staticFunction]);

	return (...args: any) => {
		if (functionReference.current) {
			return functionReference.current(...args);
		} else {
			throw new Error('Linked function is not defined');
		}
	};
}
