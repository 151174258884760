import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { includes, mapValues } from 'lodash-es';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { Dict } from '../../../../core/types';
import { join } from '../../../../core/services/lists';
import { CompetencesActions } from '../../entities/competences/actions';
import { CompetenceGroupsActions } from '../../entities/competenceGroups/actions';
import { CompetenceTypesActions } from '../../entities/competenceTypes/actions';
import { ReferencesActions } from '../../entities/references/actions';
import { CompetenceDetailsActions } from '../../details/competence/actions';
import {
	removeRows,
	removeRowsSelection,
	reorderColumns,
	updateSelectionStatistics,
} from '../../../../core/services/grid';
import { CompanyShort, CompetenceShort, FunctionShort } from '../../../../service/typings/entites';
import { GridColumn, GridCommonSelection, GridStatistics } from '../../../../service/typings/grid';
import { Row } from '../../../../service/typings/lists';
import { FilterDefinition } from '../../../../service/typings/filters';
import { CompetencesListActions } from './actions';
import { GetCompetencesListResponse } from './typing';

export interface CompetenceListData {
	companies: Dict<CompanyShort>;
	functions: Dict<FunctionShort>;
	competences: Dict<CompetenceShort>;
	rows: Row[];
	changed: boolean;
}

export interface CompetenceListSelection {
	rows: Dict<boolean>;
	companies: Dict<GridStatistics>;
	functions: Dict<GridStatistics>;
}

export interface CompetencesListState {
	data: PaginatedParametrizedContentLoading<CompetenceListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: GridCommonSelection;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	companies: {},
	functions: {},
	competences: {},
	rows: [],
	changed: false,
});

const initialState: CompetencesListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	lastParams: {},
	selection: {
		rows: {},
		companies: {},
	},
	scroll: 0,
};

export const CompetencesListReducer = reducerWithInitialState(initialState)
	.case(CompetencesListActions.resetParams, (state) => ({
		...state,
		data: {
			...state.data,
			params: {},
		},
		lastParams: {},
	}))
	.case(CompetencesListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(CompetencesListActions.getList.done, (state, { result, params }) => ({
		...state,
		data: {
			content: join(initialData(), normalize(result)) as CompetenceListData,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			pagination: result.page,
			params,
		},
		scroll: 0,
	}))
	.case(CompetencesListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(CompetencesListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(CompetencesListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(CompetencesListActions.loadMore.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: join(state.data.content, normalize(result)) as CompetenceListData,
			loading: {
				type: 'append',
				status: 'loaded',
			},
			pagination: result.page,
		},
	}))
	.case(CompetencesListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(CompetencesListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(CompetencesListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(CompetencesListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(CompetencesListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(CompetencesListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(CompetencesListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(CompetencesListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	.case(CompetencesActions.update.done, (state, { result: { id, value } }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				competences: mapValues(state.data.content.competences, (competence) =>
					competence.entityId === id
						? {
								...competence,
								...value,
						  }
						: competence,
				),
			},
		},
	}))
	.case(CompetenceGroupsActions.updateGroup.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				competences: mapValues(state.data.content.competences, (value) => ({
					...value,
					group:
						value.group && value.group.id === result.id
							? {
									...value.group,
									...result,
							  }
							: value.group,
				})),
			},
		},
	}))
	.case(CompetenceTypesActions.updateType.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				competences: mapValues(state.data.content.competences, (value) => ({
					...value,
					type:
						value.type && value.type.id === result.id
							? {
									...value.type,
									...result,
							  }
							: value.type,
				})),
			},
		},
	}))
	.case(CompetenceGroupsActions.removeGroup.done, (state, { result: { id } }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				competences: mapValues(state.data.content.competences, (value) => ({
					...value,
					group: value.group && value.group.id === id ? null : value.group,
				})),
			},
		},
	}))
	.case(CompetenceTypesActions.removeType.done, (state, { result: { id } }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				competences: mapValues(state.data.content.competences, (value) => ({
					...value,
					type: value.type && value.type.id === id ? null : value.type,
				})),
			},
		},
	}))
	.case(ReferencesActions.removeReference.done, (state, { result: { id, addColumnId } }) => {
		const column = (state.columns.content || []).find((el) => el.addColumnId === addColumnId);
		if (column && column.field) {
			const field = column.field as string;
			return {
				...state,
				data: {
					...state.data,
					content: {
						...state.data.content,
						competences: mapValues(state.data.content.competences, (value) => ({
							...value,
							[field]: value[field] && value[field].id === id ? null : value[field],
						})),
					},
				},
			};
		} else {
			return state;
		}
	})
	.case(CompetencesListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(CompetencesListActions.getStatistics.done, (state, { result }) => ({
		...state,
		selection: updateSelectionStatistics(state.selection, result),
	}))
	.case(CompetencesListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(CompetencesListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(CompetencesListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
		},
	}))
	.case(CompetenceDetailsActions.update.done, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(CompetencesListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(CompetencesListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.cases([CompetencesActions.batchRemove.done, CompetencesActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: removeRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: removeRowsSelection(state.selection, params),
	}));

function normalize(data: GetCompetencesListResponse) {
	const result: Omit<CompetenceListData, 'changed'> = {
		companies: {},
		functions: {},
		competences: {},
		rows: [],
	};

	const { companies } = data._embedded;

	(companies || []).forEach((company) => {
		const { functions, ...companyData } = company;

		if (companyData.id) {
			result.companies[companyData.id] = companyData;
			result.rows.push({
				type: 'companies',
				id: companyData.id,
			});

			(functions || []).forEach(({ competences, ...functionData }) => {
				result.functions[functionData.id] = {
					companyId: companyData.id,
					...functionData,
				};
				result.rows.push({
					type: 'functions',
					id: functionData.id,
					companyId: companyData.id,
				});

				competences.forEach((competence: Dict) => {
					result.competences[competence.correctCompetenceId] = {
						...competence,
						entityId: competence.correctCompetenceId,
					};
					result.rows.push({
						type: 'competences',
						id: competence.correctCompetenceId,
						functionId: functionData.id,
						companyId: companyData.id,
					});
				});
			});
		}
	});

	return result;
}
