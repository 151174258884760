import { DependencyList, RefObject, useCallback, useEffect, useState } from 'react';
import animateScrollTo from 'animated-scroll-to';
import { defaultEasing } from '../utils/easings';
import { useWindowSize } from './useWindowSize';

export function useSliderLogic(ref: RefObject<HTMLElement>, dependencies: DependencyList = []) {
	const [showButtons, setShowButtons] = useState(false);
	const [leftButtonDisabled, setLeftButtonDisabled] = useState(true);
	const [rightButtonDisabled, setRightButtonDisabled] = useState(false);

	const autoScroll = useCallback((direction: string) => {
		const element = ref.current;
		if (element) {
			animateScrollTo(
				[
					element.scrollLeft +
						(direction === 'left'
							? -Math.ceil(element.clientWidth / 2)
							: Math.ceil(element.clientWidth / 2)),
					0,
				],
				{ elementToScroll: element, easing: defaultEasing },
			);
		}
	}, []);

	const { width } = useWindowSize();

	const onScrollToLeft = useCallback(() => {
		autoScroll('left');
	}, [autoScroll]);

	const onScrollToRight = useCallback(() => {
		autoScroll('right');
	}, [autoScroll]);

	const onScroll = useCallback(() => {
		const element = ref.current;
		if (element) {
			setLeftButtonDisabled(Math.floor(element.scrollLeft) === 0);
			setRightButtonDisabled(Math.ceil(element.scrollLeft + element.offsetWidth) >= element.scrollWidth);
		}
	}, []);

	useEffect(() => {
		const element = ref.current;
		if (element) {
			setShowButtons(element.scrollWidth > element.clientWidth);
			if (element) {
				setLeftButtonDisabled(Math.floor(element.scrollLeft) === 0);
				setRightButtonDisabled(Math.ceil(element.scrollLeft + element.offsetWidth) >= element.scrollWidth);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width, ...dependencies]);

	return {
		onScroll,
		onScrollToRight,
		onScrollToLeft,
		showButtons,
		leftButtonDisabled,
		rightButtonDisabled,
	};
}
