import { isString } from 'lodash-es';
import { HttpMethods } from './typings/api';
import { stringify } from './query-string';

export interface IResponse<R> {
	result: R | null;
	status: number;
	error: Error | null;
	message: string | null;
}

export const baseFetch = async <P, R>(
	url: string,
	params: P,
	method: HttpMethods = 'GET',
	headers: { [key: string]: string } = {},
	noResponse = false,
): Promise<IResponse<R>> => {
	const keys = Object.keys(params);

	keys.forEach((key: any) => {
		(params as any)[key] = isString((params as any)[key])
			? (params as any)[key].replace(/%0A/g, '\n')
			: (params as any)[key];
	});

	const body = method !== 'GET' ? { body: JSON.stringify(params) } : {};

	const hasParams = Object.keys(params).length > 0;
	const urlResult =
		method !== 'GET' ? `${url}` : `${url}${hasParams ? '?' : ''}${stringify(params as any)}`;

	try {
		const res = await fetch(urlResult, {
			method,
			...body,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...headers,
			},
		});

		let json = {};

		try {
			json = !noResponse ? await res.json() : {};
		} catch (err) {
			if (noResponse) {
				return {
					result: null,
					status: res.status,
					error: null,
					message: null,
				};
			} else {
				return {
					result: null,
					status: res.status,
					error: new Error(res.statusText),
					message: res.statusText,
				};
			}
		}
		const status = res.status;

		return { result: json as R, status, error: null, message: null };
	} catch (error) {
		return {
			result: null,
			status: 502,
			error: error as Error,
			message: error.message,
		};
	}
};
