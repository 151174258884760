import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, User, initialContentLoading } from '@ea/common';
import { AuthActions } from './actions';

const initialValue = () => {
	const state = initialContentLoading({
		specialists: undefined,
	});
	try {
		const data = localStorage.getItem('profiles');
		return data ? JSON.parse(data) : state;
	} catch (e) {
		return state;
	}
};

export interface AuthState {
	profiles: ContentLoading<{
		specialists?: User;
	}>;
}

const initialState: AuthState = {
	profiles: initialValue(),
};

export const AuthReducer = reducerWithInitialState(initialState)
	.case(AuthActions.login.started, (state) => ({
		...state,
		profiles: {
			...state.profiles,
			loading: {
				status: 'loading' as const,
			},
		},
	}))
	.case(AuthActions.login.done, (state, { result }) => {
		const newState = {
			...state,
			profiles: {
				...state.profiles,
				content: {
					specialists: result,
				},
				loading: {
					status: 'loaded' as const,
				},
			},
		};
		localStorage.setItem(
			'profiles',
			JSON.stringify({ content: newState.profiles.content, loading: newState.profiles.loading }),
		);
		return newState;
	})
	.case(AuthActions.logout.done, () => {
		localStorage.removeItem('profiles');
		return {
			profiles: initialValue(),
		};
	});
