import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { includes, isArray } from 'lodash-es';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';

import { Dict } from '../../../../core/types';
import { IRow } from '../../../../core/services/lists';
import { removeRows, removeRowsSelection, reorderColumns } from '../../../../core/services/grid';
import { AdminsActions } from '../../entities/admins/actions';
import { GridColumn, GridCommonSelection } from '../../../../service/typings/grid';
import { FilterDefinition } from '../../../../service/typings/filters';
import { AdministrationListActions } from './actions';
import { GetAdministrationListResponse } from './typings';

export interface AdministrationListData {
	administration: Dict<GetAdministrationListResponse['_embedded']>;
	rows: IRow[];
}

export interface AdministrationListState {
	data: PaginatedParametrizedContentLoading<AdministrationListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: Omit<GridCommonSelection, 'companies' | 'functions'>;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	administration: {},
	rows: [],
});

const initialState: AdministrationListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	selection: {
		rows: {},
	},
	lastParams: {},
	scroll: 0,
};

export const AdministrationListReducer = reducerWithInitialState(initialState)
	.case(AdministrationListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(AdministrationListActions.getList.done, (state, { result, params }) => {
		const normalizeResult = normalize(result);
		const initial = initialData();
		return {
			...state,
			data: {
				content: {
					administration: { ...initial.administration, ...normalizeResult.administration },
					rows: [...initial.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'full',
					status: 'loaded',
				},
				pagination: result.page,
				params,
			},
			scroll: 0,
		};
	})
	.case(AdministrationListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(AdministrationListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(AdministrationListActions.loadMore.done, (state, { result }) => {
		const normalizeResult = normalize(result);
		return {
			...state,
			data: {
				...state.data,
				content: {
					administration: { ...state.data.content.administration, ...normalizeResult.administration },
					rows: [...state.data.content.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'append',
					status: 'loaded',
				},
				pagination: result.page,
			},
		};
	})
	.case(AdministrationListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(AdministrationListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AdministrationListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(AdministrationListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(AdministrationListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(AdministrationListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(AdministrationListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(AdministrationListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	.case(AdministrationListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(AdministrationListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AdministrationListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(AdministrationListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(AdministrationListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(AdministrationListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(AdministrationListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.case(AdminsActions.update.done, (state, { params, result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: {
					...state.data.content,
					administration: {
						...state.data.content.administration,
						[params.username]: {
							...state.data.content.administration[params.username],
							created: result.created,
							fName: result.fname,
							isBlocked: result.isBlocked,
							isDeleted: result.isDeleted,
							lastLogin: result.lastLogin,
							lName: result.lname,
							mName: result.mname,
							passwordChanged: result.passwordChanged,
							role: result.role,
							updated: result.updated,
						},
					},
				},
			},
			selection: {
				companies: {},
				functions: {},
				rows: {},
			},
		};
	})
	.case(AdminsActions.batchBlocked.done, (state, { params, result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: {
					...state.data.content,
					administration: {
						...state.data.content.administration,
						[params.username]: {
							...state.data.content.administration[params.username],
							...result,
							fName: result.fname,
							lName: result.lname,
							mName: result.mname,
							status: 'blocked',
							email: params.username,
						},
					},
				},
			},
			selection: {
				companies: {},
				functions: {},
				rows: {},
			},
		};
	})
	.case(AdminsActions.batchUnBlocked.done, (state, { params, result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: {
					...state.data.content,
					administration: {
						...state.data.content.administration,
						[params.username]: {
							...state.data.content.administration[params.username],
							...result,
							fName: result.fname,
							lName: result.lname,
							mName: result.mname,
							status: 'active',
							email: params.username,
						},
					},
				},
			},
			selection: {
				companies: {},
				functions: {},
				rows: {},
			},
		};
	})
	.cases([AdminsActions.batchRemove.done, AdminsActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: removeRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: removeRowsSelection(state.selection, params.username as any),
	}));

function normalize(data: GetAdministrationListResponse) {
	const result: AdministrationListData = {
		administration: {},
		rows: [],
	};

	const { administration } = data._embedded;

	(administration || []).forEach((item: any) => {
		result.administration[item.email] = { ...item, id: item.email };
		result.rows.push({
			id: item.email,
		});
	});

	return result;
}
