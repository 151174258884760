import { actionCreatorFactory } from 'typescript-fsa';
import { RemoveAdminsRequest, RestoreAdminsRequest, UpdateAdminsRequest, UpdateAdminsResponse } from './typings';
const ActionFactory = actionCreatorFactory('ADMINS');

export const AdminsActions = {
	update: ActionFactory.async<UpdateAdminsRequest, UpdateAdminsResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemoveAdminsRequest, UpdateAdminsResponse, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreAdminsRequest, UpdateAdminsResponse, Error>('BATCH_RESTORE'),
	batchBlocked: ActionFactory.async<RestoreAdminsRequest, UpdateAdminsResponse, Error>('BATCH_BLOCKED'),
	batchUnBlocked: ActionFactory.async<RestoreAdminsRequest, UpdateAdminsResponse, Error>('BATCH_UNBLOCKED'),
	changePassword: ActionFactory.async<RestoreAdminsRequest, UpdateAdminsResponse, Error>('CHANGE_PASSWORD'),
};
