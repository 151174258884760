import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetCompetencesRequest,
	GetCompetencesResponse,
	GetCompetenceUsingRequest,
	GetCompetenceUsingResponse,
	RemoveCompetencesRequest,
	RestoreCompetencesRequest,
	UpdateCompetenceRequest,
	UpdateCompetenceResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPETENCES');

export const CompetencesActions = {
	get: ActionFactory.async<GetCompetencesRequest, GetCompetencesResponse, Error>('GET'),
	update: ActionFactory.async<UpdateCompetenceRequest, UpdateCompetenceResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemoveCompetencesRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreCompetencesRequest, {}, Error>('BATCH_RESTORE'),
	getUsing: ActionFactory.async<GetCompetenceUsingRequest, GetCompetenceUsingResponse, Error>('GET_USING'),
};
