import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

interface Props extends ICellRendererParams {
	value: string;
	color?: string;
}

export class CompanyRenderer implements ICellRenderer {
	element!: HTMLElement;
	colorElement!: HTMLElement;

	public init(params: Props) {
		this.element = document.createElement('div');
		this.colorElement = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-with-padding-color-cell');
		this.colorElement.setAttribute('class', 'ag-grid-color-line-cell');

		this.render(params);
	}

	public render(params: Props) {
		const { value, color } = params;
		this.element.innerHTML = value || '';
		this.colorElement.innerHTML = '';
		this.colorElement.setAttribute('style', `background-color: ${color || 'none'};`);
		this.element.appendChild(this.colorElement);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.render(params);
		return true;
	}
}
