import { combineReducers } from 'redux';
import { ListsReducer } from './lists/reducer';
import { DetailsReducer } from './details/reducer';
import { AccessReducer } from './access/reducer';
import { EntitiesReducer } from './entities/reducer';

export const SpecialistsReducer = combineReducers({
	lists: ListsReducer,
	details: DetailsReducer,
	access: AccessReducer,
	entities: EntitiesReducer,
});
