import { useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { GridColumn } from '../typings/grid';

export const useOnColumnsChange = (callback: Function, columns: GridColumn[]) => {
	const initialRun = useRef(true);
	const previousValues = useRef([...columns]);
	useEffect(() => {
		if (initialRun.current) {
			initialRun.current = false;
		} else {
			if (!isEqual(previousValues.current, columns)) {
				callback(previousValues.current);
				previousValues.current = [...columns];
			}
		}
	}, [columns]);
};
