import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { mainReducer } from './mainReducer';

const composeEnhancers = composeWithDevTools({
	shouldHotReload: true,
});

export function configureStore(history: History<any>) {
	const middleware = [thunk.withExtraArgument({ history })];
	const enhancer = composeEnhancers(applyMiddleware(...middleware));
	const store = createStore(mainReducer, enhancer);

	return { store };
}
