import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import linkIcon from '../../../../common/src/images/icons/actions/link.svg';

interface IProps extends ICellRendererParams {
	class?: string;
	value: string;
	link?: (data?: any) => string;
	onClick?: (data?: any) => void;
	target?: string;
}

export class IconLinkRenderer implements ICellRenderer {
	element!: HTMLElement;
	link!: HTMLElement;
	icon!: HTMLElement;

	public init(params: IProps) {
		this.icon = document.createElement('img');
		this.icon.setAttribute('src', linkIcon);

		this.link = document.createElement('a');
		this.link.appendChild(this.icon);

		if (params.target) {
			this.link.setAttribute('target', params.target);
		}
		if (params.link) {
			this.link.setAttribute('href', params.link(params.node.data));
		}

		this.link.addEventListener('click', (event: MouseEvent) => {
			this.onClick(event, params);
		});

		this.element = document.createElement('div');
		this.element.setAttribute('class', `ag-grid-icon-link-cell`);
		this.element.appendChild(this.link);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		return true;
	}

	private onClick(event: MouseEvent, params: IProps) {
		if (params.onClick) {
			params.onClick(params.node.data);
			event.preventDefault();
		}
	}
}
