import { actionCreatorFactory } from 'typescript-fsa';
import { Person } from '../../../../service/typings/entites';
import { GetPersonRequest, GetPersonResponse, PatchPersonRequest, PatchPersonResponse } from './typings';

const ActionFactory = actionCreatorFactory('PERSON_DETAIL');

export const PersonDetailsActions = {
	getPerson: ActionFactory.async<GetPersonRequest, GetPersonResponse, Error>('GET_PERSON'),
	patchPersonBaseProfile: ActionFactory.async<PatchPersonRequest, PatchPersonResponse, Error>(
		'PATCH_PERSON_BASE_PROFILE',
	),
	patchPerson: ActionFactory.async<Partial<Person>, PatchPersonResponse, Error>('PATCH_PERSON'),
};
