import styled from 'astroturf';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Ripple, ListItem, TextEllipsisWithTooltip } from '@ea/common';

interface Props {
	items: ListItem[];
	additionalItems?: ListItem[];
	onSelect?: (value: ListItem) => void;
	className?: string;
}

interface ItemProps extends ListItem {
	onSelect?: (value: ListItem) => void;
	className?: string;
}

const Item: FC<ItemProps> = ({ icon, value, label, onSelect, className }) => {
	const onClick = useCallback(() => {
		if (onSelect) {
			onSelect({
				value,
				label,
			});
		}
	}, [value, label, onSelect]);
	return (
		<Ripple onClick={onClick}>
			<ItemWrapper className={className} withIcon={!!icon}>
				{Boolean(icon) && <IconWrapper> {icon} </IconWrapper>} {label}
			</ItemWrapper>
		</Ripple>
	);
};

export const GridPopupSelect: FC<Props> = ({ items, additionalItems, onSelect, className }) => {
	const { t } = useTranslation();

	return (
		<Wrapper className={className}>
			{items.map((item) => (
				<Item key={item.label} onSelect={onSelect} icon={item.icon} value={item.value} label={item.label} />
			))}
			{additionalItems && additionalItems.length > 0 && (
				<AdditionalItemsWrapper>
					{additionalItems.map(({ key, value, label }) => (
						<StyledItem
							key={key || label}
							onSelect={onSelect}
							value={value}
							label={label || t('grid.unnamedColumn')}
							unnamed={!label}
						/>
					))}
				</AdditionalItemsWrapper>
			)}
		</Wrapper>
	);
};

const StyledItem = styled(Item)<{ unnamed?: boolean }>`
	@import '../../../../common/src/styles/mixins.scss';
	@include long-content();
	&.unnamed {
		opacity: 0.5;
	}
`;

const AdditionalItemsWrapper = styled.div`
	@import '../../../../common/src/styles/constants.scss';
	padding-top: 8px;
	margin-top: 11px;
	&:before {
		top: -8px;
		position: relative;
		content: '';
		margin-left: 24px;
		margin-right: 24px;
		height: 1px;
		background: $color-warm-gray;
		display: block;
		width: calc(100% - 48px);
	}
`;
const Wrapper = styled.div``;

const ItemWrapper = styled(TextEllipsisWithTooltip)<{ withIcon?: boolean }>`
	letter-spacing: normal;
	width: 100%;
	padding-left: 22px;
	padding-right: 22px;
	font-size: 12px;
	font-weight: bold;
	white-space: nowrap;
	line-height: 32px;
	text-align: left;
	&.withIcon {
		display: flex;
	}
`;
const IconWrapper = styled.div`
	display: flex;
	width: 20px;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;
