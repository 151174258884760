import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header as HeaderComponent } from '@ea/common';
import { useTranslation } from '../../../i18n/hooks';
import { AuthAsync } from '../../../store/general/auth/actionsSync';
import { routes } from '../../../core/routes/paths';
import { useAuth } from '../../../hooks/general/useAuth';

export const Header = () => {
	const { t } = useTranslation();

	const { profile, authorized } = useAuth();

	const dispatch = useDispatch();

	const onLogout = useCallback(() => {
		dispatch(AuthAsync.logout());
	}, [dispatch]);

	return (
		<HeaderComponent
			title={t('general.siteName')}
			to={routes.root.path}
			profile={profile}
			authorized={authorized}
			onLogout={onLogout}
		/>
	);
};
