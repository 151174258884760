import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'astroturf';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSliderLogic } from '../../service/hooks/useSliderLogic';
import { scrollToElement } from '../../service/utils/html';
import { LeftSliderButton, RightSliderButton } from '../elements/buttons/SliderButton';

export interface ColoredTabsItem {
	label: string;
	value: string | number;
	color?: string;
}

interface Props {
	tabs: ColoredTabsItem[];
	onChange: (value: string | number) => void;
	className?: string;
	active?: string | number;
	dark?: boolean;
}

export const ColoredTabs: FC<Props> = memo(({ tabs, dark, active, onChange, className }) => {
	const activeElement = useRef<HTMLDivElement>();

	const container = useRef<HTMLElement>(null);

	const { showButtons, leftButtonDisabled, rightButtonDisabled, onScroll, onScrollToLeft, onScrollToRight } =
		useSliderLogic(container, [tabs]);

	const [debouncedScrollToElement] = useDebouncedCallback(scrollToElement, 500);

	useEffect(() => {
		if (container.current && activeElement.current) {
			debouncedScrollToElement(container.current, activeElement.current);
		}
	}, [active]);

	const onChangeActive = useCallback(
		(value: string | number) => () => {
			onChange && onChange(value);
		},
		[onChange],
	);

	return (
		<Wrapper className={className}>
			<Container onScroll={onScroll} onEndScroll={onScroll} innerRef={container}>
				<Tabs>
					{tabs.map((tab) => (
						<Tab
							key={tab.value}
							dark={dark}
							active={tab.value === active}
							onClick={onChangeActive(tab.value)}
							ref={(element) => {
								if (tab.value === active && element) {
									activeElement.current = element;
								}
							}}
						>
							{tab.color && <ColorLone style={{ background: tab.color }} />}
							{tab.label}
						</Tab>
					))}
				</Tabs>
			</Container>
			<Buttons>
				{showButtons && (
					<LeftStyledSliderButton black={true} disabled={leftButtonDisabled} onClick={onScrollToLeft} />
				)}
				{showButtons && (
					<RightStyledSliderButton black={true} disabled={rightButtonDisabled} onClick={onScrollToRight} />
				)}
				{showButtons && <Shadow gray={!dark} hidden={rightButtonDisabled} />}
			</Buttons>
		</Wrapper>
	);
});

const ColorLone = styled.div`
	height: 28px;
	width: 6px;
	margin-right: 12px;
`;

const Tabs = styled.div`
	display: flex;
	height: 50px;
	position: relative;
`;

const Tab = styled.div<{ active?: boolean; dark?: boolean }>`
	@import '../../styles/constants.scss';
	@import '../../styles/mixins.scss';
	@include transition();
	color: #757575;
	height: 50px;
	font-size: 12px;
	font-weight: bold;
	padding: 7px 16px;
	margin-right: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	white-space: nowrap;
	&:first-child {
		padding-left: 24px;
	}
	&.active {
		border-radius: 4px 4px 0 0;
		background: #ffffff;
		color: $color-black;
	}
	&.dark.active {
		background: #f5f5f5;
	}
`;

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

const Container = styled(ScrollContainer)`
	width: 100%;
`;

const Buttons = styled.div`
	display: flex;
	position: relative;
	margin-left: 15px;
	align-items: center;
`;

const Shadow = styled.div<{ hidden?: boolean; gray?: boolean }>`
	position: absolute;
	pointer-events: none;
	width: 59px;
	height: 50px;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 1) 67%);
	left: -59px;
	transition: opacity 0.25s;
	&.hidden {
		opacity: 0;
	}
	&.gray {
		background-image: linear-gradient(90deg, rgba(245, 245, 245, 0) 6%, rgba(245, 245, 245, 1) 67%);
	}
`;

const LeftStyledSliderButton = styled(LeftSliderButton)`
	border-right: none;
`;

const RightStyledSliderButton = styled(RightSliderButton)``;
