import React, { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'astroturf';
import useOnClickOutside from 'use-onclickoutside';
import { useDisposableCallback } from '@ea/common';
import { TABLE_ANIMATED_SCROLL_DURATION } from '../../GeneralTable';

interface Props {
	onRename: (value: string) => void;
	value: string;
}

export const HeaderInput: FC<Props> = ({ value, onRename }) => {
	const input = useRef<HTMLInputElement | null>(null);
	const [internalValue, setInternalValue] = useState(value);

	const rename = useDisposableCallback(
		(value: string) => {
			if (onRename) {
				onRename(value.trim());
			}
		},
		[onRename],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setInternalValue(event.target.value);
		},
		[setInternalValue],
	);

	const onKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				rename(internalValue);
			}
		},
		[rename, internalValue],
	);

	useEffect(() => {
		setTimeout(() => {
			if (input.current) {
				input.current.focus();
			}
		}, TABLE_ANIMATED_SCROLL_DURATION);
	}, []);

	useOnClickOutside(input, () => {
		rename(internalValue);
	});

	return <StyledInput ref={input} value={internalValue} onChange={onChange} onKeyPress={onKeyPress} />;
};

const StyledInput = styled.input`
	display: block;
	width: 100%;
	text-overflow: ellipsis;
	outline: none;
	height: 25px;
	border: solid 1px #d9d9d9;
	background-color: #ffffff;
`;
