import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

interface Props extends ICellRendererParams {
	value: string;
	color?: string;
}

export class DecorateLinkedString implements ICellRenderer {
	element!: HTMLElement;

	decorateMessage = (message: string) => {
		const messageBlock = message.replace(/\r|\n/g, ' ').split(' ');
		// eslint-disable-next-line no-useless-escape
		const regExp = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

		return messageBlock.map((m) => {
			return regExp.test(m) ? `<a href="${m}" style='color: #008AC6' target="_blank">${m}&nbsp;</a>` : m;
		});
	};

	public init(params: Props) {
		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-with-padding-cell');
		this.render(params);
	}

	public render(params: Props) {
		const { value, color } = params;
		this.element.innerHTML = this.decorateMessage(value).join(' ') || '';
		this.element.setAttribute('style', `background-color: ${color || 'none'};overflow: hidden;`);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.render(params);
		return true;
	}
}
