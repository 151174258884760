import animateScrollTo from 'animated-scroll-to';
import { defaultEasing } from './easings';
import sanitizeHtml from 'sanitize-html';

export function beautifyNumber(n: number | string) {
	const str = (n || '').toString();
	const parts = [];
	for (let i = str.length; i > 0; i -= 3) {
		if (i - 3 <= 0) {
			parts.unshift(str.slice(0, i));
		} else {
			parts.unshift(str.slice(i - 3, i));
		}
	}
	return parts.join(' ');
}

export function scrollToElement(container: HTMLElement, element: HTMLElement) {
	if (element.offsetLeft + element.clientWidth > container.scrollLeft + container.clientWidth) {
		animateScrollTo([element.offsetLeft + element.clientWidth - container.clientWidth, 0], {
			elementToScroll: container,
			easing: defaultEasing,
		});
	} else if (element.offsetLeft < container.scrollLeft) {
		animateScrollTo([element.offsetLeft, 0], {
			elementToScroll: container,
			easing: defaultEasing,
		});
	}
}


export function addLineBreaks(text: string) {
	return text && text.replace ? text.replace(/\n/g, '<br/>') : text;
}

export function sanitize(html: string) {
	return sanitizeHtml(html);
}
