import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { GridColumn } from '../../service/typings/grid';

interface Props extends ICellRendererParams {
	value: string;
	onClick?: (data?: any) => void;
	colDef: GridColumn;
	disabled?: boolean;
}

export class TextActionRenderer implements ICellRenderer {
	element!: HTMLElement;

	public init(params: Props) {
		this.element = document.createElement('div');
		this.element.innerHTML = params.value;
		params.disabled
			? this.element.setAttribute('class', 'ag-grid-action-cell disabled')
			: this.element.setAttribute('class', 'ag-grid-action-cell');
		this.element.addEventListener('click', (event: MouseEvent) => {
			this.onClick(event, params);
		});
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: Props) {
		this.element.innerHTML = params.value;
		return true;
	}

	private onClick(event: MouseEvent, params: Props) {
		if (params.onClick && !params.disabled) {
			params.onClick(params.node.data);
			event.preventDefault();
		}
	}
}
