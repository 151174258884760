import { DependencyList, useCallback } from 'react';
import { isUndefined } from 'lodash-es';

export function usePromiseCallback(callback: Function, dependencies: DependencyList) {
	return useCallback((...args: any[]) => {
		return new Promise((resolve, reject) => {
			const result = callback(...args);
			if (!isUndefined(result)) {
				resolve(result);
			} else {
				reject();
			}
		});
	}, dependencies);
}
