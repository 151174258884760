import { Dict } from '../typings/basic';

declare global {
	interface Window {
		env: Dict;
	}
}

export function getEnv(variable: string, defaultValue = '') {
	return window.env?.[variable] || defaultValue;
}
