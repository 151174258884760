import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { isArray } from 'lodash-es';

import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { Dict } from '../../../../core/types';
import { reorderColumns } from '../../../../core/services/grid';
import { FilterDefinition } from '../../../../service/typings/filters';
import { Row } from '../../../../service/typings/lists';
import { GridColumn } from '../../../../service/typings/grid';
import { GetUserActivityListResponse } from './typings';
import { UserActivityListActions } from './actions';

export interface UserActivityListData {
	userActivity: Dict<GetUserActivityListResponse['_embedded']>;
	rows: Row[];
}

export interface UserActivityListState {
	data: PaginatedParametrizedContentLoading<UserActivityListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	companies: {},
	functions: {},
	userActivity: {},
	rows: [],
});

const initialState: UserActivityListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	lastParams: {},
	scroll: 0,
};

export const UserActivityListReducer = reducerWithInitialState(initialState)
	.case(UserActivityListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(UserActivityListActions.getList.done, (state, { result, params }) => {
		const normalizeResult = normalize(result);
		const initial = initialData();
		return {
			...state,
			data: {
				content: {
					userActivity: { ...initial.userActivity, ...normalizeResult.userActivity },
					rows: [...initial.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'full',
					status: 'loaded',
				},
				pagination: result.page,
				params,
			},
			scroll: 0,
		};
	})
	.case(UserActivityListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(UserActivityListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(UserActivityListActions.loadMore.done, (state, { result }) => {
		const normalizeResult = normalize(result);
		return {
			...state,
			data: {
				...state.data,
				content: {
					userActivity: { ...state.data.content.userActivity, ...normalizeResult.userActivity },
					rows: [...state.data.content.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'append',
					status: 'loaded',
				},
				pagination: result.page,
			},
		};
	})
	.case(UserActivityListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(UserActivityListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(UserActivityListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(UserActivityListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(UserActivityListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(UserActivityListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(UserActivityListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(UserActivityListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	.case(UserActivityListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(UserActivityListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(UserActivityListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(UserActivityListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(UserActivityListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(UserActivityListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}));

function normalize(data: GetUserActivityListResponse): UserActivityListData {
	const result: Omit<UserActivityListData, 'changed'> = {
		userActivity: {},
		rows: [],
	};

	const { userActivity } = data._embedded;

	(userActivity || []).forEach((item: any) => {
		result.userActivity[item.id + item.event] = {
			...item,
			id: item.id + item.event,
			userId: item.id,
			entityId: item.id,
		};
		result.rows.push({
			id: item.id + item.event,
			userId: item.id,
		});
	});

	return result;
}
