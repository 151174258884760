import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import online from '../../images/icons/online.svg';
import offline from '../../images/icons/offline.svg';
import deleted from '../../images/icons/deleted.svg';

interface IProps extends ICellRendererParams {
	value: string;
	icon: 'online' | 'offline' | 'deleted' | 'blocked' | 'active';
}

const icons = {
	online: online,
	active: online,
	offline: offline,
	deleted: deleted,
	blocked: offline,
};

export class StatusRenderer implements ICellRenderer {
	element!: HTMLElement;
	icon!: HTMLElement;
	status!: HTMLElement;

	public init(params: IProps) {
		this.element = document.createElement('div');
		this.icon = document.createElement('img');
		this.status = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-status-cell');
		this.icon.setAttribute('class', 'ag-grid-status-icon');

		this.element.appendChild(this.icon);
		this.element.appendChild(this.status);

		this.render(params);
	}

	public render(params: IProps) {
		this.status.innerHTML = params.value;

		const icon = icons[params.icon];

		this.status.setAttribute('class', `ag-grid-status-cell__status ag-grid-status-cell__status--${params.icon}`);
		if (params.value.toLowerCase() === 'online' || params.value.toLowerCase() === 'active') {
			this.icon.setAttribute('src', icon);
		} else if (params.value.toLowerCase() === 'deleted') {
			this.icon.setAttribute('src', icon);
		} else {
			this.icon.setAttribute('src', icon);
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
