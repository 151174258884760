import styled from 'astroturf';
import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../../images/icons/close-icon.svg';
import { ModalWrapper } from './ModalWrapper';

interface IProps {
	open?: boolean;
	onClose?: () => void;
	onClosed?: () => void;
	noOverflow?: boolean;
	className?: string;
	wrapperClassName?: string;
	disableCloseOnClickOutside?: boolean;
}

export const SmallModal: FC<IProps> = ({
	open,
	wrapperClassName,
	onClose,
	onClosed,
	children,
	className,
	disableCloseOnClickOutside,
}) => {
	return (
		<ModalWrapper
			className={wrapperClassName}
			onClose={onClose}
			onClosed={onClosed}
			open={open}
			closeOnClickOutside={!disableCloseOnClickOutside}
		>
			<Content className={className}>
				{children}
				<CloseIconContainer onClick={onClose}>
					<ResizedCloseIcon />
				</CloseIconContainer>
			</Content>
		</ModalWrapper>
	);
};

const Content = styled.div`
	background-color: #fff;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
	border: solid 1px #ededed;
`;

const ResizedCloseIcon = styled(CloseIcon)`
	width: 14px;
	height: 14px;
`;

const CloseIconContainer = styled.div`
	position: absolute;
	top: 11px;
	right: 11px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
