import React, { FC, useCallback } from 'react';
import ColorWrap, { CustomPickerInjectedProps } from 'react-color/lib/components/common/ColorWrap';
import styled from 'astroturf';
import { HSLColor, HSVColor, TwitterPicker, ColorState } from 'react-color';
import { Hue } from 'react-color/lib/components/common';
import { LimitedSaturation } from './LimitedSaturation';

const inputStyles = {
	default: {
		input: {
			width: 'auto',
			boxShadow: 'none',
		},
		body: {
			padding: '0',
			display: 'flex',
		},
	},
};

interface Props extends CustomPickerInjectedProps {
	hsl?: HSLColor;
	hsv?: HSVColor;
	colors: string[];
	from: number;
	to: number;
}

const Picker: FC<Props & { hasInput?: boolean }> = ({ onChange, hsl, hsv, colors, from, to, hasInput = true }) => {
	const onSelect = useCallback(
		(hex: string) => {
			onChange({
				hex,
				source: 'hex',
			} as any); // TODO: поправить типизацию
		},
		[onChange],
	);

	const onChangeComplete = useCallback(
		(hex: ColorState) => {
			onSelect(hex.hex);
		},
		[onSelect],
	);

	return (
		<>
			<SaturationWrapper hasInput={hasInput}>
				<LimitedSaturation hsl={hsl} hsv={hsv} onChange={onChange} from={from} to={to} />
			</SaturationWrapper>
			<HueWrapper>
				<Hue hsl={hsl} onChange={onChange} />
			</HueWrapper>
			<SwatchWrapper>
				{colors.map((color: string) => (
					<Swatch style={{ backgroundColor: color }} key={color} onClick={() => onSelect(color)} />
				))}
			</SwatchWrapper>
			{hasInput && (
				<InputWrapper>
					<TwitterPicker
						color={hsl ?? hsv}
						triangle={'hide'}
						width={'100%'}
						styles={inputStyles}
						colors={[]}
						onChangeComplete={onChangeComplete}
					/>
				</InputWrapper>
			)}
		</>
	);
};

export const WrappedPicker = ColorWrap<{ hasInput?: boolean }>((props) => (
	<Picker
		{...props}
		from={0}
		to={1}
		colors={[
			'#FF6900',
			'#FCB900',
			'#7BDCB5',
			'#00D084',
			'#8ED1FC',
			'#0693E3',
			'#ABB8C3',
			'#EB144C',
			'#F78DA7',
			'#9900EF',
		]}
	/>
));

export const DarkWrappedPicker = ColorWrap<{ hasInput?: boolean }>((props) => (
	<Picker
		{...props}
		from={0}
		to={0.65}
		colors={[
			'#A64500',
			'#A67B00',
			'#5DA688',
			'#00A669',
			'#5D89A6',
			'#056AA6',
			'#919CA6',
			'#A60E36',
			'#A65F70',
			'#6A00A6',
		]}
	/>
));

export const LightWrappedPicker = ColorWrap<{ hasInput?: boolean }>((props) => (
	<Picker
		{...props}
		from={0.65}
		to={1}
		colors={[
			'#FFB47E',
			'#FFDB76',
			'#7BDCB5',
			'#58CCA4',
			'#8ED1FC',
			'#5BB3E5',
			'#ABB8C3',
			'#EC708F',
			'#F78DA7',
			'#C071EC',
		]}
	/>
));

const Swatch = styled.div`
	margin-bottom: 5px;
	height: 14px;
	width: 14px;
	border-radius: 2px;
	cursor: pointer;
`;
const SwatchWrapper = styled.div`
	height: 20px;
	margin-top: 10px;
	margin-bottom: -5px;
	justify-content: space-between;
	display: flex;
`;
const SaturationWrapper = styled.div<{ hasInput?: boolean }>`
	height: calc(100% - 60px);
	position: relative;

	&.hasInput {
		height: calc(100% - 95px);
	}
`;
const HueWrapper = styled.div`
	height: 20px;
	position: relative;
	margin-top: 10px;
`;
const InputWrapper = styled.div`
	height: 30px;
	margin-top: 10px;
	margin-bottom: -5px;
`;
