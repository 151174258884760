import React, { FC } from 'react';
import styled from 'astroturf';

import { ReactComponent as LeftCaret } from '../../../images/icons/caret-left.svg';
import { ReactComponent as RightCaret } from '../../../images/icons/caret-right.svg';
import { Ripple } from '../../service/Ripple';

interface Props {
	black?: boolean;
	right?: boolean;
	left?: boolean;
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
}

export const SliderButton: FC<Props> = ({ className, black, right, left, disabled, onClick }) => {
	return (
		<Button className={className} black={black} right={right} left={left} onClick={onClick} disabled={disabled}>
			<Ripple>{left ? <LeftCaret /> : <RightCaret />}</Ripple>
		</Button>
	);
};

const Button = styled.div<{ black?: boolean; left?: boolean; right?: boolean; disabled?: boolean }>`
	@import '../../../styles/constants.scss';
	width: 38px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: $color-blue;
	transition: 0.5s;
	border: solid 1px #d9d9d9;
	flex-shrink: 0;
	&.black {
		fill: $color-black;
	}
	&:hover {
		background: $color-blue;
		border-color: $color-blue;
		color: white;
		fill: white;
	}
	&.disabled {
		fill: $color-dark-gray;
		pointer-events: none;
	}
	&.left {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}
	&.right {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

export const LeftSliderButton: FC<Omit<Props, 'left' | 'right'>> = (props) => <SliderButton left {...props} />;

export const RightSliderButton: FC<Omit<Props, 'left' | 'right'>> = (props) => <SliderButton right {...props} />;
