import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer } from 'ag-grid-community';
import { AdvancedSelect, AdvancedSelectProps } from '@ea/common';

export class SelectRenderer implements ICellRenderer {
	params: any;
	parentElement!: HTMLElement;
	value: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
	}
}

const Renderer = (props: AdvancedSelectProps) => {
	return (
		<Wrapper disabled={props.disabled}>
			<StyledSelect {...props} />
		</Wrapper>
	);
};

const Wrapper = styled.div<{ disabled?: boolean }>`
	min-height: 100%;
	min-width: 100%;
	height: 100%;
	&.disabled {
		opacity: 0.33;
	}
`;

const StyledSelect = styled(AdvancedSelect)`
	height: 30px;
	padding: 5px 12px;
	background: none;
`;
