import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import { mapValues, values } from 'lodash-es';
import { IGridRow } from '../../../../../../../core/interfaces/ui/grid';
import { AccessListSelector } from '../../../../../../../store/specialists/lists/access/selectors';
import { IThunkDispatch } from '../../../../../../../core/interfaces/store';
import { AccessListActions } from '../../../../../../../store/specialists/lists/access/actions';
import { AccessListAsync } from '../../../../../../../store/specialists/lists/access/actionsAsync';
import { useAfterRender } from '../../../../../../../hooks/general/useAfterRender';
import { countTruthy } from '../../../../../../../core/services/utils';
import { AccessSelection } from '../../../../../../../store/specialists/lists/access/reducer';
import { AccessEntity } from './AccessTable';

export function useAccessTableSelection(
	userName: string,
	entity: AccessEntity,
	mode: 'unknown' | 'create' | 'edit' = 'create',
) {
	const selection = useSelector(AccessListSelector.selection, isEqual);
	const dispatch = useDispatch<IThunkDispatch>();

	const updateSelection = useCallback(
		(data: AccessSelection) => {
			return dispatch(AccessListActions.updateSelection(data));
		},
		[dispatch],
	);

	const onElementSelected = useCallback(
		(id: number, companyId: number, functionId: number) => {
			if (id && companyId && functionId) {
				const companySelection = selection[companyId];

				if (companySelection) {
					const functionSelection = companySelection.functions[functionId];
					if (functionSelection) {
						const items = {
							...functionSelection.items,
							[id]: !functionSelection.items[id],
						};
						const selection: AccessSelection = {
							[companyId]: {
								...companySelection,
								functions: {
									...companySelection.functions,
									[functionId]: {
										...functionSelection,
										selected: countTruthy(items) === functionSelection.total,
										items,
									},
								},
							},
						};

						updateSelection(
							mapValues(selection, (company) => ({
								...company,
								selected:
									values(company.functions).filter((el) => el.selected).length === company.total,
							})),
						);
					}
				}
			}
		},
		[selection, updateSelection],
	);
	const onFunctionSelected = useCallback(
		(id: number, companyId: number) => {
			if (id && companyId) {
				const companySelection = selection[companyId];

				if (companySelection) {
					const functionSelection = companySelection.functions[id];

					if (functionSelection) {
						const flag = !functionSelection.selected;

						const selection: AccessSelection = {
							[companyId]: {
								...companySelection,
								functions: {
									...companySelection.functions,
									[id]: {
										...functionSelection,
										selected: flag,
										items: mapValues(functionSelection.items, () => flag),
									},
								},
							},
						};

						updateSelection(
							mapValues(selection, (company) => ({
								...company,
								selected:
									values(company.functions).filter((el) => el.selected).length === company.total,
							})),
						);
					}
				}
			}
		},
		[selection, updateSelection],
	);

	const onCompanySelected = useCallback(
		(id: number) => {
			if (id) {
				const companySelection = selection[id];
				if (companySelection) {
					const flag = !companySelection.selected;
					updateSelection({
						[id]: {
							...companySelection,
							selected: flag,
							functions: mapValues(selection[id].functions, (data) => ({
								...data,
								selected: flag,
								items: mapValues(data.items, () => flag),
							})),
						},
					});
				}
			}
		},
		[selection, updateSelection],
	);

	const onRowSelected = useCallback(
		(node: IGridRow) => {
			if (node.header) {
				onCompanySelected(node.entityId || node.id);
			} else if (node.section) {
				onFunctionSelected(node.entityId || node.id, node.companyId);
			} else {
				onElementSelected(node.entityId || node.id, node.companyId, node.functionId);
			}
		},
		[onElementSelected, onFunctionSelected, onCompanySelected],
	);

	const getStatistics = useCallback(
		(companyId: number) => {
			return dispatch(
				AccessListAsync.getStatistics({ companyId, userName }, entity, mode === 'create' ? 'create' : 'edit'),
			);
		},
		[dispatch, userName, entity, mode],
	);

	const selectRow = useAfterRender((node: IGridRow) => {
		onRowSelected(node);
	});

	return useCallback(
		(node: IGridRow) => {
			const companyId = (node.header ? node.id : node.companyId) as number;

			if (selection[companyId]) {
				return onRowSelected(node);
			} else {
				getStatistics(companyId).then(() => {
					selectRow(node);
				});
			}
		},
		[selection, getStatistics],
	);
}
