export function hexToRGB(hex: string, opacity = 1): string {
	let colors: string[];
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		colors = hex.substring(1).split('');
		if (colors.length == 3) {
			colors = [colors[0], colors[0], colors[1], colors[1], colors[2], colors[2]];
		}
		const color = Number('0x' + colors.join(''));
		return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',')},${opacity})`;
	}
	throw new Error('Incorrect Hex');
}
