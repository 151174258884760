import { useCallback, useEffect, useState } from 'react';

export const useWindowSize = () => {
	const isClient = typeof window === 'object';

	const getSize = useCallback(() => {
		return {
			width: isClient ? document.body.clientWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		};
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(() => getSize());

	useEffect(() => {
		if (!isClient) {
			return undefined;
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		window.addEventListener('orientationchange', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, [getSize, isClient]);

	return windowSize;
};
