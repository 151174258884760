import React, { FC, useCallback } from 'react';
import styled from 'astroturf';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Field, FieldProps, Formik } from 'formik';
import { AuthAsync } from '../../store/general/auth/actionsSync';
import { BlockButton } from '../../components/general/elements/buttons/BlockButton';
import { IThunkDispatch } from '../../core/interfaces/store';
import { useTranslation } from '../../i18n/hooks';
import { CompetencesListActions } from '../../store/specialists/lists/competences/actions';
import { QuestionsListActions } from '../../store/specialists/lists/questions/actions';
import { ProfilesListActions } from '../../store/specialists/lists/profiles/actions';
import { PersonsListActions } from '../../store/specialists/lists/persons/actions';
import { AdminActivityListActions } from '../../store/specialists/lists/adminActivity/actions';
import { UserActivityListActions } from '../../store/specialists/lists/userActivity/actions';
import { AdministrationListActions } from '../../store/specialists/lists/administration/actions';
import { MaterialInput } from '../../components/general/elements/fields/MaterialInput';
import { ReactComponent as Logo } from '../../components/general/icons/big-logo.svg';

interface IFormValues {
	login: string;
	password: string;
}

export const Login: FC = () => {
	const dispatch = useDispatch<IThunkDispatch>();
	const history = useHistory();
	const { t } = useTranslation();
	const onSubmit = useCallback(
		({ login, password }: IFormValues) => {
			return dispatch(
				AuthAsync.login({
					username: login.trim().toLowerCase(),
					password: password.trim(),
				}),
			)
				.then(() => {
					dispatch(ProfilesListActions.setChanged());
					dispatch(CompetencesListActions.setChanged());
					dispatch(QuestionsListActions.setChanged());
					dispatch(PersonsListActions.setChanged());
					dispatch(AdminActivityListActions.setChanged());
					dispatch(UserActivityListActions.setChanged());
					dispatch(AdministrationListActions.setChanged());
					history.push('/');
				})
				.catch((error) => {
					if (error.message === 'USER_BLOCKED') {
						toast.error(t('words.userBlocked'));
					} else if (error.message === 'USER_DELETED') {
						toast.error(t('words.userDeleted'));
					} else {
						toast.error(t('words.loginError'));
					}
				});
		},
		[history, dispatch, t],
	);

	return (
		<Wrapper>
			<TopStretcher />
			<BigLogo />
			<Title>{t('title')}</Title>
			<TitleStretcher />
			<Column>
				<Formik
					initialValues={{
						login: '',
						password: '',
					}}
					onSubmit={onSubmit}
				>
					{({ isSubmitting, handleSubmit }) => (
						<Form onSubmit={handleSubmit}>
							<Content>
								<LoginTitle> {t('words.signIn')} </LoginTitle>
								<Field name={'login'}>
									{({ field }: FieldProps<string>) => (
										<Input placeholder={t('words.login')} {...field} />
									)}
								</Field>
								<Field name={'password'}>
									{({ field }: FieldProps<string>) => (
										<Input type={'password'} placeholder={t('words.password')} {...field} />
									)}
								</Field>
								<Button type={'submit'} size={'large'} disabled={isSubmitting} color="blue">
									Войти
								</Button>
							</Content>
						</Form>
					)}
				</Formik>
			</Column>
			<BottomStretcher />
			<Copyright>© Expert Me LLC</Copyright>
		</Wrapper>
	);
};

export const BigLogo = styled(Logo)`
	margin-top: auto;
	flex-shrink: 0;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
`;

export const Content = styled.div`
	padding: 52px 86px 64px;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
	background-color: #ffffff;
	max-width: 100%;
`;

const TitleStretcher = styled.div`
	min-height: 52px;
	height: 102px;
`;

const TopStretcher = styled.div`
	min-height: 32px;
	height: 64px;
`;

const BottomStretcher = styled.div`
	min-height: 64px;
	height: 142px;
`;

const Column = styled.div`
	width: 460px;
	text-align: center;
`;

const Title = styled.div`
	font-size: 24px;
	line-height: 36px;
	margin-top: 12px;
	font-weight: 600;
	text-align: center;
	padding-left: 12px;
	padding-right: 12px;
`;

const LoginTitle = styled.div`
	font-weight: 600;
	font-size: 24px;
	height: 24px;
	margin-bottom: 70px;
`;

const Form = styled.form``;

const Input = styled(MaterialInput)`
	margin-bottom: 24px;
`;

const Button = styled(BlockButton)`
	margin-top: 120px;
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	padding: 11px;
	text-transform: none;
	font-weight: 600;
`;

const Copyright = styled(Column)`
	margin-top: auto;
	border-top: solid 1px #e8e8e8;
	padding-top: 32px;
	font-size: 12px;
	line-height: 16px;
	padding-bottom: 32px;
`;
