import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useGridLocalization(noRowsToShow?: string) {
	const { t } = useTranslation();
	return useMemo(() => {
		return {
			loadingOoo: t('grid.loading'),
			noRowsToShow: noRowsToShow || t('grid.noRowsToShow'),
		};
	}, [t, noRowsToShow]);
}
