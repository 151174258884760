import React from 'react';
import ReactDOM from 'react-dom';
import { ICellRenderer } from 'ag-grid-community';
import { HeaderWrapper } from './HeaderWrapper';

export class HeaderRenderer implements ICellRenderer {
	params: any;
	parentElement?: HTMLElement;
	value: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<HeaderWrapper {...params} />, this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(<HeaderWrapper {...params} />, this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
	}
}
