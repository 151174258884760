import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../../buttons/TextButton';
import { useOnEnter } from '../../../../service/hooks/useOnEnter';

interface IProps {
	onEdit?: (text: string, item: any) => Promise<any>;
	saveText?: string;
	item: any;
	value: string;
}

export const EditSection: FC<IProps> = ({ onEdit, saveText, value, item }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [editValue, setValue] = useState(value);

	useEffect(() => {
		if (editValue !== value) {
			setValue(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const input = useRef<HTMLInputElement>(null);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value);
		},
		[setValue],
	);

	const onSubmit = useCallback(() => {
		const trimmedValue = editValue.trim();
		onEdit &&
			onEdit(trimmedValue, item)
				.then(() => {
					setOpen(false);
				})
				.finally(() => {
					setValue('');
				});
	}, [onEdit, item, editValue, setOpen]);

	const onEnter = useOnEnter(() => {
		onSubmit();
	}, [onSubmit]);

	useEffect(() => {
		if (open && input.current) {
			input.current.focus();
		}
	}, [open]);

	return (
		<Wrapper>
			<AddInputWrapper>
				<AddInput ref={input} value={editValue} onChange={onChange} onKeyPress={onEnter} />
				<SaveButton onClick={onSubmit}>{saveText || t('common.actions.save')}</SaveButton>
			</AddInputWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	@import '../../../../styles/constants.scss';
	color: $color-blue;
	font-size: 12px;
`;

const AddInputWrapper = styled.div`
	height: 24px;
	border: solid 1px #f5f5f5;
	display: flex;
	align-items: center;
	margin: 6px 9px 5px 12px;
`;

const AddInput = styled.input`
	border: none;
	outline: 0;
	min-width: 0;
	text-overflow: ellipsis;
	padding-left: 8px;
	font-size: 12px;
	width: 100%;
`;

const SaveButton = styled(TextButton)`
	font-weight: normal !important;
	width: 85px;
`;
