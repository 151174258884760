import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { isArray } from 'lodash-es';
import { PaginatedParametrizedContentLoading, ParametrizedContentLoading } from '@ea/common';
import { initialContentLoading, initialPaginatedContentLoading } from '@ea/common';
import { Dict } from '../../../../core/types';
import { reorderColumns } from '../../../../core/services/grid';
import { Row } from '../../../../service/typings/lists';
import { GridColumn } from '../../../../service/typings/grid';
import { FilterDefinition } from '../../../../service/typings/filters';
import { GetAdminActivityListResponse } from './typings';
import { AdminActivityListActions } from './actions';

export interface AdminActivityListData {
	adminActivity: Dict<GetAdminActivityListResponse['_embedded']>;
	rows: Row[];
}

export interface AdminActivityListState {
	data: PaginatedParametrizedContentLoading<AdminActivityListData>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	adminActivity: {},
	rows: [],
});

const initialState: AdminActivityListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	lastParams: {},
	scroll: 0,
};

export const AdminActivityListReducer = reducerWithInitialState(initialState)
	.case(AdminActivityListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(AdminActivityListActions.getList.done, (state, { result, params }) => {
		const normalizeResult = normalize(result);
		const initial = initialData();
		return {
			...state,
			data: {
				content: {
					adminActivity: { ...initial.adminActivity, ...normalizeResult.adminActivity },
					rows: [...initial.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'full',
					status: 'loaded',
				},
				pagination: result.page,
				params,
			},
			scroll: 0,
		};
	})
	.case(AdminActivityListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(AdminActivityListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(AdminActivityListActions.loadMore.done, (state, { result }) => {
		const normalizeResult = normalize(result);
		return {
			...state,
			data: {
				...state.data,
				content: {
					adminActivity: { ...state.data.content.adminActivity, ...normalizeResult.adminActivity },
					rows: [...state.data.content.rows, ...normalizeResult.rows],
				},
				loading: {
					type: 'append',
					status: 'loaded',
				},
				pagination: result.page,
			},
		};
	})
	.case(AdminActivityListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(AdminActivityListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AdminActivityListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(AdminActivityListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(AdminActivityListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(AdminActivityListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(AdminActivityListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(AdminActivityListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	.case(AdminActivityListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AdminActivityListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(AdminActivityListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(AdminActivityListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(AdminActivityListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(AdminActivityListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}));

function normalize(data: GetAdminActivityListResponse) {
	const result: AdminActivityListData = {
		adminActivity: {},
		rows: [],
	};

	const { adminActivity } = data._embedded;

	(adminActivity || []).forEach((item: any) => {
		result.adminActivity[item.adminActivityId] = { id: item.adminActivityId, ...item };
		result.rows.push({
			id: item.adminActivityId,
		});
	});

	return result;
}
