import { TCompetencesTabs } from '../../pages/specialists/Competences/Competences/CompetencePage';
import { AccessEntity } from '../../pages/specialists/Settings/Administration/AdministrationUserPage/Access/AccessTable/AccessTable';
import { specialistsConfig } from '../../config/specialists';

const prefix = specialistsConfig.routePrefix;

export const specialistsRoutes = {
	root: { path: `${prefix}/`, link: () => `${prefix}/` },
	projects: {
		root: { path: `${prefix}/projects`, link: () => `${prefix}/projects` },
		company: {
			path: `${prefix}/projects/:companyId/`,
			link: (companyId: number) => `${prefix}/projects/${companyId}/`,
		},
	},
	library: {
		root: { path: `${prefix}/library`, link: () => `${prefix}/library` },
		company: {
			path: `${prefix}/library/:companyId/`,
			link: (companyId: number) => `${prefix}/library/${companyId}/`,
		},
		profiles: {
			list: {
				path: `${prefix}/library/:companyId/profiles`,
				link: (companyId: number) => `${prefix}/library/${companyId}/profiles`,
			},
			trash: {
				path: `${prefix}/library/:companyId/profiles/trash`,
				link: (companyId: number) => `${prefix}/library/${companyId}/profiles/trash`,
			},
			editing: {
				path: `${prefix}/library/:companyId/profiles/editing`,
				link: (companyId: number) => `${prefix}/library/${companyId}/profiles/editing`,
			},
			create: {
				path: `${prefix}/library/:companyId/profiles/create`,
				link: (companyId: number) => `${prefix}/library/${companyId}/profiles/create`,
			},
			card: {
				path: `${prefix}/library/:companyId/profiles/:id`,
				link: (companyId: number, id: number) => `${prefix}/library/${companyId}/profiles/${id}`,
			},
		},
		competences: {
			list: {
				path: `${prefix}/library/:companyId/competences`,
				link: (companyId: number) => `${prefix}/library/${companyId}/competences`,
			},
			trash: {
				path: `${prefix}/library/:companyId/competences/trash`,
				link: (companyId: number) => `${prefix}/library/${companyId}/competences/trash`,
			},
			editing: {
				path: `${prefix}/library/:companyId/competences/editing`,
				link: (companyId: number) => `${prefix}/library/${companyId}/competences/editing`,
			},
			create: {
				path: `${prefix}/library/:companyId/competences/create`,
				link: (companyId: number) => `${prefix}/library/${companyId}/competences/create`,
			},
			card: {
				path: `${prefix}/library/:companyId/competences/:id/:functionId/:tab?`,
				link: (companyId: number, id: number, functionId?: number, tab?: TCompetencesTabs) =>
					`${prefix}/library/${companyId}/competences/${id}/${functionId || ``}/${tab || ``}`,
			},
			profile: {
				path: `${prefix}/library/:companyId/competences/:competenceId/profile/:id`,
				link: (companyId: number, competenceId: number, id: number) =>
					`${prefix}/library/${companyId}/competences/${competenceId}/profile/${id}`,
			},
			question: {
				path: `${prefix}/library/:companyId/competences/:competenceId/question/:id`,
				link: (companyId: number, competenceId: number, id: number) =>
					`${prefix}/library/${companyId}/competences/${competenceId}/question/${id}`,
			},
		},
		questions: {
			list: {
				path: `${prefix}/library/:companyId/questions`,
				link: (companyId: number) => `${prefix}/library/${companyId}/questions`,
			},
			trash: {
				path: `${prefix}/library/:companyId/questions/trash`,
				link: (companyId: number) => `${prefix}/library/${companyId}/questions/trash`,
			},
			editing: {
				path: `${prefix}/library/:companyId/questions/editing`,
				link: (companyId: number) => `${prefix}/library/${companyId}/questions/editing`,
			},
			create: {
				path: `${prefix}/library/:companyId/questions/create`,
				link: (companyId: number) => `${prefix}/library/${companyId}/questions/create`,
			},
			card: {
				path: `${prefix}/library/:companyId/questions/:id/:functionId`,
				link: (companyId: number, id: number, functionId: number) =>
					`${prefix}/library/${companyId}/questions/${id}/${functionId}`,
			},
		},
	},
	estimate: {
		root: { path: `${prefix}/estimate`, link: () => `${prefix}/estimate` },
		company: {
			path: `${prefix}/estimate/:companyId/`,
			link: (companyId: number) => `${prefix}/estimate/${companyId}/`,
		},
		staff: {
			trash: {
				path: `${prefix}/estimate/:companyId/staff/trash`,
				link: (companyId: number) => `${prefix}/estimate/${companyId}/staff/trash`,
			},
			list: {
				path: `${prefix}/estimate/:companyId/staff`,
				link: (companyId: number) => `${prefix}/estimate/${companyId}/staff`,
			},
			card: {
				path: `${prefix}/estimate/:companyId/staff/:id`,
				link: (companyId: string | number, id: string | number) =>
					`${prefix}/estimate/${companyId}/staff/${id}`,
			},
		},
		estimateSettings: {
			path: `${prefix}/estimate/:companyId/settings`,
			link: (companyId: number) => `${prefix}/estimate/${companyId}/settings`,
		},
	},
	settings: {
		root: { path: `${prefix}/settings`, link: () => `${prefix}/settings` },
		company: {
			path: `${prefix}/settings/:companyId/`,
			link: (companyId: number) => `${prefix}/settings/${companyId}/`,
		},
		userActivity: {
			list: {
				path: `${prefix}/settings/:companyId/userActivity`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/userActivity`,
			},
			trash: {
				path: `${prefix}/settings/:companyId/userActivity/trash`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/userActivity/trash`,
			},
		},
		adminActivity: {
			list: {
				path: `${prefix}/settings/:companyId/adminActivity`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/adminActivity`,
			},
			trash: {
				path: `${prefix}/settings/:companyId/adminActivity/trash`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/adminActivity/trash`,
			},
		},
		administration: {
			list: {
				path: `${prefix}/settings/:companyId/administration`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/administration`,
			},
			trash: {
				path: `${prefix}/settings/:companyId/administration/trash`,
				link: (companyId: number) => `${prefix}/settings/${companyId}/administration/trash`,
			},
			edit: {
				path: `${prefix}/settings/:companyId/administration/edit/:email/:tab?`,
				link: (companyId: number, email: string, tab?: AccessEntity) =>
					`${prefix}/settings/${companyId}/administration/edit/${email}/${tab || ``}`,
			},
			create: {
				path: `${prefix}/settings/:companyId/administration/create/:tab?`,
				link: (companyId: number, tab?: AccessEntity) =>
					`${prefix}/settings/${companyId}/administration/create/${tab || ``}`,
			},
		},
		proctoring: {
			path: `${prefix}/settings/:companyId/proctoring/`,
			link: (companyId: number) => `${prefix}/settings/${companyId}/proctoring/`,
		},
	},
};
