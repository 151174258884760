import React, { ComponentType, createContext, FC, useState } from 'react';
import { isFunction } from 'lodash';
import { generateUuid } from '../../../service/helpers/uuid';

export interface ModalOptions {
	[key: string]: any;
}

export interface ModalState {
	id: string;
	props: ModalOptions;
	open: boolean;
}

export interface ModalInstance<Props extends BasicModalProps = any> {
	state: ModalState;
	component: ComponentType<Props>;
	onClose: () => void;
	onDestroy: () => void;
}

type AdditionalModalsProps<Props> = Exclude<Props, keyof BasicModalProps>;

export interface ModalsManager {
	openModal: <Props extends BasicModalProps>(component: ComponentType<Props>, props: ModalProps<Props>) => string;
	closeModal: (id: string) => void;
	destroyModal: (id: string) => void;
}

export interface BasicModalProps {
	open?: boolean;
	onClose?: () => void;
	onDestroy?: () => void;
}

export type ModalProps<Props> = AdditionalModalsProps<Props> | ((id: string) => AdditionalModalsProps<Props>);

export const ModalsContext = createContext<ModalsManager | null>(null);

export const ModalsProvider: FC = ({ children }) => {
	const [modals, setModals] = useState<ModalInstance[]>([]);

	const closeModal = (id: string) => {
		setModals((modals) =>
			modals.map((el) => (el.state.id === id ? { ...el, state: { ...el.state, open: false } } : el)),
		);
	};
	const destroyModal = (id: string) => {
		setModals((modals) => modals.filter((el) => el.state.id !== id));
	};

	const openModal = <Props extends BasicModalProps>(
		component: ComponentType<Props>,
		props: AdditionalModalsProps<Props> | ((id: string) => AdditionalModalsProps<Props>),
	) => {
		const id = generateUuid();

		const state = {
			id,
			props: isFunction(props) ? props(id) : props,
			open: true,
		};

		setModals((modals) => [
			...modals,
			{
				state,
				component,
				onClose() {
					closeModal(state.id);
				},
				onDestroy() {
					destroyModal(state.id);
				},
			},
		]);
		return state.id;
	};

	const manager = {
		openModal,
		closeModal,
		destroyModal,
	};

	return (
		<ModalsContext.Provider value={manager}>
			{children}
			{modals.map((modal) => (
				<modal.component
					open={modal.state.open}
					key={modal.state.id}
					onClose={modal.onClose}
					onDestroy={modal.onDestroy}
					{...modal.state.props}
				/>
			))}
		</ModalsContext.Provider>
	);
};
