import { actionCreatorFactory } from 'typescript-fsa';
import {
	CompetenceDetailed,
	CompetenceDetailedDraft,
	GetCompetenceDetailsRequest,
	GetCompetenceDetailsTemplate,
	GetCompetenceProfilesRequest,
	GetCompetenceProfilesResponse,
	GetCompetenceQuestionsRequest,
	GetCompetenceQuestionsResponse,
	LoadMoreProfilesRequest,
	LoadMoreQuestionsRequest,
	UpdateCompetenceDetails,
} from './typings';

const ActionFactory = actionCreatorFactory('COMPETENCE_DETAIL');

export const CompetenceDetailsActions = {
	get: ActionFactory.async<GetCompetenceDetailsRequest, CompetenceDetailed, Error>('GET'),
	getProfiles: ActionFactory.async<GetCompetenceProfilesRequest, GetCompetenceProfilesResponse, Error>(
		'GET_PROFILES',
	),
	loadMoreProfiles: ActionFactory.async<LoadMoreProfilesRequest, GetCompetenceProfilesResponse, Error>(
		'LOAD_MORE_PROFILES',
	),
	loadMoreQuestions: ActionFactory.async<LoadMoreQuestionsRequest, GetCompetenceQuestionsResponse, Error>(
		'LOAD_MORE_QUESTIONS',
	),
	getQuestions: ActionFactory.async<GetCompetenceQuestionsRequest, GetCompetenceQuestionsResponse, Error>(
		'GET_QUESTIONS',
	),
	getTemplate: ActionFactory.async<GetCompetenceDetailsTemplate, CompetenceDetailedDraft, Error>('GET_TEMPLATE'),
	update: ActionFactory.async<UpdateCompetenceDetails, CompetenceDetailed, Error>('UPDATE'),
};
