import { DependencyList, useCallback, useRef } from 'react';

export function useDisposableCallback<T extends (...args: any[]) => any>(callback: T, dependencies: DependencyList) {
	const shooted = useRef(false);
	return useCallback(
		(...args: any) => {
			if (!shooted.current) {
				callback(...args);
				shooted.current = true;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[...dependencies],
	);
}
