import React, { FC, MouseEvent, useCallback } from 'react';
import styled from 'astroturf';
import { ReactComponent as RemoveIcon } from '../../../../images/icons/grid/remove-reference-gray.svg';
import { Ripple } from '../../../service/Ripple';
import { Reference } from '../../../../service/typings/entities';
import { TextEllipsisWithTooltip } from '../../../service/TextEllipsisWithTooltip';

interface IProps extends Reference {
	onSelect?: (id: number) => void;
	onRemove?: (id: number) => void;
	onChangeColor?: (color: string, target: Reference) => void;
	className?: string;
	last?: boolean;
	withColor?: boolean;
	showCount?: boolean;
	removable?: boolean;
}

export const Item: FC<IProps> = React.memo(
	({
		id,
		name,
		color,
		withColor,
		count,
		onSelect,
		onRemove,
		onChangeColor,
		className,
		last,
		removable,
		showCount,
	}) => {
		const onClick = useCallback(() => {
			if (onSelect && id) {
				onSelect(id);
			}
		}, [id, onSelect]);

		const onColorClick = useCallback(() => {
			if (onChangeColor) {
				onChangeColor(color || '#FFF', {
					id,
					name,
					color: color,
				});
			}
		}, [id, name, color, onSelect]);

		const onRemoveClick = useCallback(
			(event: MouseEvent) => {
				if (onRemove && id) {
					onRemove(id);
				}
				event.stopPropagation();
				event.preventDefault();
			},
			[id, onRemove],
		);

		return (
			<Wrapper>
				{withColor && <Color style={{ backgroundColor: color }} onClick={onColorClick} />}
				<Ripple onClick={onClick}>
					<NameWrapper className={className} last={last}>
						{removable && <StyledRemoveIcon onClick={onRemoveClick} />}
						<Name> {name} </Name>
						{showCount && <Count>{count || 0}</Count>}
					</NameWrapper>
				</Ripple>
			</Wrapper>
		);
	},
);

const StyledRemoveIcon = styled(RemoveIcon)`
	flex-shrink: 0;
	margin-right: 6px;
`;

const Wrapper = styled.div<{ even?: boolean }>`
	@import '../../../../styles/mixins.scss';
	@include long-content();
	display: flex;
	letter-spacing: normal;
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	white-space: nowrap;
	text-align: left;
`;

export const Color = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 2px;
	border: solid 1px #cacaca;
	margin-right: 5px;
	flex-shrink: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NameWrapper = styled.div<{ last?: boolean }>`
	display: flex;
	height: 34px;
	border-bottom: solid 1px #cacaca;
	align-items: center;
	width: 100%;

	&.last {
		border-bottom: none;
	}
`;

const Count = styled.div`
	margin-right: 11px;
	margin-left: auto;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.33;
	flex-shrink: 0;
`;

const Name = styled(TextEllipsisWithTooltip)`
	font-weight: bold;
	font-size: 12px;
	text-align: left;
	margin-right: 12px;
	width: 100%;
`;
