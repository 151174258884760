import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer } from 'ag-grid-community';
import { ProfileSelect } from '@ea/common';

export class ProfileSelectRenderer implements ICellRenderer {
	params: any;
	parentElement!: HTMLElement;
	value: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
	}
}

const Renderer = (props: any) => {
	const { companyId, functionId, onChange, value, onGetCompanies, onGetFunctions, onGetProfiles } = props;

	return (
		<Wrapper>
			<ProfileSelect
				values={value}
				companyId={companyId}
				functionId={functionId}
				onChange={onChange}
				onGetCompanies={onGetCompanies}
				onGetFunctions={onGetFunctions}
				onGetProfiles={onGetProfiles}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	min-height: 100%;
	min-width: 100%;
	height: 100%;
`;
