import { callApi } from '../../apiActionsSync';
import { AuthActions } from './actions';
import { LoginRequest } from './typings';

export const AuthAsync = {
	login: (params: LoginRequest) =>
		callApi({
			url: 'login',
			method: 'POST',
			params,
			noErrorToast: true,
			actions: AuthActions.login,
		}),
	logout: () =>
		callApi({
			url: 'logout',
			method: 'POST',
			params: {},
			actions: AuthActions.logout,
		}),
};
