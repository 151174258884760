import { flatten, mapValues, omit, reduce, uniq, values } from 'lodash-es';
import { IGridColumn, IGridCommonSelection, IGridSort } from '../interfaces/ui/grid';
import { Dict } from '../types';
import { GridCommonSelection } from '../../service/typings/grid';
import { GetStatisticsResponse } from '../../service/typings/actions/statistics';
import { Row } from '../../service/typings/lists';
import { FiltersValuesState } from '../../service/typings/filters';
import { stringify } from './queryString';
import { arrayToObject, countTruthy, omitEmpty } from './utils';
import { filterValuesToQuery } from './filters';

export function addSorting(columns: IGridColumn[], options?: IGridSort) {
	if (options) {
		return columns.map((column) => ({
			...column,
			sortingActive: options.field === column.field,
			sortingDirection: options.field === column.field ? options.order : 'asc',
		}));
	}
	return columns;
}

export function gridParamsToQuery(params: Dict) {
	// TODO: вырезается идентификатор компании. Остальное влияет на получение данных, поэтому остаётся
	return stringify(omit(params, ['companyId', 'isDelete']));
}

interface IGridParams {
	query?: string;
	isTrash?: boolean;
	sortingQuery?: { sort?: string };
	companyId?: number;
	searchMode?: string;
	filtersValues?: FiltersValuesState;
}

export function gridParams({ query, isTrash, sortingQuery, companyId, searchMode, filtersValues }: IGridParams) {
	return omitEmpty({
		query,
		isDelete: isTrash,
		...sortingQuery,
		...(!query || searchMode !== 'everywhere'
			? {
					companyId,
					...(!isTrash ? filterValuesToQuery(filtersValues) : {}),
			  }
			: {}),
	});
}

export function removeRows(rows: Row[], condition: (row: Row) => boolean) {
	return rows
		.filter((row) => !condition(row))
		.filter(
			(row, index, rows) =>
				rows[index].type !== 'functions' || (index + 1 < rows.length && rows[index + 1].type !== 'functions'),
		)
		.filter(
			(row, index, rows) =>
				rows[index].type !== 'companies' || (index + 1 < rows.length && rows[index + 1].type !== 'companies'),
		);
}

// TODO: костыль, сделанный для того, чтобы идентификаторы столбцов менялись после изменения их порядка
// На самом деле идентификаторы столбцов не должны меняться на бекенде при изменении порядка оных
export function reorderColumns(columns: IGridColumn[], reorderedColumns: IGridColumn[]) {
	return columns.map((column) => {
		const updatedColumn = reorderedColumns.find((el) => el.field === column.field);
		if (updatedColumn) {
			return {
				...column,
				id: updatedColumn.id,
			};
		} else {
			return column;
		}
	});
}

export function removeRowsSelection(selection: Partial<IGridCommonSelection>, params: number[]) {
	const result = {} as IGridCommonSelection;
	if (selection.rows) {
		result.rows = omit(selection.rows, params);
	}
	if (selection.rows) {
		result.companies = mapValues(selection.companies, (company) => {
			if (company) {
				const selected = omit(company.selected, params);
				return {
					selected,
					total: company.total - (countTruthy(company.selected) - countTruthy(selected)),
					functions: mapValues(company.functions, (value) => {
						if (value) {
							const selected = omit(value.selected, params);
							return {
								selected,
								total: value.total - (countTruthy(value.selected) - countTruthy(selected)),
							};
						}
						return value;
					}),
				};
			}
			return company;
		});
	}
	return result;
}

export function updateSelectionStatistics(currentSelection: GridCommonSelection, statistics: GetStatisticsResponse) {
	return {
		...currentSelection,
		companies: {
			...currentSelection.companies,
			...mapValues(statistics.companies, (value, companyId) => {
				const rows = uniq(flatten(values(value.functions)));
				return {
					selected: {
						...arrayToObject(rows, false),
						...currentSelection.companies[companyId]?.selected,
					},
					// TODO: проверить, можно ли заменить на value.total
					total: rows.length,
					functions: mapValues(value.functions, (value) => ({
						selected: arrayToObject(value, false),
						total: value.length,
					})),
				};
			}),
		},
	};
}

interface Selection<T extends string | number> extends IGridCommonSelection {
	count: number;
	ids: T[];
}

export function selectionSelector(selection: IGridCommonSelection, numeric: false): Selection<string>;
export function selectionSelector(selection: IGridCommonSelection, numeric?: true): Selection<number>;
export function selectionSelector(selection: IGridCommonSelection, numeric = true): Selection<number | string> {
	const ids = reduce(
		selection.rows,
		(result, selected, id) => (selected ? result.concat(numeric ? Number(id) : id) : result),
		[] as (number | string)[],
	);
	return {
		...selection,
		count: ids.length,
		ids,
	};
}
