import { specialistsRoutes } from './specialists';

export interface IRoute {
	path: string;
	link: (value?: string | number, key?: string | number, key2?: string | number) => string;
}

export const routes = {
	login: { path: '/login/', link: () => `/login` },
	notFound: { path: '/404/', link: () => `/404` },
	root: {
		path: '/',
		link: () => `/`,
	},
	specialists: specialistsRoutes,
};
