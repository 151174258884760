import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Dict, initialContentLoading, ContentLoading, PaginatedContentLoading } from '@ea/common';
import { CompetenceDetailsActions } from './actions';
import { CompetenceDetailed, CompetenceDetailedDraft, GetCompetenceProfilesResponse } from './typings';

export interface CompetenceDetailsState {
	data: ContentLoading<CompetenceDetailed | null>;
	template: ContentLoading<CompetenceDetailedDraft | null>;
	profiles: Dict<PaginatedContentLoading<Dict[]>>;
	questions: Dict<PaginatedContentLoading<Dict[]>>;
}

const initialState = (): CompetenceDetailsState => ({
	data: initialContentLoading(null),
	template: initialContentLoading(null),
	profiles: {},
	questions: {},
});

export const CompetenceDetailsReducer = reducerWithInitialState(initialState())
	.case(CompetenceDetailsActions.get.started, (state) => ({
		...state,
		data: {
			content: null,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(CompetenceDetailsActions.get.failed, (state, { error }) => ({
		...state,
		data: {
			loading: {
				status: 'loaded',
			},
			content: null,
			error,
		},
	}))
	.case(CompetenceDetailsActions.get.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(CompetenceDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			[result.data.id]: {
				content: result,
				loading: {
					status: 'loaded',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.getProfiles.started, (state, params) => ({
		...state,
		profiles: {
			...state.profiles,
			[params.competenceId]: {
				...state.profiles[params.competenceId],
				loading: {
					status: 'loading',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.getProfiles.failed, (state, { params, error }) => ({
		...state,
		profiles: {
			...state.profiles,
			[params.competenceId]: {
				...state.profiles[params.competenceId],
				loading: {
					status: 'loaded',
				},
				error,
			},
		},
	}))
	.case(CompetenceDetailsActions.getProfiles.done, (state, { params, result }) => ({
		...state,
		profiles: {
			...state.profiles,
			[params.competenceId]: {
				pagination: result.page,
				content: normalizeProfiles(result.functions),
				loading: {
					status: 'loaded',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreProfiles.started, (state, params) => ({
		...state,
		profiles: {
			...state.profiles,
			[params.competenceId]: {
				...state.profiles[params.competenceId],
				loading: {
					status: 'loading',
					type: 'append',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreProfiles.failed, (state, { params, error }) => ({
		...state,
		profiles: {
			...state.profiles,
			[params.competenceId]: {
				...state.profiles[params.competenceId],
				loading: {
					status: 'loaded',
					type: 'append',
				},
				error,
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreProfiles.done, (state, { params, result }) => {
		const normalized = normalizeProfiles(result.functions);
		return {
			...state,
			profiles: {
				...state.profiles,
				[params.competenceId]: {
					...state.profiles[params.competenceId],
					content: joinProfiles(state.profiles[params.competenceId].content, normalized),
					loading: {
						status: 'loaded',
						type: 'append',
					},
					pagination: {
						...result.page,
						more: normalized.length > 0,
					},
				},
			},
		};
	})
	.case(CompetenceDetailsActions.getQuestions.started, (state, params) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				...state.questions[params.competenceId],
				content: [],
				loading: {
					status: 'loading',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.getQuestions.failed, (state, { params, error }) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				...state.questions[params.competenceId],
				content: [],
				loading: {
					status: 'loaded',
				},
				error,
			},
		},
	}))
	.case(CompetenceDetailsActions.getQuestions.done, (state, { params, result }) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				content: result.questions,
				loading: {
					status: 'loaded',
				},
				pagination: result.page,
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreQuestions.started, (state, params) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				...state.questions[params.competenceId],
				loading: {
					status: 'loading',
					type: 'append',
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreQuestions.failed, (state, { params, error }) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				...state.questions[params.competenceId],
				loading: {
					status: 'loaded',
					type: 'append',
				},
				error,
			},
		},
	}))
	.case(CompetenceDetailsActions.loadMoreQuestions.done, (state, { params, result }) => ({
		...state,
		questions: {
			...state.questions,
			[params.competenceId]: {
				content: [...state.questions[params.competenceId].content, ...result.questions],
				loading: {
					status: 'loaded',
					type: 'append',
				},
				pagination: {
					...result.page,
					more: result.questions.length > 0,
				},
			},
		},
	}))
	.case(CompetenceDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(CompetenceDetailsActions.getTemplate.done, (state, { result }) => ({
		...state,
		template: {
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}));

function normalizeProfiles(data: GetCompetenceProfilesResponse['functions']) {
	return data.reduce<Dict[]>(
		(result, { profiles, ...functionData }) => [
			...result,
			{
				section: true,
				...functionData,
			},
			...profiles,
		],
		[],
	);
}

// TODO: дешёвое решение
function joinProfiles(rows: Dict[], newRows: Dict[]) {
	return [...rows, ...newRows.filter((row) => !row.section || !rows.some((el) => el.id === row.id))];
}
