import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetProfileDetails,
	GetProfileDetailsTemplate,
	ProfileDetailed,
	ProfileDetailedDraft,
	UpdateProfileDetails,
} from './typings';

const ActionFactory = actionCreatorFactory('PROFILE_DETAIL');

export const ProfileDetailsActions = {
	get: ActionFactory.async<GetProfileDetails, ProfileDetailed, Error>('GET'),
	getTemplate: ActionFactory.async<GetProfileDetailsTemplate, ProfileDetailedDraft, Error>('GET_TEMPLATE'),
	update: ActionFactory.async<UpdateProfileDetails, ProfileDetailed, Error>('UPDATE'),
};
