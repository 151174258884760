import React, { FC } from 'react';
import styled from 'astroturf';
import { Link } from 'react-router-dom';
import { Header } from '../../components/general/layout/Header';
import { BlockButton } from '../../components/general/elements/buttons/BlockButton';
import { useTranslation } from '../../i18n/hooks';

export const NotFound: FC = () => {
	const { t } = useTranslation();
	return (
		<Layout>
			<Header />
			<Content>
				<Container>
					<MainBlock>
						<Title>{t('notFound.title')}</Title>
						<Description>{t('notFound.description')}</Description>
						<Link to="/">
							<Button color={'blue'}>{t('notFound.return')}</Button>
						</Link>
					</MainBlock>
					<BigNumbers>404</BigNumbers>
				</Container>
			</Content>
		</Layout>
	);
};

const MainBlock = styled.div`
	margin-top: 74px;
`;

const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 1129px;
	height: 480px;
	margin: 40px auto 0;
`;

const Title = styled.div`
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 40px;
	font-weight: bold;
`;

const Description = styled.div`
	width: 275px;
	font-size: 12px;
	margin-bottom: 72px;
	line-height: 16px;
`;

const Button = styled(BlockButton)`
	width: 145px;
	padding: 10px 10px;
`;

const BigNumbers = styled.div`
	font-size: 400px;
	font-weight: 300;
	letter-spacing: -20px;
	margin-right: 20px;
	color: #f5f5f5;
`;

const Layout = styled.div`
	@import '@ea/common/dist/constants.scss';
	background-color: white;
	min-width: $large-desktop-width;
`;

const Content = styled.div``;
