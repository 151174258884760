import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialContentLoading, ParametrizedContentLoading } from '@ea/common';
import { Person } from '../../../../service/typings/entites';
import { PersonDetailsActions } from './actions';

export interface PersonDetailState {
	data: ParametrizedContentLoading<Person | undefined>;
}

const initialState: PersonDetailState = {
	data: initialContentLoading(undefined, {}),
};

export const PersonDetailReducer = reducerWithInitialState(initialState)
	.case(PersonDetailsActions.getPerson.started, (state, { isUpdate }) => ({
		...state,
		data: {
			...state.data,
			content: isUpdate ? state.data.content : undefined,
			loading: isUpdate
				? state.data.loading
				: {
						type: 'full',
						status: 'loading',
				  },
		},
	}))
	.case(PersonDetailsActions.getPerson.done, (state, { result, params }) => ({
		...state,
		data: {
			content: result._embedded.persons[0],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
		},
	}))
	.case(PersonDetailsActions.getPerson.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: undefined,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))

	.case(PersonDetailsActions.patchPerson.done, (state, { result, params }) => ({
		...state,
		data: {
			content: {
				...(state.data.content as Person),
				fio: result.fio,
			},
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
		},
	}));
