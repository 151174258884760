import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { range } from 'lodash-es';
import showPasswordIcon from '../../images/icons/show-password.svg';
import pulseIcon from '../../images/indicators/pulse.svg';

interface IProps extends ICellRendererParams {
	value: string;
	data: any;
	hidePassword: () => Promise<any>;
	showPassword: () => Promise<any>;
}

export class PasswordRenderer implements ICellRenderer {
	element!: HTMLElement;
	circles!: HTMLElement[];
	content!: HTMLElement;
	iconWrapper!: HTMLElement;
	icon!: HTMLElement;
	pulse!: HTMLElement;
	passwordShowed!: boolean;

	public updateContent(value: string | undefined) {
		if (value) {
			this.content.innerHTML = value;
		} else {
			this.content.innerHTML = '';
			this.circles.forEach((circle) => {
				this.content.appendChild(circle);
			});
		}
	}

	public init(params: IProps) {
		const { value, showPassword, hidePassword } = params;

		this.passwordShowed = Boolean(value);
		this.content = document.createElement('div');
		this.content.setAttribute('class', 'ag-grid-password-cell__content');

		this.circles = range(4).map(() => {
			const circle = document.createElement('div');
			circle.setAttribute('class', 'ag-grid-password-cell__circle');
			return circle;
		});

		this.updateContent(value);

		this.icon = document.createElement('img');
		this.icon.setAttribute('class', 'ag-grid-password-cell__icon');
		this.icon.setAttribute('src', showPasswordIcon);

		this.pulse = document.createElement('img');
		this.pulse.setAttribute('class', 'ag-grid-password-cell__pulse ag-grid-password-cell__pulse--hidden');
		this.pulse.setAttribute('src', pulseIcon);

		this.iconWrapper = document.createElement('div');
		this.iconWrapper.setAttribute('class', 'ag-grid-password-cell__icon-wrapper');

		this.iconWrapper.appendChild(this.icon);
		this.iconWrapper.appendChild(this.pulse);
		this.iconWrapper.addEventListener('click', () => {
			if (!this.passwordShowed) {
				if (showPassword) {
					this.pulse && this.pulse.classList.remove('ag-grid-password-cell__pulse--hidden');
					this.icon && this.icon.classList.add('ag-grid-password-cell__icon--hidden');
					showPassword().finally(() => {
						this.icon && this.icon.classList.remove('ag-grid-password-cell__icon--hidden');
						this.pulse && this.pulse.classList.add('ag-grid-password-cell__pulse--hidden');
					});
				}
			} else {
				hidePassword && hidePassword();
			}
		});

		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-password-cell');
		this.element.appendChild(this.content);
		this.element.appendChild(this.iconWrapper);
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		const { value } = params;
		this.passwordShowed = Boolean(value);
		this.updateContent(value);

		return true;
	}
}
