import { actionCreatorFactory } from 'typescript-fsa';
import { GetAdministrationPersonRequest, GetAdministrationPersonResponse } from './typings';

const ActionFactory = actionCreatorFactory('ADMINISTRATION_PAGE');

export const AdministrationPageActions = {
	getPerson: ActionFactory.async<GetAdministrationPersonRequest, GetAdministrationPersonResponse, Error>(
		'GET_PERSON',
	),
	clearPerson: ActionFactory('CLEAR_PERSON'),
};
