import React, { FC, useRef } from 'react';
import styled from 'astroturf';
import { Popover, PopoverProps } from '@material-ui/core';
import useOnClickOutside from 'use-onclickoutside';
import { ClassifierSelectMenuContent } from '@ea/common';
import { GridReference } from '../../../service/typings/grid';

interface IProps extends Omit<PopoverProps, 'anchorEl' | 'onClose' | 'onSelect'> {
	open: boolean;
	showCount?: boolean;
	withColors?: boolean;
	items: GridReference[];
	anchor: React.RefObject<HTMLElement>;
	onClose: () => void;
	onSelect: (id: number) => void;
	onRecolor: (id: number, color: string) => void;
	onRemove: (id: number) => void;
	onCreate: (item: GridReference) => Promise<any>;
	onExited: () => void;
	removable?: boolean;
	addable?: boolean;
}

export const REFERENCE_POPUP_APPEAR_TIME = 300;

export const ClassifierPopup: FC<IProps> = ({
	anchor,
	onCreate,
	onRecolor,
	onRemove,
	onClose,
	onSelect,
	items,
	withColors,
	open,
	removable,
	addable,
	showCount,
	...props
}) => {
	const body = useRef<HTMLDivElement>(null);
	useOnClickOutside(body, () => {
		onClose();
	});
	return (
		<Popover
			transitionDuration={REFERENCE_POPUP_APPEAR_TIME}
			open={open}
			anchorEl={anchor.current}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			{...props}
		>
			<PopupBody ref={body}>
				<PopupContent>
					<ClassifierSelectMenuContent
						withColors={withColors}
						onCreate={onCreate}
						onSelect={onSelect}
						onRecolor={onRecolor}
						onRemove={onRemove}
						removable={removable}
						addable={addable}
						showCount={showCount}
						items={items}
					/>
				</PopupContent>
			</PopupBody>
		</Popover>
	);
};

const PopupContent = styled.div``;

const PopupBody = styled.div`
	background: white;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	padding: 15px 12px 19px;
	max-height: 250px;
`;
