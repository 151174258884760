import { SelectItem } from '../typings/interface';

export const transformDataToSelectList = (data: any): SelectItem[] => {
	if (Array.isArray(data)) {
		return data.map(({ id, companyId, functionId, questionId, name, label, value, ...rest }) => {
			return {
				label: name || label,
				value: id || companyId || functionId || questionId || value,
				...rest,
			};
		});
	} else if (typeof data === 'object' && typeof data._embedded === 'object') {
		Object.keys(data._embedded).forEach((key) => {
			if (Array.isArray(data._embedded[key])) {
				return data._embedded[key].map(
					({ id, companyId, functionId, questionId, name, label, value, ...rest }: any) => ({
						label: name || label,
						value: id || companyId || functionId || questionId || value,
						...rest,
					}),
				);
			}
		});
	}
	return [];
};
