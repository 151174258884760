import { useCallback, useEffect, useRef } from 'react';
import { useForceUpdate } from './useForceUpdate';

export function useAfterRender(callback: Function) {
	const called = useRef(false);
	const args = useRef<any[]>([]);
	const forceUpdate = useForceUpdate();
	useEffect(() => {
		if (called.current) {
			callback(...args.current);
			called.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [called.current]);

	return useCallback(
		(...params: any[]) => {
			called.current = true;
			args.current = params;
			forceUpdate();
		},
		[callback],
	);
}
