import React, { memo, FC, useCallback, ChangeEvent } from 'react';
import styled from 'astroturf';
import { Checkbox } from '../Checkbox';
import { ListItem } from '../../../../service/typings/interface';
import { TextEllipsisWithTooltip } from '../../../service/TextEllipsisWithTooltip';
import { Ripple } from '../../../service/Ripple';

interface Props {
	label: ListItem['label'];
	value?: ListItem['value'];
	onChange?: (item: ListItem, checked: boolean) => void;
	checked?: boolean;
}

export const CustomSelectItem: FC<Props> = memo(({ value, label, onChange, checked }) => {
	const onChangeInternal = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				onChange({ label, value }, event.target.checked);
			}
		},
		[label, value, onChange],
	);

	return (
		<Ripple>
			<Item htmlFor={label}>
				<Checkbox id={label} onChange={onChangeInternal} checked={checked} />
				<Label>{label}</Label>
			</Item>
		</Ripple>
	);
});

const Item = styled.label`
	@import '../../../../styles/constants.scss';
	text-align: left;
	font-size: 12px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 4px 7px 0;
	white-space: nowrap;
	cursor: pointer;
	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
`;

const Label = styled(TextEllipsisWithTooltip)`
	margin-left: 4px;
`;
