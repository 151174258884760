import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { DateTime } from 'luxon';

interface IProps extends ICellRendererParams {
	value: string;
	dateFormat: string;
}

export class DateRenderer implements ICellRenderer {
	element!: HTMLElement;

	public init(params: IProps) {
		this.element = document.createElement('div');
		this.element.setAttribute('class', 'ag-grid-with-padding-cell ag-grid-centered-cell');
		this.render(params);
	}

	public render(params: IProps) {
		const { dateFormat = 'dd.MM.yyyy' } = params;
		this.element.innerHTML = params.value ? DateTime.fromISO(params.value).toFormat(dateFormat) : '';
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
