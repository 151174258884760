import React, { FC, memo, ReactNode, useCallback } from 'react';
import styled from 'astroturf';
import { ReactComponent as WarningIcon } from '../../images/icons/warning.svg';
import { ReactComponent as TimerIcon } from '../../images/icons/timer.svg';
import { Tooltiped } from '../service/Tooltiped';

export interface WorkerTab {
	label: string;
	value: string;
	lowLevel?: number;
	expired?: number;
	lowLevelTooltip?: string;
	expiredTooltip?: string;
}

interface Props {
	tabs: WorkerTab[];
	onChange?: (value: string) => void;
	className?: string;
	active?: string;
}

export const WorkerTabs: FC<Props> = memo(({ tabs, active, onChange, className }) => {
	const onChangeActive = useCallback(
		(value: string) => () => {
			onChange && onChange(value);
		},
		[onChange],
	);

	return (
		<Wrapper className={className}>
			<Tabs>
				{tabs.map((tab) => (
					<Tab key={tab.value} active={tab.value === active} onClick={onChangeActive(tab.value)}>
						{tab.label}
						{!!tab.expired && (
							<Tooltiped show={true} text={tab.expiredTooltip}>
								<IconWrapper first={true}>
									<TimerIcon />
									<Count color={'orange'}>{tab.expired}</Count>
								</IconWrapper>
							</Tooltiped>
						)}
						{!!tab.lowLevel && (
							<Tooltiped show={true} text={tab.lowLevelTooltip}>
								<IconWrapper first={!tab.expired}>
									<WarningIcon />
									<Count color={'red'} type={'warning'}>
										{tab.lowLevel}
									</Count>
								</IconWrapper>
							</Tooltiped>
						)}
					</Tab>
				))}
			</Tabs>
		</Wrapper>
	);
});

const Tabs = styled.div`
	display: flex;
	height: 35px;
	position: relative;
`;

const Tab = styled.div<{ active?: boolean }>`
	@import '../../styles/constants.scss';
	@import '../../styles/mixins.scss';
	@include transition();
	color: $color-black;
	height: 35px;
	font-size: 12px;
	font-weight: bold;
	padding: 7px 32px;
	line-height: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	white-space: nowrap;
	&.active {
		border-radius: 4px 4px 0 0;
		background: #f5f5f5;
	}
`;

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

const IconWrapper = styled.span<{ first: boolean }>`
	display: flex;
	margin-left: 1px;
	&.first {
		margin-left: 9px;
	}
`;
const Count = styled.div<{ color?: string; type?: string }>`
	font-size: 10px;
	font-weight: bold;
	top: -7px;
	position: relative;
	border-radius: 8px;
	padding: 2px 4px 0px;
	height: 14px;
	min-width: 14px;
	margin-left: 2px;
	line-height: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	&.color-red {
		background-color: #e57373;
	}
	&.color-orange {
		background-color: #ef6c02;
	}
	&.type-warning {
		margin-left: -2px;
	}
`;
