import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer } from 'ag-grid-community';

export class JSXRenderer implements ICellRenderer {
	params: any;
	parentElement!: HTMLElement;
	value: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-all-centered-cell';
	}
}

const Renderer = ({ disable, jsx }: any) => {
	return <Wrapper disabled={disable}>{jsx}</Wrapper>;
};

const Wrapper = styled.div<{ disabled?: boolean }>`
	min-height: 100%;
	min-width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.disabled {
		opacity: 0;
	}
`;
