import React, { FC, useCallback, useRef, useState, ChangeEvent, MouseEvent, memo } from 'react';
import { isArray } from 'lodash-es';
import styled from 'astroturf';
import Cleave from 'cleave.js/react';
import { PopperPlacementType } from '@material-ui/core';
import { Calendar } from '../../service/Calendar';
import { formattedDate, formattedDateToISO } from '../../../service/utils/date';
import { SelectMenu } from './SelectMenu';

export interface IDatePickerProps {
	name?: string;
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	disablePortal?: boolean;
	className?: string;
	touched?: boolean;
	onChange?: (value: string) => void;
	onBlur?: (e: React.FocusEvent<any>) => void;
	invalid?: boolean;
	placement?: PopperPlacementType;
}

export const InlineDatePicker: FC<IDatePickerProps> = memo(
	({ name, className, placeholder, disabled, disablePortal, onChange, onBlur, value, invalid, placement }) => {
		const [open, setOpen] = useState(false);

		const container = useRef<HTMLDivElement>(null);

		const formattedValue = value && formattedDate(value);

		const [internalValue, setInternalValue] = useState(formattedValue);

		const [focus, setFocus] = useState(false);

		const onOpen = useCallback(() => {
			if (!disabled) {
				setOpen(true);
			}
		}, [setOpen, disabled]);

		const onClose = () => {
			setOpen(false);
			setFocus(false);
			onUpdate();
		};

		const onUpdate = () => {
			const date = internalValue && formattedDateToISO(internalValue);
			if (date && onChange) {
				onChange(date);
			}
		};

		const onChangeInternal = (event: ChangeEvent) => {
			setInternalValue((event.target as HTMLInputElement).value);
		};

		const onSelectInternal = (value: string | [string, string]) => {
			if (!isArray(value)) {
				setInternalValue(value);
			}
		};

		const onFocusInternal = () => {
			setFocus(true);
			setInternalValue(formattedValue);
			onOpen();
		};

		const onBlurInternal = (event: React.FocusEvent<HTMLInputElement>) => {
			if (onBlur) {
				onBlur(event);
			}
			onClose();
		};

		const onSelectMenuClick = (event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<Wrapper ref={container} className={className}>
				<Input
					invalid={!disabled && invalid}
					disabled={disabled}
					name={name}
					value={(focus ? internalValue : formattedValue) || ''}
					onFocus={onFocusInternal}
					onBlur={onBlurInternal}
					onChange={onChangeInternal}
					placeholder={placeholder}
					options={{
						date: true,
						delimiter: '.',
						datePattern: ['d', 'm', 'Y'],
					}}
				/>
				<SelectMenu
					placement={placement}
					onClickOutside={onClose}
					anchor={container}
					disablePortal={disablePortal}
				>
					<CalendarWrapper onMouseDown={onSelectMenuClick}>
						<Calendar
							immediately={true}
							hideButton={true}
							format={'dd.MM.yyyy'}
							onChange={onSelectInternal}
							value={formattedValue}
						/>
					</CalendarWrapper>
				</SelectMenu>
			</Wrapper>
		);
	},
);

const Wrapper = styled.div`
	position: relative;
	height: 24px;
`;

const Input = styled(Cleave)<{ invalid?: boolean }>`
	width: 100%;
	height: 100%;
	font-size: 12px;
	border: solid 1px #ededed;
	padding-top: 1px;
	padding-left: 10px;
	padding-right: 6px;
	transition: border 0.5s;
	background: white;
	&.invalid {
		border-color: red;
	}
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
	}
`;

const CalendarWrapper = styled.div``;
