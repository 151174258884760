export function clearArray<T>(obj: T[]) {
	return obj.filter((el) => el);
}

import { GridColumn, GridSort } from './typings/grid';

export function addSorting(columns: GridColumn[], options?: GridSort) {
	if (options) {
		return columns.map((column) => ({
			...column,
			sortingActive: options.field === column.field,
			sortingDirection: options.field === column.field ? options.order : 'asc',
		}));
	}
	return columns;
}
