import React, { CSSProperties, FC, MouseEvent } from 'react';
import { Button, ButtonProps, withStyles } from '@material-ui/core';
import styled from 'astroturf';
import { colors } from '../../../service/material-ui';

interface Props {
	className?: string;
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	type?: 'button' | 'reset' | 'submit';
	style?: CSSProperties;
}

export const IconButton: FC<Props> = ({ onClick, disabled = false, className = '', type, style, children }) => {
	return (
		<StyledButton className={className} disabled={disabled} onClick={onClick} type={type} style={style}>
			{children}
		</StyledButton>
	);
};

const StyledButton = withStyles({
	root: {
		cursor: 'pointer',
		flex: '0 0 auto',
		boxShadow: 'none',
		borderRadius: '50%',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		padding: '8px',
		minWidth: '0',
		fill: colors.blue,
		'&:disabled': {
			opacity: 0.33,
		},
	},
})(({ classes = {}, className, ...other }: ButtonProps) => (
	<FixedPaddingButton {...other} className={`${classes.root} ${className}`} />
));

const FixedPaddingButton = styled(Button)`
	padding: 8px !important;
`;
