import {
	ContentLoading,
	LoadingData,
	PaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '../../service/typings/store';

export function initialContentLoading<T>(value: T): ContentLoading<T>;
export function initialContentLoading<T, P>(value: T, params: P): ParametrizedContentLoading<T, P>;
export function initialContentLoading<T, P>(value?: T, params?: P) {
	const result = {
		content: value,
		loading: initialLoadingData(),
		error: undefined,
	} as ContentLoading<T>;

	if (params) {
		return {
			...result,
			params,
		};
	} else {
		return result;
	}
}

export function initialPaginatedContentLoading<T>(value: T): PaginatedContentLoading<T>;
export function initialPaginatedContentLoading<T, P>(value: T, params: P): PaginatedParametrizedContentLoading<T, P>;
export function initialPaginatedContentLoading<T, P>(value?: T, params?: P) {
	const result = {
		...initialContentLoading(value, params),
		pagination: {
			size: 0,
			number: 0,
		},
	} as PaginatedContentLoading<T>;

	if (params) {
		return {
			...result,
			params,
		};
	} else {
		return result;
	}
}

export const initialLoadingData = (): LoadingData => ({
	status: 'init',
	type: 'full',
});
