import { Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'astroturf';

export interface IProps {
	show?: boolean;
	text?: string;
}

export const Tooltiped: FC<IProps> = ({ show, text, children }) => {
	return (
		<>
			{show ? (
				<Tooltip title={(text || children) as string} leaveTouchDelay={300}>
					<Wrapper>{children}</Wrapper>
				</Tooltip>
			) : (
				children
			)}
		</>
	);
};

const Wrapper = styled.span``;
