import React, { FC, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@ea/common';
import { GridPopup, POPUP_APPEAR_TIME } from '../popups/GridPopup';
import { ReactComponent as AddButton } from '../../images/icons/add-column.svg';
import { ReactComponent as CloseButton } from '../../images/icons/add-column-cancel.svg';
import { ReactComponent as NumberIcon } from '../../images/icons/columns/number.svg';
import { ReactComponent as TextIcon } from '../../images/icons/columns/string.svg';
import { ReactComponent as ClassificationIcon } from '../../images/icons/add-classification-column.svg';
import { GridColumn } from '../../service/typings/grid';

interface IProps extends ICellRendererParams {
	onColumnAdded: (type: string) => void;
	onColumnShowed: (type: string, options: any) => void;
}

const Cell: FC<IProps> = ({ onColumnAdded, onColumnShowed, columnApi }) => {
	const { t } = useTranslation();

	const [opened, setOpened] = useState(false);

	const closePopup = useCallback(() => {
		setOpened(false);
	}, [setOpened]);

	const togglePopup = useCallback(() => {
		setOpened(!opened);
	}, [opened, setOpened]);

	const onSelect = useCallback(
		({ value }: ListItem) => {
			closePopup();
			setTimeout(() => {
				if (value.action === 'add') {
					if (onColumnAdded) {
						onColumnAdded(value.type);
					}
				} else {
					if (onColumnShowed) {
						onColumnShowed(value.field, {
							hidden: false,
						});
					}
				}
			}, POPUP_APPEAR_TIME + 100);
		},
		[onColumnShowed, onColumnAdded, closePopup],
	);

	const container = useRef<HTMLDivElement>(null);

	const columns: GridColumn[] = columnApi.getAllColumns().map((col: any) => col.colDef);

	return (
		<>
			<ButtonWrapper onClick={togglePopup} ref={container}>
				{opened ? <CloseButton /> : <AddButton />}
			</ButtonWrapper>
			<GridPopup
				open={opened}
				onSelect={onSelect}
				onClose={closePopup}
				anchorEl={container.current}
				items={[
					{
						value: {
							action: 'add',
							type: 'STRING',
						},
						label: t('grid.addTextColumn'),
						icon: <TextIcon />,
					},
					{
						value: {
							action: 'add',
							type: 'NUMBER',
						},
						label: t('grid.addNumberColumn'),
						icon: <NumberIcon />,
					},
					{
						value: {
							action: 'add',
							type: 'REFERENCE',
						},
						label: t('grid.addClassificationColumn'),
						icon: <ClassificationIcon />,
					},
				]}
				additionalItems={columns
					.filter((el) => el.hide)
					.map((el: GridColumn) => ({
						value: {
							action: 'setVisible',
							field: el.field,
						},
						label: el.headerName,
						key: el.field,
					}))}
			/>
		</>
	);
};

export class AddColumnRenderer implements ICellRenderer {
	parentElement!: HTMLElement;

	public init(params: IProps) {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
		this.render(params);
	}

	public render(params: IProps) {
		ReactDOM.render(<Cell {...params} />, this.parentElement);
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}
}

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 100%;
	width: 100%;
	position: relative;
`;
