import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialPaginatedContentLoading, PaginatedParametrizedContentLoading } from '@ea/common';
import { AdministrationPerson } from '../../../../service/typings/entites';
import { AdministrationPageActions } from './actions';

export interface AdministrationPageState {
	data: PaginatedParametrizedContentLoading<AdministrationPerson | null>;
}

const initialState: AdministrationPageState = {
	data: initialPaginatedContentLoading(null, {}),
};

export const AdministrationPageReducer = reducerWithInitialState(initialState)
	.case(AdministrationPageActions.clearPerson, (state) => ({
		...state,
		data: {
			...state.data,
			content: null,
			loading: {
				status: 'init',
				type: 'full',
			},
		},
	}))
	.case(AdministrationPageActions.getPerson.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				status: 'loading',
				type: 'full',
			},
		},
	}))
	.case(AdministrationPageActions.getPerson.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: result._embedded.administration[0],
			loading: {
				status: 'loaded',
				type: 'full',
			},
		},
	}))
	.case(AdministrationPageActions.getPerson.failed, (state) => ({
		...state,
		data: {
			...state.data,
			content: null,
			loading: {
				status: 'init',
				type: 'full',
			},
		},
	}));
