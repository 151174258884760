import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import styled from 'astroturf';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { Checkbox } from '@ea/common';

interface IProps extends ICellRendererParams {
	onSelectAll?: () => void;
	getStatistics?: () => {
		selected: number;
		total: number;
	};
}

const Cell: FC<IProps> = ({ onSelectAll, getStatistics }) => {
	const statistics = getStatistics?.();

	const indeterminate = statistics ? statistics.selected > 0 && statistics.selected !== statistics.total : false;
	const checked = statistics ? statistics.selected > 0 && statistics.selected === statistics.total : false;
	return (
		<CheckboxWrapper>
			<Checkbox indeterminate={indeterminate} checked={checked} onChange={() => onSelectAll?.()} />
		</CheckboxWrapper>
	);
};

export class CheckboxHeaderRenderer implements ICellRenderer {
	parentElement!: HTMLElement;

	public init(params: IProps) {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered ag-grid-all-centered-cell';
		this.render(params);
	}

	public render(params: IProps) {
		ReactDOM.render(<Cell {...params} />, this.parentElement);
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}
}

const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	&.disabled {
		cursor: not-allowed;
	}
`;
