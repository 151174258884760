import { actionCreatorFactory } from 'typescript-fsa';
import { Dict } from '../../../../core/types';
import {
	AddColumnRequest,
	AddColumnResponse,
	EditColumnRequest,
	EditColumnResponse,
	GetColumnsRequest,
	GetColumnsResponse,
	HideColumnRequest,
	HideColumnResponse,
	RemoveColumnRequest,
	RemoveColumnResponse,
	RenameColumnRequest,
	RenameColumnResponse,
	ReorderColumnsRequest,
	ReorderColumnsResponse,
	ShowColumnRequest,
	ShowColumnResponse,
	UpdateColumns,
} from '../../../../service/typings/actions/columns';
import {
	AddClassifierRequest,
	AddClassifierResponse,
	GetClassifierRequest,
	GetClassifierResponse,
} from '../../../../service/typings/actions/classifier';
import { UpdateSelection } from '../../../../service/typings/actions/selection';
import { GetStatisticsRequest, GetStatisticsResponse } from '../../../../service/typings/actions/statistics';
import {
	GetFiltersRequest,
	GetFiltersResponse,
	UpdateFiltersRequest,
	UpdateFiltersResponse,
} from '../../../../service/typings/actions/filters';
import { RememberScrollPosition } from '../../../../service/typings/actions/grid';
import { GetQuestionsListRequest, GetQuestionsListResponse, RemoveQuestionImage, UpdateQuestion } from './typings';

const ActionFactory = actionCreatorFactory('QUESTIONS_LIST');

export const QuestionsListActions = {
	getList: ActionFactory.async<GetQuestionsListRequest, GetQuestionsListResponse, Error>('GET_LIST'),
	loadMore: ActionFactory.async<GetQuestionsListRequest, GetQuestionsListResponse, Error>('LOAD_MORE'),
	getColumns: ActionFactory.async<GetColumnsRequest, GetColumnsResponse, Error>('LOAD_COLUMNS'),
	reorderColumns: ActionFactory.async<ReorderColumnsRequest, ReorderColumnsResponse, Error>('REORDER_COLUMNS'),
	updateColumns: ActionFactory<UpdateColumns>('UPDATE_COLUMNS'),
	updateQuestion: ActionFactory<UpdateQuestion>('UPDATE_ROW'),
	editColumn: ActionFactory.async<EditColumnRequest, EditColumnResponse, Error>('EDIT_COLUMN'),
	hideColumn: ActionFactory.async<HideColumnRequest, HideColumnResponse, Error>('HIDE_COLUMN'),
	renameColumn: ActionFactory.async<RenameColumnRequest, RenameColumnResponse, Error>('RENAME_COLUMN'),
	removeColumn: ActionFactory.async<RemoveColumnRequest, RemoveColumnResponse, Error>('REMOVE_COLUMN'),
	addColumn: ActionFactory.async<AddColumnRequest, AddColumnResponse, Error>('ADD_COLUMN'),
	showColumns: ActionFactory.async<ShowColumnRequest, ShowColumnResponse, Error>('SHOW_COLUMNS'),
	getClassifiers: ActionFactory.async<GetClassifierRequest, GetClassifierResponse, Error>('GET_CLASSIFIERS'),
	addClassifier: ActionFactory.async<AddClassifierRequest, AddClassifierResponse, Error>('ADD_CLASSIFIER'),
	setChanged: ActionFactory('SET_CHANGED'),
	rememberParams: ActionFactory<Dict>('REMEMBER_PARAMS'),
	// Selection
	updateSelection: ActionFactory<UpdateSelection>('UPDATE_SELECTION'),
	getStatistics: ActionFactory.async<GetStatisticsRequest, GetStatisticsResponse, Error>('GET_STATISTICS'),
	// Filters
	getFilters: ActionFactory.async<GetFiltersRequest, GetFiltersResponse, Error>('GET_FILTERS'),
	updateFilters: ActionFactory.async<UpdateFiltersRequest, UpdateFiltersResponse, Error>('UPDATE_FILTERS'),
	// Scroll
	rememberScroll: ActionFactory<RememberScrollPosition>('REMEMBER_SCROLL'),
	resetParams: ActionFactory('RESET_PARAMS'),
	deleteImage: ActionFactory<RemoveQuestionImage>('DELETE_IMAGE'),
};
