import React, { memo, FC, useCallback } from 'react';
import styled from 'astroturf';
import { ReactComponent as EditIcon } from '../../../../images/icons/grid/edit-column.svg';

interface IProps {
	id: number;
	onClick?: (id: number) => void;
}

export const EditIconSection: FC<IProps> = memo(({ id, onClick }) => {
	const handleClick = useCallback(
		(event: React.MouseEvent<any>) => {
			event.preventDefault();
			event.stopPropagation();
			onClick && onClick(id);
		},
		[id, onClick],
	);
	return (
		<Wrap>
			<StyledEditIcon onClick={handleClick} />
		</Wrap>
	);
});

const Wrap = styled.div`
	margin-left: auto;
`;
const StyledEditIcon = styled(EditIcon)`
	margin-left: 20px;
	float: right;
`;
