import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LoadingStatus } from '@ea/common';
import { Timezone } from '../../../../service/typings/entites';
import { TimezoneActions } from './actions';

export interface TimezonesState {
	data: Timezone[];
	status: LoadingStatus;
}

const initialState = (): TimezonesState => ({
	data: [],
	status: 'init',
});

export const TimezonesReducer = reducerWithInitialState(initialState())
	.case(TimezoneActions.get.started, () => ({
		data: [],
		status: 'loading',
	}))
	.case(TimezoneActions.get.done, (state, payload) => ({
		data: payload.result,
		status: 'loaded',
	}))
	.case(TimezoneActions.get.failed, (state) => ({
		...state,
		status: 'init',
	}));
