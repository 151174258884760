import React, { CSSProperties, FC, MouseEvent } from 'react';
import { Button, ButtonProps, withStyles } from '@material-ui/core';
import styled from 'astroturf';
import { CircleSpinner } from '../indicators/CircleSpinner';
import { HideableContent } from '../../service/HideableContent';
import { colors, styledBy } from '../../../service/material-ui';

interface Props {
	color?: 'blue' | 'transparentBlue' | 'red';
	size?: 'medium' | 'large';
	className?: string;
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	type?: 'button' | 'reset' | 'submit';
	style?: CSSProperties;
	loading?: boolean;
}

export const BlockButton: FC<Props> = ({
	onClick,
	color = 'transparentBlue',
	size = 'medium',
	disabled = false,
	className = '',
	type,
	style,
	children,
	loading,
}) => {
	return (
		<StyledButton
			color={color}
			size={size}
			className={className}
			disabled={disabled}
			onClick={onClick}
			type={type}
			style={style}
		>
			<SpinWrap visible={loading}>
				<CircleSpinner />
			</SpinWrap>
			<HideableContent visible={!loading}>{children}</HideableContent>
		</StyledButton>
	);
};

const SpinWrap = styled(HideableContent)`
	position: absolute;
	display: block;
	height: 32px;
	width: 32px;
`;

const StyledButton = withStyles({
	root: {
		position: 'relative',
		letterSpacing: 'normal',
		fontFamily: 'lato, -apple-system, Roboto, BlinkMacSystemFont, "Segoe UI"',
		lineHeight: '10px',
		fontWeight: 600,
		boxSizing: 'border-box',
		cursor: 'pointer',
		textDecoration: 'none',
		flex: '0 0 auto',
		boxShadow: 'none',
		borderRadius: '4px',
		textAlign: 'center',
		minWidth: '0',
		transition: 'opacity 0.5s',
		transitionDelay: '0.5s',
		background: styledBy('color', {
			blue: colors.blue,
			red: colors.red,
			transparentBlue: colors.transparent,
		}),
		// TODO: уточнить какой должен быть padding
		padding: styledBy('size', {
			medium: '10px 12px',
		}),
		textTransform: styledBy('size', {
			medium: 'none',
		}),
		fontSize: styledBy('size', {
			medium: '12px',
		}),
		color: styledBy('color', {
			blue: colors.white,
			red: colors.white,
			transparentBlue: colors.blue,
		}),
		border: styledBy('color', {
			blue: `1px solid ${colors.blue}`,
			transparentBlue: `1px solid ${colors.blue}`,
			red: `1px solid ${colors.red}`,
		}),
		'&:hover': {
			backgroundColor: styledBy('color', {
				blue: colors.blue,
				red: colors.red,
				transparentBlue: colors.transparent,
			}),
			color: styledBy('color', {
				blue: colors.white,
				red: colors.white,
				transparentBlue: colors.blue,
			}),
			border: styledBy('color', {
				blue: `1px solid ${colors.blue}`,
				transparentBlue: `1px solid ${colors.blue}`,
			}),
		},
		'&:disabled': {
			backgroundColor: styledBy('color', {
				blue: colors.blue,
				red: colors.red,
				transparentBlue: colors.transparent,
			}),
			borderColor: styledBy('color', {
				blue: colors.blue,
				red: colors.red,
				transparentBlue: colors.white,
			}),
			opacity: styledBy('color', {
				blue: 0.33,
				red: 0.33,
				transparentBlue: 0.33,
			}),
			color: styledBy('color', {
				blue: colors.white,
				red: colors.white,
				transparentBlue: colors.blue,
			}),
		},
	},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
})(({ classes = {}, color, size, className, ...other }: ButtonProps) => (
	<Button {...other} className={`${classes.root} ${className}`} />
));
