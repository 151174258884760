import React, { FC, useCallback, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { BlockButton } from '../buttons/BlockButton';
import { SmallModal } from './SmallModal';

interface Props {
	open?: boolean;
	onClose?: () => void;
	onDestroy?: () => void;
	onSubmit?: () => unknown;
	title?: string;
	question?: string;
	explanation?: string;
	submitText?: string;
	cancelText?: string;
}

export const ConfirmationModal: FC<Props> = ({
	open,
	onClose,
	onSubmit,
	onDestroy,
	title,
	question,
	explanation,
	submitText,
	cancelText,
}) => {
	const [sending, setSending] = useState(false);

	const { t } = useTranslation();

	const submit = useCallback(() => {
		if (onSubmit) {
			setSending(true);
			Promise.resolve(onSubmit())
				.then(onClose)
				.finally(() => {
					setSending(false);
				});
		}
	}, [onSubmit, onClose, setSending]);

	return (
		<SmallModal open={open} onClose={onClose} onClosed={onDestroy}>
			<Content>
				<Title>{title || t('confirmation.title')}</Title>
				<Question>{question}</Question>
				{explanation && <Explanation>{explanation}</Explanation>}
				<Buttons>
					<ButtonWrapper>
						<BlockButton color="transparentBlue" onClick={onClose}>
							{cancelText || t('actions.cancel')}
						</BlockButton>
					</ButtonWrapper>
					<ButtonWrapper>
						<BlockButton color="blue" disabled={sending} onClick={submit}>
							{submitText || t('actions.yes')}
						</BlockButton>
					</ButtonWrapper>
				</Buttons>
			</Content>
		</SmallModal>
	);
};

const ButtonWrapper = styled.div`
	margin-top: 52px;
	margin-right: 16px;
`;

const Buttons = styled.div`
	display: flex;
`;

const Explanation = styled.p`
	font-size: 12px;
	line-height: 14px;
	margin-top: 9px;
`;

const Question = styled.p`
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	margin-bottom: 17px;
	margin-top: 17px;
`;

const Title = styled.h1`
	font-size: 24px;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 36px;
`;

const Content = styled.div`
	width: 400px;
	padding: 40px 53px 53px 35px;
`;
