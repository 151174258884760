import React, { FC, useMemo } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { DateTime, Duration } from 'luxon';
import { WorkerProfile, WorkerSkill } from '../../service/typings/workers/profile';
import { formattedDate, plusMonths } from '../../service/utils/date';
import { ReactComponent as WarningIcon } from '../../images/icons/warning.svg';
import { ReactComponent as TimerIcon } from '../../images/icons/timer.svg';
import { ReactComponent as AddIcon } from '../../images/icons/actions/add.svg';
import { ReactComponent as RemoveIcon } from '../../images/icons/actions/delete.svg';
import { Tooltiped } from '../service/Tooltiped';
import { IconButton } from '../elements/buttons/IconButton';
import { TextButton } from '../elements/buttons/TextButton';
import { InlineDatePicker } from '../elements/fields/InlineDatePicker';
import { Dict } from '../../service/typings/basic';
import { WorkerTabs } from './WorkerTabs';
import { ClassificationLevel } from './ClassificationLevel';

interface Data {
	level?: number;
	dt?: string;
}

interface Props {
	profile: WorkerProfile;
	activeTab?: string;
	onChangeTab?: (value: string) => void;
	editing?: boolean;
	onValidateField?: (type: string, index: number, field: string) => boolean;
	onChangeField?: (type: string, index: number, field: string, value: unknown) => unknown;
	onBlurField?: (type: string, index: number, field: string) => unknown;
	onRemoveRow?: (type: string, index: number) => unknown;
	onAddRow?: (type: string) => unknown;
}

export const WorkerTable: FC<Props> = ({
	profile,
	editing,
	activeTab,
	onAddRow,
	onRemoveRow,
	onChangeField,
	onBlurField,
	onValidateField,
	onChangeTab,
}) => {
	const { t } = useTranslation();

	const tabs = useMemo(
		() => [
			{
				label: t('common.workerTable.tabs.skill1'),
				value: 'skill1',
				expired: profile.skill1.expired,
				lowLevel: profile.skill1.lowLevel,
				expiredTooltip: t('common.workerTable.tooltips.expiring'),
				lowLevelTooltip: t('common.workerTable.tooltips.lowLevel'),
				addButtonText: t('common.workerTable.words.addSkill'),
			},
			{
				label: t('common.workerTable.tabs.skill2'),
				value: 'skill2',
				expired: profile.skill2.expired,
				lowLevel: profile.skill2.lowLevel,
				expiredTooltip: t('common.workerTable.tooltips.expiring'),
				lowLevelTooltip: t('common.workerTable.tooltips.expired'),
				addButtonText: t('common.workerTable.words.addSkill'),
			},
			{
				label: t('common.workerTable.tabs.skill3'),
				value: 'skill3',
				expired: profile.skill3.expired,
				lowLevel: profile.skill3.lowLevel,
				expiredTooltip: t('common.workerTable.tooltips.expiring'),
				lowLevelTooltip: t('common.workerTable.tooltips.expired'),
				addButtonText: t('common.workerTable.words.addAccess'),
			},
		],
		[profile],
	);

	const tab = activeTab === 'skill1' || activeTab === 'skill2' || activeTab === 'skill3' ? activeTab : 'skill1';

	const rows: WorkerSkill[] = profile[tab].items;

	const onRemoveInternal = (type: string, id: number) => () => {
		if (onRemoveRow) {
			return onRemoveRow(type, id);
		}
	};

	const onAddInternal = (type: string) => () => {
		if (onAddRow) {
			onAddRow(type);
		}
	};

	const onChangeInternal = (type: string, index: number, field: string) => (value: unknown) => {
		if (onChangeField) {
			return onChangeField(type, index, field, value);
		}
	};

	const onBlurInternal = (type: string, index: number, field: string) => () => {
		if (onBlurField) {
			return onBlurField(type, index, field);
		}
	};

	const onValidateInternal = (type: string, index: number, field: string) => {
		return onValidateField ? onValidateField(type, index, field) : true;
	};

	const isExpired = (row: WorkerSkill) => {
		return row.expired;
	};

	const isLowLevel = (row: WorkerSkill) => {
		return row.lowLevel;
	};

	const isInvalid = (row: WorkerSkill) => {
		if (tab === 'skill1') {
			return row.level <= 2;
		} else {
			return isLowLevel(row);
		}
	};

	const renderIndicator = (row: WorkerSkill, expiredTooltip: string, lowLevelTooltip: string) => {
		if (isExpired(row) || isLowLevel(row)) {
			return (
				<Tooltiped show={true} text={isLowLevel(row) ? lowLevelTooltip : expiredTooltip}>
					<IndicatorWrapper>{isLowLevel(row) ? <WarningIcon /> : <TimerIcon />}</IndicatorWrapper>
				</Tooltiped>
			);
		}
	};

	const renderRow = (row: WorkerSkill, rowIndex: number) => {

		if (tab === 'skill1') {
			return (
				<>
					<Cell full>{row.name}</Cell>
					<Cell>
						<StyledClassificationLevel
							level={row.level}
							onChange={onChangeInternal(tab, rowIndex, 'level')}
							invalid={!onValidateInternal(tab, rowIndex, 'level')}
							editing={editing}
							simplified={editing}
						/>
					</Cell>
					{editing && (
						<RemoveCell>
							<IconButton onClick={onRemoveInternal(tab, rowIndex)}>
								<RemoveIcon />
							</IconButton>
						</RemoveCell>
					)}
				</>
			);
		} else if (tab === 'skill2') {
			const expiredDate = editing ? formattedDate(plusMonths(row.dt, row.monthCount)) : formattedDate(row.till);

			return (
				<>
					<Cell full>{row.name}</Cell>
					{editing && (
						<Cell>
							<StyledDatePicker
								value={row.dt}
								placement={'bottom'}
								onChange={onChangeInternal(tab, rowIndex, 'dt')}
								onBlur={onBlurInternal(tab, rowIndex, 'dt')}
								invalid={!onValidateInternal(tab, rowIndex, 'dt')}
							/>
						</Cell>
					)}
					<Cell>
						{expiredDate || t('common.workerTable.words.undefined')}
						{renderIndicator(
							row,
							t('common.workerTable.tooltips.expiring'),
							t('common.workerTable.tooltips.expired'),
						)}
					</Cell>
					<Cell green={!isLowLevel(row)}>
						{isLowLevel(row)
							? t('common.workerTable.words.secondSkillAbsence')
							: t('common.workerTable.words.secondSkillPresence')}
					</Cell>
					{editing && (
						<RemoveCell>
							<IconButton onClick={onRemoveInternal(tab, rowIndex)}>
								<RemoveIcon />
							</IconButton>
						</RemoveCell>
					)}
				</>
			);
		} else if (tab === 'skill3') {
			const expiredDate = editing ? formattedDate(plusMonths(row.dt, row.monthCount)) : formattedDate(row.till);

			return (
				<>
					<Cell full>{row.name}</Cell>
					{editing && (
						<Cell>
							<StyledDatePicker
								value={row.dt}
								placement={'bottom'}
								onChange={onChangeInternal(tab, rowIndex, 'dt')}
								onBlur={onBlurInternal(tab, rowIndex, 'dt')}
								invalid={!onValidateInternal(tab, rowIndex, 'dt')}
							/>
						</Cell>
					)}
					<Cell>
						{expiredDate || t('common.workerTable.words.undefined')}
						{renderIndicator(
							row,
							t('common.workerTable.tooltips.expiring'),
							t('common.workerTable.tooltips.expired'),
						)}
					</Cell>
					{editing && (
						<RemoveCell>
							<IconButton onClick={onRemoveInternal(tab, rowIndex)}>
								<RemoveIcon />
							</IconButton>
						</RemoveCell>
					)}
				</>
			);
		}
	};

	const renderHeader = () => {
		if (tab === 'skill1') {
			return (
				<>
					<HeaderCell>{t('common.workerTable.words.name')}</HeaderCell>
					<HeaderCell>{t('common.workerTable.words.level')}</HeaderCell>
					{editing && <HeaderCell />}
				</>
			);
		} else if (tab === 'skill2') {
			return (
				<>
					<HeaderCell>{t('common.workerTable.words.name')}</HeaderCell>
					{editing && <HeaderCell>{t('common.workerTable.words.classificationGranted')}</HeaderCell>}
					<HeaderCell>{t('common.workerTable.words.date')}</HeaderCell>
					<HeaderCell>{t('common.workerTable.words.presence')}</HeaderCell>
					{editing && <HeaderCell />}
				</>
			);
		} else if (tab === 'skill3') {
			return (
				<>
					<HeaderCell>{t('common.workerTable.words.name')}</HeaderCell>
					{editing && <HeaderCell>{t('common.workerTable.words.accessGranted')}</HeaderCell>}
					<HeaderCell>{t('common.workerTable.words.date')}</HeaderCell>
					{editing && <HeaderCell />}
				</>
			);
		}
	};

	const renderEmpty = () => {
		return (
			<Row>
				<EmptyCell colSpan={3}>{t('common.workerTable.words.empty')}</EmptyCell>
			</Row>
		);
	};

	const renderAddButton = () => {
		if (editing) {
			const description = tabs.find((el) => el.value === tab);
			if (description) {
				return (
					<AddButton icon={<AddIcon />} onClick={onAddInternal(description.value)}>
						{description.addButtonText}
					</AddButton>
				);
			}
		}
	};

	return (
		<Wrapper>
			<WorkerTabs tabs={tabs} active={tab} onChange={onChangeTab} />

			{rows && (
				<Table>
					<HeaderRow>{renderHeader()}</HeaderRow>
					{rows.length > 0
						? rows.map((row, rowIndex) => (
								<Row
									key={row.skillId}
									first={rowIndex === 0}
									last={rowIndex === rows.length - 1}
									red={isInvalid(row)}
								>
									{renderRow(row, rowIndex)}
								</Row>
						  ))
						: renderEmpty()}
				</Table>
			)}
			{renderAddButton()}
		</Wrapper>
	);
};

const Table = styled.table`
	margin-right: 9px;
	width: 100%;
	font-size: 12px;
	line-height: 19px;
	border-collapse: collapse;
	border-bottom: solid 1px #d9d9d9;
	overflow: hidden;
`;

const HeaderRow = styled.tr`
	margin-left: 16px;
	width: 100%;
	background-color: #f5f5f5;
	border: solid 1px #f5f5f5;
`;

const Row = styled.tr<{ first?: boolean; last?: boolean; red?: boolean }>`
	border: solid 1px #d9d9d9;
	transition: background 0.5s;
	&.first {
		border-top: none;
	}
	&.last {
		border-bottom: none;
	}
	&.red {
		background: #ffe6e6;
	}
`;

const Cell = styled.td<{ full?: boolean; green?: boolean; blocked?: boolean }>`
	position: relative;
	padding: 1px 18px 0px 18px;
	height: 32px;
	white-space: nowrap;
	&.full {
		width: 100%;
		white-space: normal;
	}
	&.green {
		background: #cfe9cf;
	}
	&.blocked {
		opacity: 0.33;
		pointer-events: none;
	}
`;

const RemoveCell = styled(Cell)`
	padding-left: 11px;
	padding-right: 11px;
`;

const EmptyCell = styled(Cell)`
	text-align: center;
`;

const HeaderCell = styled.td`
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 20px;
	padding-right: 8px;
	white-space: nowrap;
	font-weight: bold;
`;

const Wrapper = styled.div``;

const IndicatorWrapper = styled.div`
	margin-left: 12px;
	display: inline-block;
	vertical-align: middle;
	height: 16px;
`;

const StyledClassificationLevel = styled(ClassificationLevel)``;

const AddButton = styled(TextButton)`
	margin-left: 15px;
	margin-top: 9px;
	margin-bottom: 9px;
`;

const StyledDatePicker = styled(InlineDatePicker)`
	width: 83px;
`;
