import { mapValues } from 'lodash-es';
import { Dict } from '../types';
import { ICompanyShort, IFunctionShort } from '../interfaces/entities';
import { IGridStatistics } from '../interfaces/ui/grid';
import { countTruthy } from './utils';

export interface IRow extends Dict {
	type?: string;
	id: number;
}

export interface IData extends Dict {
	companies: Dict<ICompanyShort>;
	functions: Dict<IFunctionShort>;
	rows: IRow[];
}

export function join(data: IData, { rows: newRows, ...newData }: IData, stub = false) {
	const sections = Object.keys(newData);
	const rows = newRows.filter((row) => row.type && (!data[row.type][row.id] || data[row.type][row.id].stub));
	const result = sections.reduce<Dict>(
		(result, section: string) => ({
			...result,
			[section]: stub
				? {
						...mapValues(newData[section], (value) => ({ ...value, stub: true })),
						...data[section],
				  }
				: {
						...data[section],
						...mapValues(newData[section], (value) => ({ ...value, stub: false })),
				  },
		}),
		{},
	);

	return {
		...data,
		rows: [...data.rows, ...rows],
		...result,
	};
}

export function rowSelector(data: IData, leaf: string) {
	const { rows, companies, functions } = data;

	return rows.map((row) => {
		if (row.type === 'companies') {
			return {
				header: true,
				...companies[row.id],
			};
		} else if (row.type === 'functions') {
			return {
				section: true,
				...functions[row.id],
			};
		} else {
			return {
				...data[leaf][row.id],
				name: data[leaf][row.id].description,
			};
		}
	});
}

export function countSelected(data?: IGridStatistics) {
	if (data) {
		const { selected, total } = data;
		const selectedCount = countTruthy(selected);
		return {
			indeterminate: selectedCount > 0 && selectedCount !== total,
			selected: selectedCount > 0 && selectedCount === total,
			total,
		};
	} else {
		return {
			indeterminate: false,
			selected: false,
			total: 0,
		};
	}
}
