import styled from 'astroturf';

export const SelectMenuWrapper = styled.div`
	background: white;
	box-sizing: border-box;
	border: solid 1px #ededed;
	padding-top: 7px;
	margin-top: -1px;
	width: 100%;
`;
