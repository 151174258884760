import { actionCreatorFactory } from 'typescript-fsa';
import { ConformityPerson } from '../../../../service/typings/entites';
import { RemovePersonRequest, RestorePersonRequest, UpdatePersonRequest, UpdatePersonResponse } from './typings';

const ActionFactory = actionCreatorFactory('PERSONS');

export const PersonsActions = {
	update: ActionFactory.async<UpdatePersonRequest, UpdatePersonResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemovePersonRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestorePersonRequest, {}, Error>('BATCH_RESTORE'),
	getPersons: ActionFactory.async<
		{ companyId: number; functionId?: number },
		{ persons: ConformityPerson[]; total: number },
		Error
	>('GET_PERSONS'),
};
