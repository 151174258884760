import { Tooltip, TooltipProps } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'astroturf';
import { useWindowSize } from '../../service/hooks/useWindowSize';

const styles = css`
	@import '../../styles/constants.scss';
	.tooltip {
		filter: blur(0.000001px);
		-webkit-font-smoothing: antialiased;
		z-index: $z-index-tooltip !important;
	}
`;

export interface IProps {
	className?: string;
	width?: number;
	text?: string;
	disabledTooltip?: boolean;
	placement?: TooltipProps['placement'];
	dangerouslySetInnerHTML?: { __html: string };
}

export const TextEllipsisWithTooltip: FC<IProps> = ({
	className,
	placement,
	text,
	children,
	width,
	dangerouslySetInnerHTML,
	disabledTooltip,
}) => {
	const windowSize = useWindowSize();
	const [clamped, setClamped] = useState(false);
	const container = useRef<HTMLDivElement>(null);

	const updateClamped = useCallback(() => {
		if (container.current) {
			setClamped(container.current.scrollWidth > container.current.clientWidth);
		}
	}, []);

	useEffect(() => {
		updateClamped();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize.width, width, text]);

	const content = !dangerouslySetInnerHTML ? (
		<Container ref={container} className={className}>
			{children || text}
		</Container>
	) : (
		<Container ref={container} className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
	);

	return clamped && !disabledTooltip ? (
		<Tooltip
			classes={{
				tooltip: styles.tooltip,
				popper: styles.tooltip,
			}}
			title={(text || children) as string}
			placement={placement}
			leaveTouchDelay={300}
		>
			{content}
		</Tooltip>
	) : (
		content
	);
};

const Container = styled.div<{ hasHidden?: boolean }>`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	&:before {
		content: attr(data-name);
	}
`;
