import { upperFirst } from 'lodash-es';
import { specialistsApi } from '../../../apiActionsSync';
import {
	AccessEntity,
	accessFields,
} from '../../../../pages/specialists/Settings/Administration/AdministrationUserPage/Access/AccessTable/AccessTable';

import { GetFiltersRequest, UpdateFiltersRequest } from '../../../../service/typings/actions/filters';
import { AccessListActions } from './actions';
import { AccessProjectTypeChangeRequest, GetAccessListRequest, SetAccessListRequest } from './typings';

function generateId(data: any, entity: AccessEntity) {
	return {
		...data,
		_embedded: {
			companies: data._embedded.companies.map((companyData: any) => ({
				...companyData,
				functions: companyData.functions.map((functionData: any) => ({
					...functionData,
					access: functionData.access.map((accessData: any) => ({
						...accessData,
						id: accessFields[entity].reduce((id, field) => {
							if (field === 'functionId') {
								return id ? `${id}/${functionData.id || 0}` : functionData.id || 0;
							} else {
								return id ? `${id}/${accessData[field] || 0}` : accessData[field] || 0;
							}
						}, ''),
					})),
				})),
			})),
		},
	};
}

export const AccessListAsync = {
	getProjectTypeAccess: (userName: string) =>
		specialistsApi({
			url: `accessProjectTypes/search`,
			method: 'GET',
			params: { userName },
			actions: AccessListActions.getProjectTypeAccess,
		}),
	setProjectTypeAccess: (params: AccessProjectTypeChangeRequest) =>
		specialistsApi({
			url: `accessProjectTypes${params.id ? `/${params.id}` : ''}`,
			method: params.id ? 'PATCH' : 'POST',
			noSendParams: params.id ? ['id', 'username'] : [],
			params,
			actions: AccessListActions.setProjectTypeAccess,
		}),
	checkAccess: (params: GetAccessListRequest, entity: AccessEntity) =>
		specialistsApi({
			url: `access${upperFirst(entity)}/search`,
			method: 'GET',
			params: {
				...params,
				entity,
			},
			noSendParams: ['entity'],
			transformData(response) {
				return {
					status: ((response._embedded?.companies || []).length > 0 ? 'edit' : 'create') as 'edit' | 'create',
				};
			},
			actions: AccessListActions.checkAccess,
		}),
	getList: (params: GetAccessListRequest, entity: AccessEntity, mode: 'create' | 'edit') =>
		specialistsApi({
			url: `access${upperFirst(entity)}/${mode === 'create' ? 'list' : 'search'}`,
			method: 'GET',
			params: {
				...params,
				entity,
			},
			noSendParams: params.entity ? ['initialLoad', 'allLoad'] : ['initialLoad', 'allLoad', 'entity'],
			transformData(response) {
				return mode === 'create' ? generateId(response, entity) : response;
			},
			actions: mode === 'create' ? AccessListActions.getListForAccessCreate : AccessListActions.getList,
		}),
	loadMore: (params: GetAccessListRequest, entity: AccessEntity, mode: 'create' | 'edit') =>
		specialistsApi({
			url: `access${upperFirst(entity)}/${mode === 'create' ? 'list' : 'search'}`,
			method: 'GET',
			params,
			transformData(response) {
				return mode === 'create' ? generateId(response, entity) : response;
			},
			actions: AccessListActions.loadMore,
		}),
	getStatistics: (params: GetAccessListRequest, entity: AccessEntity, mode: 'create' | 'edit') =>
		specialistsApi({
			url: `access${upperFirst(entity)}/${mode === 'create' ? 'list' : 'search'}`,
			method: 'GET',
			params: {
				...params,
				size: 9999,
			},
			transformData(response) {
				return mode === 'create' ? generateId(response, entity) : response;
			},
			actions: AccessListActions.getStatistics,
		}),
	getFilters: (params: GetFiltersRequest) =>
		specialistsApi({
			url: `access/list/filters`,
			method: 'GET',
			params,
			actions: AccessListActions.getFilters,
			mockData: [{ field: 'function', custom: false, hidden: false }],
		}),
	updateFilters: (params: UpdateFiltersRequest) =>
		specialistsApi({
			url: `access/list/filters/update`,
			method: 'POST',
			params,
			actions: AccessListActions.updateFilters,
		}),
	setAccess: ({ size, ...rest }: SetAccessListRequest, entity: AccessEntity) =>
		specialistsApi({
			url: `access${upperFirst(entity)}/list?size=${size}`,
			method: 'POST',
			params: { ...(rest as SetAccessListRequest) },
			actions: AccessListActions.setAccess,
		}),
};
