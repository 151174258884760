import { useLayoutEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// Hook
export function useLockBodyScroll(lock?: boolean) {
	useLayoutEffect(() => {
		if (lock === false) {
			return;
		}
		// Prevent scrolling on mount
		disableBodyScroll(document.body, { reserveScrollBarGap: true });

		// Re-enable scrolling when component unmount
		return () => {
			enableBodyScroll(document.body);
		};
	}, [lock]); // Empty array ensures effect is only run on mount and unmount
}
