import { ButtonBase, ButtonBaseProps, withStyles } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';

export const Ripple = withStyles({
	root: {
		width: '100%',
		display: 'block',
		height: '100%',
	},
})(({ classes, className, children, onClick }: ButtonBaseProps) => (
	<ButtonBase className={classes ? cn(classes.root, className) : className} onClick={onClick}>
		{children}
	</ButtonBase>
));
