import { AsyncActionCreators } from 'typescript-fsa';

function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export const queryRaceInit = <P, R>(actions: AsyncActionCreators<P, R, Error>) => {
	const guid = uuidv4();
	sessionStorage.setItem(actions.type, guid);

	return guid;
};

export const queryRaceSuccess = <P, R>(actions: AsyncActionCreators<P, R, Error>, guid: string): boolean => {
	return guid ? sessionStorage.getItem(actions.type) === guid : true;
};

function clean<T>(rawParams: T): T {
	return Object.entries(rawParams).reduce((a, [k, v]) => (empty(v) ? { ...a, [k]: v } : a), {} as T);
}

const empty = (value: any): boolean => {
	return !(value === null || value === undefined || (typeof value === 'string' && value.length === 0));
};

export const interceptor = <T, P>(
	rawParams: T,
	noSendParams?: (keyof P)[],
	noSendLanguage?: boolean,
	allowEmptyParams?: boolean,
) => {
	const sendParams = allowEmptyParams ? rawParams : clean(rawParams);

	const params = !noSendParams
		? sendParams
		: Object.keys(sendParams).reduce(
				(acc, key) => ({
					...acc,
					...(noSendParams.indexOf(key as keyof P) === -1
						? // tslint:disable-next-line:no-any
						  { [key]: (sendParams as any)[key] }
						: {}),
				}),
				{} as P,
		  );

	return { sendParams, params };
};
