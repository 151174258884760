import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DragStoppedEvent } from 'ag-grid-community';
import { get, maxBy } from 'lodash-es';
import { Dict, omitEmpty } from '@ea/common';
import { GridColumn, GridSort } from '../typings/grid';
import { addSorting } from '../utils';
import { GeneralTableInterface } from '../../components/GeneralTable';

interface Methods {
	updateColumns?: (columns: GridColumn[]) => void;
	editColumn?: (id: number, data: GridColumn) => void;
	renameColumn?: (id: number, name: string) => void;
	addColumn?: (data: GridColumn) => Promise<any>;
	hideColumn?: (id: number) => void;
	showColumns?: (data: GridColumn[]) => Promise<any>;
	removeColumn?: (id: number) => void;
	reorderColumns?: (data: GridColumn[]) => void;
}

interface Options {
	sorting?: GridSort;
	column: Dict<Partial<GridColumn>>;
}

export function useGridColumns(
	stateColumns: GridColumn[],
	options: Options,
	methods: Methods,
	ref?: GeneralTableInterface | null,
) {
	const {
		updateColumns,
		editColumn,
		renameColumn,
		addColumn,
		hideColumn,
		showColumns,
		removeColumn,
		reorderColumns,
	} = methods;

	const [columns, setColumns] = useState<GridColumn[]>([]);

	const columnOptions = options.column;
	const sortingOptions = options.sorting;

	// Used to aggregate different debounced changes
	const internalColumns = useRef(stateColumns);

	// Checks if columns should be rerendered
	useEffect(() => {
		const needUpdate =
			stateColumns.length !== columns.length ||
			stateColumns.some((rawData, index) => {
				const properties: (keyof GridColumn)[] = ['headerName', 'editing', 'hidden'];
				// TODO: убрать, когда перестанет приходить null в headerName
				const data = {
					...rawData,
					headerName: rawData.headerName || '',
				};
				return properties.some(
					(property) =>
						(data.custom || property !== 'headerName') &&
						data[property] !== (columns[index] ? columns[index][property] : undefined),
				);
			});
		if (needUpdate) {
			refreshColumns(stateColumns);
		}
		internalColumns.current = [...stateColumns];
	}, [columns, stateColumns, setColumns]);

	// TODO: проверить насколько это влияет на производительность
	useEffect(() => {
		refreshColumns(stateColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnOptions, sortingOptions]);

	const refreshColumns = useCallback(
		(columns) => {
			setColumns(
				addSorting(
					columns.map((column: any) => ({
						...column,
						...columnOptions[column.field],
						suppressMovable:
							column.editing ||
							column.suppressMovable ||
							get(columnOptions, `${column.field}.suppressMovable`),
						suppressSizeToFit: Boolean(column.width || !get(columnOptions, `${column.field}.autoWidth`)),
						headerName:
							!column.custom && columnOptions[column.field]
								? columnOptions[column.field].headerName
								: column.headerName || '',
						width:
							!column.width && columnOptions[column.field]
								? columnOptions[column.field].width
								: column.width,
						hide: column.hidden,
					})),
					sortingOptions,
				),
			);
		},
		[setColumns, columnOptions, sortingOptions],
	);

	const editColumns = useCallback(
		(columns: GridColumn[]) => {
			const editedColumns = columns.map((column) => {
				const stateColumn = stateColumns.find((stateColumn) => stateColumn.field === column.field);
				const diff: Partial<GridColumn> = {};
				if (stateColumn) {
					const properties: (keyof GridColumn)[] = ['headerName', 'editing', 'hidden', 'width'];
					properties.forEach((property) => {
						if (
							(stateColumn.custom || property !== 'headerName') &&
							stateColumn[property] !== column[property]
						) {
							diff[property] = column[property];
						}
					});
				}
				return {
					id: column.id,
					addColumnId: column.addColumnId,
					diff,
				};
			});
			editedColumns.forEach(({ id, addColumnId, diff }) => {
				if (id && Object.keys(diff).length) {
					if ('headerName' in diff && addColumnId) {
						renameColumn && renameColumn(addColumnId, diff.headerName || '');
					} else if ('width' in diff || 'hidden' in diff) {
						if (diff.hidden) {
							hideColumn && hideColumn(id);
						} else {
							editColumn && editColumn(id, { width: diff.width, hidden: diff.hidden });
						}
					}
				}
			});
			updateColumns && updateColumns(columns);
		},
		[stateColumns, editColumn, updateColumns, hideColumn],
	);

	const [debouncedEditColumns] = useDebouncedCallback(editColumns, 500);

	const onColumnAdded = useCallback(
		(type) => {
			const lastColumn = maxBy(stateColumns, 'seq');
			addColumn &&
				addColumn({
					width: 120,
					seq: lastColumn ? (lastColumn.seq as number) + 1 : 1,
					custom: true,
					columnType: type,
				}).then(() => {
					if (ref) {
						ref.scrollToEnd();
					}
				});
		},
		[columns, updateColumns, stateColumns, addColumn],
	);

	const onColumnEdited = useCallback(
		(field: string, value: Partial<GridColumn>, debounce = false) => {
			internalColumns.current = internalColumns.current.map((col) => {
				if (col.field === field) {
					return {
						...col,
						...value,
					};
				}
				return col;
			});
			if (debounce) {
				debouncedEditColumns(internalColumns.current);
			} else {
				editColumns(internalColumns.current);
			}
		},
		[stateColumns, editColumns, debouncedEditColumns],
	);

	const onColumnRenamed = useCallback(
		(field: string, name: string) => {
			const column = stateColumns.find((col) => col.field === field);
			if (column && column.addColumnId) {
				renameColumn && renameColumn(column.addColumnId, name);
			}
		},
		[removeColumn, stateColumns],
	);

	const onColumnResized = useCallback(
		(params: any) => {
			const columns = params.columns ? params.columns : [params.column];
			columns.forEach((column: any) => {
				onColumnEdited(
					column.colDef.field,
					{
						width: column.actualWidth,
					},
					true,
				);
			});
		},
		[onColumnEdited],
	);

	const onColumnShowed = useCallback(
		(colId: string) => {
			const column = stateColumns.find((el) => el.field === colId);
			const lastColumn = maxBy(stateColumns, 'seq');
			if (column && showColumns) {
				showColumns([
					{
						...omitEmpty(column),
						hidden: false,
						width:
							column.field && get(columnOptions, `${column.field}.width`)
								? columnOptions[column.field].width
								: 100,
						seq: lastColumn ? (lastColumn.seq as number) + 1 : 1,
					},
				]).then(() => {
					if (ref) {
						ref.scrollToEnd();
					}
				});
			}
		},
		[stateColumns, showColumns, columnOptions],
	);

	const onColumnRemoved = useCallback(
		(colId: string) => {
			return new Promise((resolve, reject) => {
				const column = stateColumns.find((el) => el.field === colId);
				if (column && column.addColumnId && removeColumn) {
					resolve(removeColumn(column.addColumnId));
				} else {
					reject();
				}
			});
		},
		[stateColumns, removeColumn],
	);

	const onColumnMoved = useCallback(
		(params: DragStoppedEvent) => {
			const { columnApi } = params;

			// Костыль: предотвращает перемещение колонок при их ресайзе
			if (params.target.classList.contains('ag-header-cell-resize')) {
				return;
			}

			const orderedColDefs = columnApi.getAllDisplayedColumns().map((column) => column.getColDef());
			const orderedColumns = stateColumns
				.sort(
					(a, b) =>
						orderedColDefs.findIndex((el) => el.field === a.field) -
						orderedColDefs.findIndex((el) => el.field === b.field),
				)
				.filter((column) => !column.hidden)
				.map((column, index) => ({
					...column,
					seq: index,
				}));

			updateColumns && updateColumns(orderedColumns);
			reorderColumns && reorderColumns(orderedColumns);
		},
		[stateColumns, updateColumns, reorderColumns],
	);

	return {
		onColumnAdded: onColumnAdded,
		onColumnEdited: onColumnEdited,
		onColumnResized: onColumnResized,
		onColumnRemoved: onColumnRemoved,
		onColumnShowed: onColumnShowed,
		onColumnMoved: onColumnMoved,
		onColumnRenamed: onColumnRenamed,
		columns,
	};
}
