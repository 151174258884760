import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import russianTranslation from '../translations/ru.json';
import englishTranslation from '../translations/en.json';

type IAvailableLanguages = 'ru' | 'en';

const translations = {
	ru: russianTranslation,
	en: englishTranslation,
};

i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'ru',
		debug: process.env.NODE_ENV !== 'production',
		load: 'languageOnly',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		backend: {
			loadPath(language: IAvailableLanguages) {
				return translations[language];
			},
		},
	});

// tslint:disable-next-line:no-default-export
export default i18n;
