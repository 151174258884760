import React, { FC } from 'react';
import { Layout } from '@ea/common';
import { Header } from './Header';

const AdminLayout: FC = ({ children }) => {
	return (
		<Layout>
			<Header />
			{children}
		</Layout>
	);
};

export default AdminLayout;
