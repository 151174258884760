import React, {
	forwardRef,
	ForwardRefRenderFunction,
	memo,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'astroturf';
import cx from 'classnames';
import { includes, isObject, isUndefined, omit } from 'lodash-es';
import animateScrollTo from 'animated-scroll-to';

import { AgGridReact } from 'ag-grid-react';
import {
	BodyScrollEvent,
	CellClassParams,
	ColumnApi,
	ColumnResizedEvent,
	DragStoppedEvent,
	GridApi,
	RowNode,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { Dict, LoadingIndicator, defaultEasing, useDynamicFunction } from '@ea/common';
import { RedrawRowsParams, RefreshCellsParams } from 'ag-grid-community/dist/lib/gridApi';
import { GeneralTableData, GridColumn, GridReference, GridRow, GridSort } from '../service/typings/grid';
import { useGridLocalization } from '../service/hooks/useGridLocalization';
import { useOnColumnsChange } from '../service/hooks/useOnColumnsChange';
import { clearArray } from '../service/utils';
import { CheckboxRenderer } from './cells/CheckboxRenderer';
import { AddColumnRenderer } from './cells/AddColumnRenderer';
import { HeaderRenderer } from './cells/HeaderRenderer/Renderer';
import { ClassifierRenderer } from './cells/ClassifierRenderer/Renderer';
import { CustomRenderer } from './cells/CustomRenderer';
import { FullWidthRenderer } from './cells/FullWidthRenderer';
import { CheckboxHeaderRenderer } from './cells/CheckboxHeaderRenderer';

export const TABLE_ANIMATED_SCROLL_DURATION = 800;

interface SelectionSettings {
	enabled?: boolean;
	selectAll?: boolean;
	statistics?: {
		total: number;
		selected: number;
	};
}

interface IProps {
	columns: GridColumn[];
	data?: GeneralTableData;
	className?: string;
	hasMore?: boolean;
	selection?: boolean | SelectionSettings;
	addable?: boolean;
	scroll?: number;
	onScroll?: (scroll: number) => void;
	onLoadMore?: () => void;
	onColumnAdded?: (type: string) => void;
	onColumnEdited?: (coldId: string, params: any) => void;
	onColumnShowed?: (coldId: string) => void;
	onColumnRemoved?: (coldId: string) => void;
	onColumnResized?: (params: any, auto?: boolean) => void;
	onColumnMoved?: (params: any) => void;
	onColumnSorted?: (params: GridSort) => void;
	onRowChanged?: (node: GridRow, field: string, value: any) => void;
	onRowSelected?: (node: GridRow) => void;
	onSelectAll?: () => void;
	onGetClassifiers?: (column: GridColumn, row: any) => Promise<any>;
	onAddClassifier?: (column: GridColumn, data: GridReference) => Promise<any>;
	onEditClassifier?: (column: GridColumn, id: number, data: Partial<GridReference>) => Promise<any>;
	onRemoveClassifier?: (column: GridColumn, id: number) => Promise<any>;
	pageSize?: number;
	rows: Dict[];
	autoResize?: boolean | string[];
	autoFit?: boolean;
	key?: string;
	loading?: boolean;
	hideHeader?: boolean;
	fullWidthParams?: any;
	colResizeDefault?: string;
	noRowsToShow?: string;
	domLayout?: string;
	rowClassRules?: {
		[cssClassName: string]: ((params: any) => boolean) | string;
	};
	cellClassRules?: {
		[cssClassName: string]: ((params: any) => boolean) | string;
	};
}

export interface GeneralTableInterface {
	scrollToEnd: () => void;
	updateRowHeight: () => void;
	redrawRows: (params?: RedrawRowsParams) => void;
	refreshCells: (params?: RefreshCellsParams) => void;
	sizeColumnsToFit: () => void;
}

export const rowClasses = {
	invalid: 'ag-row-invalid',
	blocked: 'ag-row-blocked',
};

export const cellClasses = {
	centered: 'ag-grid-centered-cell',
	bold: 'ag-grid-bold-cell',
	blocked: 'ag-grid-blocked-cell',
	header: 'ag-grid-header-cell',
	section: 'ag-grid-section-cell',
	main: 'ag-grid-main-cell',
	first: 'ag-grid-first-cell',
	checkbox: 'ag-grid-checkbox-cell',
	custom: 'ag-grid-custom-cell',
	reference: 'ag-grid-reference-cell',
	level: ({ value }: CellClassParams) => {
		const levelClass = 'ag-grid-level-cell';
		if (value === 3 || value == 2) {
			return `${levelClass} ${levelClass}-${value}`;
		}
		return 'ag-grid-level-cell';
	},
};

export const headerClasses = {
	first: 'ag-header-first',
	custom: 'ag-header-custom-cell',
	addButton: 'ag-header-add-button-cell',
};

export const defaultColDef: Partial<GridColumn> = {
	cellClass: 'ag-grid-text-cell',
	cellClassRules: {
		[cellClasses.header]: 'data.header',
		[cellClasses.section]: 'data.section',
	},
	minWidth: 60,
	autoHeight: true,
	resizable: true,
	suppressSizeToFit: true,
};

export const selectColumn: GridColumn = {
	field: 'selected',
	headerName: '',
	width: 30,
	minWidth: 30,
	pinned: 'left',
	resizable: false,
	autoHeight: true,
	lockPosition: true,
	suppressSizeToFit: true,
	maxWidth: 30,
	cellRendererFramework: CheckboxRenderer,
	cellClass: cellClasses.checkbox,
};

export const addColumn: GridColumn = {
	field: 'addColumn',
	headerName: '',
	width: 38,
	resizable: false,
	suppressMovable: true,
	headerComponent: AddColumnRenderer,
	suppressSizeToFit: true,
	headerClass: headerClasses.addButton,
};

const RenderTable: ForwardRefRenderFunction<GeneralTableInterface, IProps> = (
	{
		className,
		selection,
		fullWidthParams,
		addable,
		columns,
		rows,
		pageSize,
		onLoadMore,
		onColumnAdded,
		onColumnEdited,
		onColumnResized,
		onColumnRemoved,
		onColumnShowed,
		onColumnMoved,
		onRowChanged,
		onRowSelected,
		onSelectAll,
		onRemoveClassifier,
		onColumnSorted,
		onAddClassifier,
		onEditClassifier,
		onGetClassifiers,
		onScroll,
		hideHeader,
		scroll,
		loading,
		key,
		autoResize,
		autoFit,
		colResizeDefault,
		domLayout,
		noRowsToShow,
		rowClassRules,
		cellClassRules,
	},
	ref,
) => {
	const localization = useGridLocalization(noRowsToShow);

	const gridApi = useRef<GridApi>();
	const columnApi = useRef<ColumnApi>();

	const [visible, setVisible] = useState(false);
	const [initialized, setInitialized] = useState(false);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const dragTimer = useRef(0);

	const selectionSettings = isObject(selection)
		? selection
		: {
				enabled: !!selection,
		  };

	const extendedRowClassRules = useMemo(
		() => ({
			...rowClassRules,
			'ag-grid-header-row': 'data.header',
			'ag-grid-section-row': 'data.section',
			'ag-first-row': ({ api, data }: { api: GridApi; data: any }) => {
				if (api) {
					const rows = api.getModel();
					const row = rows.getRow(data.index);
					if (row && !row.data.section && !row.data.header) {
						const previousRow = rows.getRow(data.index - 1);
						if (previousRow && (previousRow.data.section || previousRow.data.header)) {
							return true;
						}
					}
				}
				return false;
			},
		}),
		[],
	);

	const getRowHeight = useCallback((params) => {
		return params.data.header || params.data.section ? 28 : null;
	}, []);

	const getContainer = useCallback(() => {
		return wrapperRef.current ? wrapperRef.current.querySelector('.ag-full-width-container') : null;
	}, []);

	const getViewport = useCallback(() => {
		return wrapperRef.current ? wrapperRef.current.querySelector('.ag-body-viewport') : null;
	}, []);

	const getFloatingTop = useCallback(() => {
		return wrapperRef.current ? wrapperRef.current.querySelector('.ag-floating-top') : null;
	}, []);

	const getPinnedRows = useCallback(() => {
		return wrapperRef.current
			? {
					header: wrapperRef.current.querySelector('.ag-floating-top .ag-grid-header-row') as HTMLElement,
					section: wrapperRef.current.querySelector('.ag-floating-top .ag-grid-section-row') as HTMLElement,
			  }
			: {};
	}, []);

	const updateFloatingTopSize = useCallback(() => {
		const container = getContainer();
		const floatingTop = getFloatingTop();

		if (container && floatingTop) {
			floatingTop.style.width = `${container.clientWidth}px`;
		}
	}, [getContainer, getFloatingTop]);

	const updateRowHeight = useCallback(() => {
		if (gridApi.current) {
			gridApi.current.resetRowHeights();
		}
	}, []);

	const [debouncedUpdateRowHeight] = useDebouncedCallback(() => {
		if (gridApi.current) {
			gridApi.current.resetRowHeights();
		}
	}, 100);

	const currentScroll = useRef(0);

	const onBodyScroll = useCallback(
		({ direction, api, top }: BodyScrollEvent) => {
			const headerHeight = 28;
			const sectionHeight = 28;
			currentScroll.current = Math.max(0, top);
			if (direction === 'vertical') {
				let headerRow: RowNode | undefined;
				let sectionRow: RowNode | undefined;
				let sectionIntersection: RowNode | undefined;
				let headerIntersection: RowNode | undefined;
				let first: RowNode | undefined;
				let bottom: RowNode | undefined;

				const container = getViewport();

				api.forEachNode((node) => {
					if (node.data.header || node.data.section) {
						if (node.data.header && top >= node.rowTop) {
							headerRow = node;
						} else {
							if (node.data.section && top + (headerRow ? headerHeight : 0) >= node.rowTop) {
								sectionRow = node;
							} else {
								if (headerRow && top + headerHeight > node.rowTop && top < node.rowTop) {
									headerIntersection = node;
								} else if (
									sectionRow &&
									top + (headerRow ? headerHeight + sectionHeight : headerHeight) > node.rowTop &&
									top < node.rowTop
								) {
									sectionIntersection = node;
								}
							}
						}
					}
					if (node.rowTop < top && node.rowTop + node.rowHeight > top) {
						first = node;
					}

					if (
						container &&
						container.clientHeight + top >= node.rowTop &&
						container.clientHeight + top <= node.rowTop + node.rowHeight
					) {
						bottom = node;
					}
				});

				if (first && onScroll) {
					onScroll(first.rowIndex);
				}

				if (headerRow || sectionRow) {
					const pinnedHeader = api.getPinnedTopRow(0);
					const pinnedSection = api.getPinnedTopRow(headerRow ? 1 : 0);

					const headerPinningChanged =
						headerRow && (!pinnedHeader || pinnedHeader.data.id !== headerRow.data.id);
					const sectionPinningChanged =
						sectionRow && (!pinnedSection || pinnedSection.data.id !== sectionRow.data.id);

					if (
						headerPinningChanged ||
						(!headerIntersection && sectionPinningChanged) ||
						(headerIntersection && pinnedSection)
					) {
						const rows = [];
						if (headerRow && headerRow.data) {
							rows.push(headerRow.data);
						}
						if (sectionRow && sectionRow.data && !headerIntersection) {
							rows.push(sectionRow.data);
						}
						api.setPinnedTopRowData(rows);
					}
					const { section, header } = getPinnedRows();
					if (section) {
						if (sectionIntersection) {
							section.style.transform = `translateY(${-Math.floor(
								top + sectionHeight - sectionIntersection.rowTop,
							)}px)`;
						} else {
							section.style.transform = `translateY(${headerRow ? headerHeight : 0}px)`;
						}
					}
					if (header) {
						if (headerIntersection) {
							header.style.transform = `translateY(${-Math.floor(
								top + headerHeight - headerIntersection.rowTop,
							)}px)`;
						} else {
							header.style.transform = `translateY(0px)`;
						}
					}
				} else {
					api.setPinnedTopRowData([]);
				}
				if (pageSize && onLoadMore && bottom && bottom.rowIndex > rows.length - pageSize / 2) {
					onLoadMore();
				}
			}
		},
		[pageSize, onLoadMore, rows, hideHeader],
	);

	const updateScrollbar = useCallback(() => {
		if (wrapperRef.current) {
			const pinnedLeftContainer = wrapperRef.current.querySelector<HTMLDivElement>('.ag-pinned-left-header');
			const container = wrapperRef.current.querySelector<HTMLDivElement>('.ag-body-horizontal-scroll-container');
			if (container && pinnedLeftContainer) {
				container.style.paddingRight = `${pinnedLeftContainer.clientWidth}px`;
			}
		}
	}, []);

	const onGridResized = useCallback(() => {
		updateFloatingTopSize();
		updateScrollbar();
	}, [updateScrollbar, updateFloatingTopSize]);

	const onColumnResizedInternal = useCallback(
		(args: ColumnResizedEvent) => {
			onGridResized();
			debouncedUpdateRowHeight();
			if (onColumnResized) {
				onColumnResized(args, args.source !== 'uiColumnDragged');
			}
		},
		[debouncedUpdateRowHeight, onColumnResized],
	);

	const onGridReady = useCallback(
		(event) => {
			gridApi.current = event.api;
			columnApi.current = event.columnApi;
			setInitialized(true);
		},
		[setInitialized],
	);

	const onDragStarted = useCallback(() => {
		if (wrapperRef.current) {
			wrapperRef.current.classList.add('prevent-selection');
		}
		if (dragTimer.current) {
			clearTimeout(dragTimer.current);
		}
	}, []);

	const onFitColumns = useCallback(() => {
		if (gridApi.current) {
			gridApi.current.sizeColumnsToFit();
		}
	}, []);

	// Костыль для того, чтобы содержимое таблицы не выделялось при перемещении колонки на ac'е
	const onDragStopped = useCallback(
		(event: DragStoppedEvent) => {
			if (onColumnMoved) {
				onColumnMoved(event);
			}
			dragTimer.current = window.setTimeout(() => {
				if (wrapperRef.current) {
					wrapperRef.current.classList.remove('prevent-selection');
				}
			}, 1000);
		},
		[onColumnMoved],
	);

	// TODO: переписать
	const lastRows = useRef<Dict[]>([]);
	const onRowDataUpdated = useCallback(() => {
		const api = gridApi.current;
		if (lastRows.current.length !== rows.length) {
			lastRows.current = rows;
		} else {
			if (api) {
				let refreshHeights = false;
				api.forEachNode((node, index) => {
					const changed = Object.keys(node.data).find(
						(key) => lastRows.current[index] && !isEqual(node.data[key], lastRows.current[index][key]),
					);
					if (changed) {
						if (changed === 'selected') {
							api.refreshCells({
								force: true,
								rowNodes: [node],
							});
						} else if (!includes(['selected', 'indeterminate', 'password', 'index'], changed)) {
							node.setRowHeight(null);
							refreshHeights = true;
						}
					}
				});
				if (refreshHeights) {
					api.onRowHeightChanged();
				}
			}
			lastRows.current = rows;
		}

		// Обновляем закреплённые заголовки
		if (api) {
			let refreshPinnedRows = false;
			const pinnedRows: GridRow[] = [];

			// Получаем закреплённые строки (заголовок и подзаголовок)
			clearArray([api.getPinnedTopRow(0), api.getPinnedTopRow(1)]).forEach((pinnedRow) => {
				const { data } = pinnedRow;
				const node = rows.find((row) => row.id === data.id);
				if (node) {
					const changed = Object.keys(node).some((key) => key !== 'index' && !isEqual(node[key], data[key]));
					refreshPinnedRows = refreshPinnedRows || changed;
					pinnedRows.push({ ...node });
				} else {
					refreshPinnedRows = true;
				}
			});

			if (refreshPinnedRows) {
				api.setPinnedTopRowData(pinnedRows);
			}
		}
	}, [rows, lastRows, gridApi]);

	// Закрепляем строки по умолчанию
	useEffect(() => {
		if (gridApi.current) {
			onBodyScroll({
				api: gridApi.current,
				direction: 'vertical',
				top: currentScroll.current,
			} as BodyScrollEvent);
		}
	}, [rows]);

	const linkedOnColumnEdited = useDynamicFunction(onColumnEdited);
	const linkedOnColumnAdded = useDynamicFunction(onColumnAdded);
	const linkedOnColumnShowed = useDynamicFunction(onColumnShowed);
	const linkedOnColumnRemoved = useDynamicFunction(onColumnRemoved);
	const linkedOnRowChanged = useDynamicFunction(onRowChanged);
	const linkedOnColumnSorted = useDynamicFunction(onColumnSorted);
	const linkedOnAddClassifier = useDynamicFunction(onAddClassifier);
	const linkedOnGetClassifiers = useDynamicFunction(onGetClassifiers);
	const linkedOnEditClassifier = useDynamicFunction(onEditClassifier);
	const linkedOnRemoveClassifier = useDynamicFunction(onRemoveClassifier);
	const linkedOnRowSelected = useDynamicFunction(onRowSelected);
	const linkedOnSelectAll = useDynamicFunction(onSelectAll);
	const linkedGetStatistics = useDynamicFunction(() => selectionSettings.statistics);

	const extendedColumns = useMemo(() => {
		const result: GridColumn[] = [
			...columns.map((el, index) => {
				const data = {
					...el,
					sortable: isUndefined(el.sortable) ? true : el.sortable,
					alterable: isUndefined(el.alterable) ? !el.pinned : el.alterable,
					first: index === 0,
					minWidth: el.minWidth || (el.width ? Math.min(el.width, 90) : 90),
					headerComponent: HeaderRenderer,
					headerComponentParams: {
						...el.headerComponentParams,
						onColumnEdited: linkedOnColumnEdited,
						onColumnRemoved: linkedOnColumnRemoved,
						onColumnSorted: linkedOnColumnSorted,
					},
					cellClassRules: {
						...cellClassRules,
						...el.cellClassRules,
						[cellClasses.first]: ({ colDef }: any) =>
							!(colDef.cellRendererFramework || colDef.cellRenderer) && colDef.first,
						[cellClasses.custom]: ({ colDef }: any) =>
							Boolean(colDef.cellRendererFramework || colDef.cellRenderer),
					},
				};
				if (data.columnType === 'REFERENCE') {
					return {
						...data,
						cellClass: cellClasses.reference,
						cellRenderer: ClassifierRenderer,
						cellRendererParams: {
							...el.cellRendererParams,
							onRowChanged: linkedOnRowChanged,
							onGetClassifiers: linkedOnGetClassifiers,
							onAddClassifier: linkedOnAddClassifier,
							onEditClassifier: linkedOnEditClassifier,
							onRemoveClassifier: linkedOnRemoveClassifier,
						},
					};
				} else if (data.custom) {
					return {
						...data,
						cellRenderer: CustomRenderer,
						cellRendererParams: {
							isNumber: data.columnType === 'NUMBER',
							onRowChanged: linkedOnRowChanged,
						},
					};
				} else {
					return data;
				}
			}),
		];

		if (addable) {
			result.push({
				...addColumn,
				service: true,
				headerComponentParams: {
					onColumnAdded: linkedOnColumnAdded,
					onColumnShowed: linkedOnColumnShowed,
				},
			});
		}

		if (selectionSettings.enabled) {
			const column = {
				...selectColumn,
				service: true,
				cellRendererParams: {
					...selectColumn.cellRendererParams,
					onRowSelected: linkedOnRowSelected,
				},
			};

			if (selectionSettings.selectAll && rows.length) {
				column.headerComponent = CheckboxHeaderRenderer;
				column.headerComponentParams = {
					onSelectAll: linkedOnSelectAll,
					getStatistics: linkedGetStatistics,
				};
			}
			result.unshift(column);
		}
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columns, onColumnAdded, addable]);

	// Обновление header'а, чтобы через getStatistics проставить правильное значение для чекбокса. В противном случае брасывается ширина столбцов
	useEffect(() => {
		gridApi.current?.refreshHeader();
	}, [selectionSettings.statistics?.total, selectionSettings.statistics?.selected]);

	const indexedRows = useMemo(
		() =>
			rows.map((row, index) => ({
				...row,
				index,
			})),
		[rows],
	);

	// Скролл вверх
	useEffect(() => {
		const api = gridApi.current;
		if (api) {
			if (loading) {
				api.showLoadingOverlay();
			} else if (!loading) {
				if (rows.length) {
					api.hideOverlay();
					api.ensureIndexVisible(0, 'top');
				} else {
					api.showNoRowsOverlay();
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useOnColumnsChange(() => {
		if (gridApi.current) {
			gridApi.current.sizeColumnsToFit();
		}
	}, columns);

	useImperativeHandle(ref, () => ({
		scrollToEnd: () => {
			if (wrapperRef.current) {
				const horizontalScroll = wrapperRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
				if (horizontalScroll) {
					setTimeout(() => {
						animateScrollTo([horizontalScroll.scrollWidth, 0], {
							elementToScroll: horizontalScroll,
							minDuration: TABLE_ANIMATED_SCROLL_DURATION,
							maxDuration: TABLE_ANIMATED_SCROLL_DURATION,
							easing: defaultEasing,
						});
					});
				}
			}
		},
		updateRowHeight: () => {
			setTimeout(updateRowHeight);
		},
		redrawRows: (params?: RedrawRowsParams) => {
			if (gridApi.current) {
				gridApi.current.redrawRows(params);
			}
		},
		refreshCells: (params?: RefreshCellsParams) => {
			if (gridApi.current) {
				gridApi.current.refreshCells(params);
			}
		},
		sizeColumnsToFit() {
			if (gridApi.current) {
				gridApi.current.sizeColumnsToFit();
			}
		},
	}));

	useEffect(() => {
		const api = gridApi.current;
		// Первая загрузка:
		if (!loading && !visible && initialized) {
			if (api) {
				if (columnApi.current && autoResize) {
					if (Array.isArray(autoResize)) {
						columnApi.current.autoSizeColumns(autoResize);
					} else {
						columnApi.current.autoSizeAllColumns();
					}
				}
				api.sizeColumnsToFit();
			}
			// Ждём, когда перерисуются колонки
			setTimeout(() => {
				updateRowHeight();
				updateScrollbar();
				if (wrapperRef.current) {
					const horizontalScroll = wrapperRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
					if (horizontalScroll) {
						horizontalScroll.scrollLeft = 0;
					}
				}
				if (scroll) {
					// Ждём, когда перерисуются строки
					setTimeout(() => {
						if (api) {
							api.ensureIndexVisible(scroll, 'top');
							// Ждём, когда скролл закончится, чтобы закрепляемая строка не моргала
							setTimeout(() => {
								setVisible(true);
							});
						}
					});
				} else {
					setVisible(true);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, visible, initialized]);

	useEffect(() => {
		if (autoFit) {
			window.addEventListener('resize', onFitColumns);
			window.addEventListener('orientationchange', onFitColumns);
			return () => {
				window.removeEventListener('resize', onFitColumns);
				window.removeEventListener('orientationchange', onFitColumns);
			};
		}
	}, [onFitColumns, autoFit]);

	const overlayLoadingTemplate = `<div/>`;
	return (
		<Wrapper
			className={cx(className, 'ag-theme-admin', hideHeader && 'ag-theme-admin--hide-header')}
			ref={wrapperRef}
		>
			<LoadingIndicator delay visible={Boolean(loading)} />
			<GridWrapper visible={visible}>
				<AgGridReact
					key={key}
					headerHeight={hideHeader ? 0 : 43}
					columnDefs={extendedColumns}
					defaultColDef={defaultColDef}
					localeText={localization}
					immutableData
					getRowNodeId={(data) => data.rowId || data.id}
					rowData={indexedRows}
					onBodyScroll={onBodyScroll}
					onColumnResized={onColumnResizedInternal}
					onGridSizeChanged={onGridResized}
					onRowDataUpdated={onRowDataUpdated}
					onDragStopped={onDragStopped}
					onDragStarted={onDragStarted}
					rowClassRules={extendedRowClassRules}
					getRowHeight={getRowHeight}
					onGridReady={onGridReady}
					suppressDragLeaveHidesColumns
					suppressColumnVirtualisation
					suppressPropertyNamesCheck
					suppressCellSelection
					disableStaticMarkup={false}
					immutableColumns
					enableCellTextSelection
					skipHeaderOnAutoSize
					isFullWidthCell={(row: any) => {
						return row.data.section || row.data.header;
					}}
					fullWidthCellRenderer={FullWidthRenderer}
					fullWidthCellRendererParams={{
						onRowSelected: linkedOnRowSelected,
						selectable: selectionSettings.enabled,
						...fullWidthParams,
					}}
					frameworkComponents={{
						checkboxRenderer: CheckboxRenderer,
						referenceRenderer: ClassifierRenderer,
					}}
					overlayLoadingTemplate={overlayLoadingTemplate}
					colResizeDefault={colResizeDefault}
					domLayout={domLayout}
				/>
			</GridWrapper>
		</Wrapper>
	);
};

export const GeneralTable = memo(forwardRef(RenderTable), (prevProps, newProps) =>
	isEqual(omit(newProps, 'scroll'), omit(prevProps, 'scroll')),
);

const Wrapper = styled.div`
	height: 100%;
	position: relative;
	min-height: 150px;
`;

const GridWrapper = styled.div<{ visible: boolean }>`
	height: 100%;
	position: relative;
	opacity: 0;
	transition: 0.5s;

	&.visible {
		opacity: 1;
	}
`;
