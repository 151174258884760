import React, { CSSProperties, FC, RefObject, useMemo, useRef } from 'react';
import styled from 'astroturf';
import { Fade, Popper, PopperPlacementType } from '@material-ui/core';
import useOnClickOutside from 'use-onclickoutside';

interface Orios {
	anchor: RefObject<HTMLElement>;
	open?: boolean;
	onClickOutside?: () => void;
	className?: string;
	sameWidth?: boolean;
	limitHeight?: boolean;
	disablePortal?: boolean;
	preventResize?: boolean;
	placement?: PopperPlacementType;
	listStyle?: CSSProperties;
	processClickOutside?: boolean;
}

export const SelectMenu: FC<Orios> = ({
	open,
	anchor,
	onClickOutside,
	processClickOutside,
	className,
	sameWidth,
	children,
	limitHeight,
	disablePortal,
	preventResize,
	placement = 'bottom-start',
	listStyle,
}) => {
	const dropdown = useRef<HTMLElement>(null);

	useOnClickOutside(anchor, (event: any) => {
		if (processClickOutside === undefined ? open : processClickOutside) {
			// TODO: костыль, сделанный для предотвращения двойного клика
			if (
				anchor.current &&
				!anchor.current.contains(event.target) &&
				(!dropdown.current || !dropdown.current.contains(event.target)) &&
				onClickOutside
			) {
				onClickOutside();
			}
		}
	});

	const modifiers = useMemo(
		() => ({
			setWidth: {
				enabled: true,
				order: 750,
				fn: (data: any) => {
					const {
						instance: { reference, popper },
						popper: { height, top },
					} = data;
					if (sameWidth) {
						popper.style.width = `${reference.offsetWidth}px`;
					} else {
						popper.style.minWidth = `${reference.offsetWidth}px`;
					}
					if (limitHeight && top < 5) {
						const diff = top - 5;
						data.styles.height = height + diff;
						data.offsets.popper.top = top - diff;
					}
					return data;
				},
			},
			setMaxWidth: {
				enabled: true,
				order: 760,
				fn: (data: any) => {
					if (preventResize) {
						data.instance.popper.style.maxWidth = `${data.instance.popper.offsetWidth}px`;
					}
					return data;
				},
			},
			flip: {
				enabled: true,
			},
		}),
		[sameWidth, preventResize, limitHeight],
	);

	return (
		<List
			style={listStyle}
			transition
			open={Boolean(open)}
			disablePortal={disablePortal}
			anchorEl={anchor.current}
			modifiers={modifiers}
			placement={placement}
		>
			{({ TransitionProps }) => {
				return (
					<Fade {...TransitionProps} unmountOnExit ref={dropdown}>
						<Wrapper className={className} sameWidth={sameWidth}>
							{children}
						</Wrapper>
					</Fade>
				);
			}}
		</List>
	);
};

const Wrapper = styled.div<{ sameWidth?: boolean }>`
	height: 100%;
	&.sameWidth {
		max-width: 100%;
	}
`;

const List = styled(Popper)`
	@import '../../../styles/constants.scss';
	box-sizing: border-box;
	position: relative;
	user-select: none;
	z-index: $z-index-select;
`;
