import React, { FC, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { LoadingIndicator } from '../elements/indicators/LoadingIndicator';

interface IProps {
	loading?: boolean;
	onEnter?: () => void;
	onEntering?: () => void;
	hideIndicator?: boolean;
}

export const LoadingContent: FC<IProps> = ({ children, hideIndicator, loading, onEnter, onEntering }) => {
	const [showIndicator, setShowIndicator] = useState(false);
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		const id = setTimeout(() => {
			setShowIndicator(Boolean(loading));
			setShowContent(!loading);
		});
		return () => {
			clearTimeout(id);
		};
	}, [loading, setShowIndicator, setShowContent]);

	return (
		<>
			<CSSTransition
				appear
				in={showContent}
				timeout={500}
				classNames="slow-appear"
				onEnter={onEnter}
				onEntering={onEntering}
				unmountOnExit
			>
				<>{children}</>
			</CSSTransition>
			{!hideIndicator && <LoadingIndicator delay visible={showIndicator} />}
		</>
	);
};
