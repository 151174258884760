import React, { useCallback, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { isObject, omit } from 'lodash-es';
import { ICellRenderer } from 'ag-grid-community';
import ReactDOM from 'react-dom';
import { ClassifierAbstractSelect, ConfirmationModal, Reference } from '@ea/common';
import { useStatefulModal } from '../../../service/hooks/useStatefulModal';
import { GridReference } from '../../../service/typings/grid';
import { ClassifierEmptyCell } from './ClassifierEmptyCell';
import { ClassifierPopup } from './ClassifierPopup';
import { ClassifierValueCell } from './ClassifierValueCell';

export class ClassifierRenderer implements ICellRenderer {
	params: any;
	parentElement!: HTMLElement;
	value: any;

	public init(params: any) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: any) {
		if (this.parentElement) {
			ReactDOM.render(<Renderer {...params} />, this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement('div');
		this.parentElement.className = 'ag-grid-header-rendered';
	}
}

const Renderer = (props: any) => {
	const {
		onAddClassifier,
		onGetClassifiers,
		onRowChanged,
		onEditClassifier,
		onRemoveClassifier,
		value,
		node,
		colDef,
	} = props;

	const { t } = useTranslation();

	const [confirmationModal, openConfirmationModal, closeConfirmationModal] = useStatefulModal<Reference>();

	const onChange = useCallback(
		(value: any) => {
			if (colDef.field === 'type') {
				openConfirmationModal(value);
			} else {
				onRowChanged(node.data, colDef.field, value);
			}
		},
		[onRowChanged, openConfirmationModal, node.data, colDef.field],
	);

	const onSubmitConfirmationModal = useCallback(() => {
		return onRowChanged(node.data, colDef.field, omit(confirmationModal, 'open'));
	}, [confirmationModal]);

	const onClear = useCallback(() => {
		onChange({ id: -1 });
	}, [onChange]);

	const addClassifier = useCallback(
		(data: GridReference) => {
			return onAddClassifier(colDef, data);
		},
		[onAddClassifier, colDef],
	);

	const loadClassifiers = useCallback(() => {
		return onGetClassifiers(colDef);
	}, [onGetClassifiers, colDef]);

	const removeClassifier = useCallback(
		(id: number) => {
			return onRemoveClassifier(colDef, id);
		},
		[onRemoveClassifier, colDef],
	);

	const editClassifier = useCallback(
		(id: number, data: Partial<GridReference>) => {
			onEditClassifier(colDef, id, data);
		},
		[onEditClassifier, colDef],
	);

	return (
		<Wrapper>
			<ClassifierAbstractSelect
				dropdownComponent={isObject(value) ? ClassifierValueCell : ClassifierEmptyCell}
				selectMenuComponent={ClassifierPopup}
				onSelect={onChange}
				onAddClassifier={addClassifier}
				onLoadClassifiers={loadClassifiers}
				onRemoveClassifier={removeClassifier}
				onChangeClassifier={editClassifier}
				withColors={!colDef.custom}
				showCount={colDef.custom}
				addable={colDef.field !== 'type'}
				removable={colDef.field !== 'type'}
				selectMenuProps={{
					currentId: value ? value.id : undefined,
				}}
				dropdownProps={{
					value,
					clearable: colDef.field !== 'type',
					disabled: colDef.field === 'type',
					onClear,
				}}
				selectOnExit
			/>
			{colDef.field === 'type' && confirmationModal.open && (
				<ConfirmationModal
					open={confirmationModal.open}
					onClose={closeConfirmationModal}
					title={t('competence.confirmationModal.title')}
					question={t('competence.confirmationModal.question')}
					explanation={t('competence.confirmationModal.explanation', {
						new: value.name,
						original: confirmationModal.name,
					})}
					cancelText={t('competence.confirmationModal.cancel')}
					submitText={t('competence.confirmationModal.submit')}
					onSubmit={onSubmitConfirmationModal}
				/>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	min-height: 100%;
	min-width: 100%;
	height: 100%;
`;
