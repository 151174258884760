import * as queryString from 'query-string';

const parseOptions: queryString.ParseOptions = {
	arrayFormat: 'comma',
	parseNumbers: true,
};

const stringifyOptions: queryString.StringifyOptions = {
	encode: false,
	arrayFormat: 'comma',
};

export function stringify(object: { [key: string]: any }, needQuestionMark?: boolean) {
	return `${needQuestionMark ? '?' : ''}${queryString.stringify(object, stringifyOptions)}`;
}

export function parse<T = { [key: string]: any }>(url: string): T {
	return queryString.parse(url, parseOptions) as any;
}
